import { toast } from 'react-toastify';

type Metadata = {
    entity_id: string | null;
    logout_url?: string | null | undefined;
    login_url?: string | null | undefined;
    slo_url?: string | null | undefined;
    acs_url?: string | null | undefined;
    issuer?: string | null | undefined;
    auth_uri?: string | null | undefined;
    token_uri?: string | null | undefined;
    userinfo_uri?: string | null | undefined;
    logout_uri?: string | null | undefined;
    client_id?: string | null | undefined;
    client_secret?: string | null | undefined;
};

type OidcData = {
    web: {
        client_id: string;
        project_id: string;
        auth_uri: string;
        token_uri: string;
        userinfo_uri?: string;
        logout_uri?: string;
        auth_provider_x509_cert_url: string;
        client_secret: string;
        redirect_uris: string[];
    };
};

type SAMLFields = {
    entity_id: string;
    logout_url: string;
    login_url: string;
    slo_url: string;
    acs_url: string;
};
type OIDCFields = {
    client_id: string;
    client_secret: string;
    auth_uri: string;
    token_uri: string;
    userinfo_uri: string;
    logout_uri: string;
};

const OIDCRequiredFields: (keyof OIDCFields)[] = ['client_id', 'client_secret', 'auth_uri', 'token_uri'];
const OIDCInputFields: (keyof OIDCFields)[] = [
    'client_id',
    'client_secret',
    'auth_uri',
    'token_uri',
    'userinfo_uri',
    'logout_uri',
];

type GeneralProps = {
    event: React.ChangeEvent<HTMLInputElement>;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    type: 'idp' | 'sp';
};
type SAMLProps = GeneralProps & {
    protocol: 'saml';
    fieldPath: SAMLFields;
};
type OIDCProps = GeneralProps & {
    protocol: 'oidc';
    fieldPath: OIDCFields;
};

const SAML_CODE = 1;
const OIDC_CODE = 2;
const OIDC_AZURE_CODE = 3;
const SAML_LABEL = 'SAML 2.0';
const OIDC_LABEL = 'OIDC';
const OIDC_AZURE_LABEL = 'Microsoft Entra ID';
const PROTOCOL_MAP: { [key: number]: string } = {
    [SAML_CODE]: SAML_LABEL,
    [OIDC_CODE]: OIDC_LABEL,
    [OIDC_AZURE_CODE]: OIDC_AZURE_LABEL,
};

export const jsonFileValidation = (json: any): string | null => {
    if (!json.web) {
        return "The JSON file should contain 'web' attribute.";
    }
    // ? to be decided
    for (const key of OIDCRequiredFields) {
        if (!json.web[key]) {
            return `The JSON file should contain 'web.${key}' attribute.`;
        }
    }

    return null;
};

export const onFileChange = (props: SAMLProps | OIDCProps) => {
    const { event, type, protocol, fieldPath, setFieldValue } = props;
    const file = event.target;
    if (file && file.files && file.files.length > 0) {
        const reader = new FileReader();
        reader.onload = (e) => {
            if (protocol === 'oidc') {
                try {
                    const json: OidcData = JSON.parse(e.target?.result as string);
                    const errMsg = jsonFileValidation(json);
                    if (errMsg) {
                        toast.error(errMsg);
                        return;
                    }
                    for (const field of OIDCInputFields) {
                        setFieldValue(fieldPath[field], json.web[field]);
                    }
                } catch (error) {
                    toast.error('Invalid JSON file');
                }
            } else {
                const samlXmlContent = e.target?.result as string;
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(samlXmlContent, 'text/xml');
                const entityDescriptor = xmlDoc.querySelector('EntityDescriptor');
                const singleLogoutService = entityDescriptor?.querySelector('SingleLogoutService');
                let entityID: string | null,
                    singleLogoutServiceUrl: string | null | undefined,
                    singleSignOnServiceUrl: string | null | undefined,
                    assertionConsumerService: string | null | undefined;
                if (entityDescriptor) {
                    entityID = entityDescriptor.getAttribute('entityID');
                    setFieldValue(fieldPath.entity_id, entityID);
                    singleLogoutServiceUrl = singleLogoutService?.getAttribute('Location');
                    if (type === 'idp') {
                        const singleSignOnService = entityDescriptor.querySelector('SingleSignOnService');
                        singleSignOnServiceUrl = singleSignOnService?.getAttribute('Location');
                        setFieldValue(fieldPath.login_url, singleSignOnServiceUrl);
                        setFieldValue(fieldPath.logout_url, singleLogoutServiceUrl);
                    } else if (type === 'sp') {
                        const AssertionConsumerService = entityDescriptor.querySelector('AssertionConsumerService');
                        assertionConsumerService = AssertionConsumerService?.getAttribute('Location');
                        setFieldValue(fieldPath.slo_url, singleLogoutServiceUrl);
                        setFieldValue(fieldPath.acs_url, assertionConsumerService);
                    }
                } else {
                    toast.error('Entity ID not found');
                }
            }
            file.value = '';
        };
        reader.readAsText(file.files[0]);
    }
};

export const parseCertificate = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    setFieldError: (field: string, message: string | undefined) => void,
    fieldRef: React.RefObject<HTMLInputElement>
) => {
    try {
        const target = event.target as HTMLInputElement;

        if (target.files && target.files.length > 0) {
            const file = target.files[0];
            if (
                file.size > 20 * 1000 ||
                !['pem', 'cer', 'crt', '.key'].some((ext) => file.name.toLowerCase().endsWith(ext))
            ) {
                throw Error('The file must be a pem, cer or crt and cannot exceed max size 20kB!');
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const result = (e.target as FileReader).result as string;
                if (result && result !== 'undefined') {
                    setFieldValue(field, result);
                    setFieldError('file_content', '');
                } else {
                    setFieldValue(field, '');
                    setFieldError(field, 'Incorrect certificate file format');
                }
                // clear file input value
                if (fieldRef?.current?.value) {
                    fieldRef.current.value = '';
                }
            };
            reader.readAsText(file);
        }
    } catch (e) {
        toast.error(e.message);
    }
};

export const getUserSourceDraftMessage = (row: UserSource): string => {
    const incompleteFields = [];

    if (row.type === SAML_CODE) {
        if (!row.entity_id) {
            incompleteFields.push('Entity ID');
        }
        if (!row.login_url) {
            incompleteFields.push('Login URL');
        }
    } else if (row.type === OIDC_CODE) {
        if (!row.issuer) {
            incompleteFields.push('Issuer');
        }
        if (!row.auth_uri) {
            incompleteFields.push('Auth URI');
        }
        if (!row.token_uri) {
            incompleteFields.push('Token URI');
        }
        if (!row.client_id) {
            incompleteFields.push('Client ID');
        }
        if (!row.client_secret) {
            incompleteFields.push('Client Secret');
        }
    }

    return incompleteFields.length > 0
        ? `Configuration is in draft. Complete the ${incompleteFields.join(', ')} before using the user source`
        : '';
};

export const isDraftUserSource = (row: UserSource): boolean => {
    return !!getUserSourceDraftMessage(row);
};

const nameFormatOptions = [
    { label: 'Unspecified', value: 1 },
    { label: 'URI', value: 2 },
    { label: 'Basic', value: 3 },
];

const signingAlgOptions = [
    { label: 'RSA-SHA256', value: 'rsa-sha256' },
    { label: 'RSA-SHA512', value: 'rsa-sha512' },
];

export {
    nameFormatOptions,
    SAML_CODE,
    OIDC_CODE,
    OIDC_AZURE_CODE,
    SAML_LABEL,
    OIDC_LABEL,
    OIDC_AZURE_LABEL,
    PROTOCOL_MAP,
    signingAlgOptions,
    type Metadata,
    type SAMLFields,
    type OIDCFields,
};
