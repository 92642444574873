import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { brandingFormatter } from '@store/modules/settings/branding/helper';
import { loadSuccess as loadBrandingsSuccess } from './branding/userPortalBrandingsSlice';
import { loadSuccess as loadApplicationRealmsSuccess } from './userPortalRealmsSlice';
import { loadSuccess as loadApplicationsSuccess } from './userPortalsSlice';
import { loadSuccess as loadUserSourcesSuccess } from './userSource/userPortalUserSourcesSlice';

type ApplicationResource = {
    userPortals: Application[] | null;
    realms: RealmBrief[] | null;
    brandings: Branding[] | null;
    userSources: UserSource[] | null;
};

type NonNullableResource = {
    userPortals: NonNullable<ApplicationResource['userPortals']>;
    realms: NonNullable<ApplicationResource['realms']>;
    brandings: BrandingData[];
    userSources: NonNullable<ApplicationResource['userSources']>;
};

interface InitialState extends GenericState<ApplicationResource> {}

const initialState: InitialState = {
    loading: false,
    data: {
        userPortals: null,
        realms: null,
        brandings: null,
        userSources: null,
    },
    error: null,
};

const userPortalResourcesSlice = createGenericSlice({
    name: 'userPortalResources',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ isEditMode?: boolean }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: ApplicationResource }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadBrandingsSuccess, (state, action: PayloadAction<{ data: Branding[] }>) => {
            const { data: brandings } = action.payload;
            state.data.brandings = brandings;
        });
        builder.addCase(loadApplicationRealmsSuccess, (state, action: PayloadAction<{ data: RealmBrief[] }>) => {
            console.log('loadApplicationRealmsSuccess');
            const { data: realms } = action.payload;
            state.data.realms = realms;
            console.log(state.data);
        });
        builder.addCase(loadApplicationsSuccess, (state, action: PayloadAction<{ data: Application[] }>) => {
            const { data: applications } = action.payload;
            state.data.userPortals = applications;
        });
        builder.addCase(loadUserSourcesSuccess, (state, action: PayloadAction<{ data: UserSource[] }>) => {
            const { data: userSources } = action.payload;
            state.data.userSources = userSources;
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userPortalResourcesSlice.actions;

export const selecUserPortalResource = createSelector(
    (state: RootState) => state.userPortalResources.data,
    (data) => {
        let nonNullData: NonNullableResource = {
            userPortals: [],
            realms: [],
            brandings: [],
            userSources: [],
        };
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null) {
                nonNullData[key as keyof UserPortalResource] = value as any;
            }
        });
        nonNullData['brandings'] = (data['brandings'] ?? []).map(brandingFormatter);
        return nonNullData;
    }
);

export const selectUserPortalRealmOptions = createSelector(
    (state: RootState) => state.userPortalResources.data.realms?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export const selectUserPortalUserSourceOptions = createSelector(
    (state: RootState) =>
        state.userPortalResources.data.userSources?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export default userPortalResourcesSlice.reducer;
