import { SSOAccount } from '@store/modules/auth/ssoAccountSlice';

export const phraseAccount = (
    account: AuthUser,
    setAccountRequest: (data: SSOAccount) => {
        payload: { data: SSOAccount | { access_from: SSOAccount } };
        type: 'visitedUrls/setJsonAccount';
    }
): { h_key: string; region_domain?: string | null } => {
    const query = {
        source_app: 'FTC',
        account_id: account.account_id,
        user_id: account.user_id == null || account.user_id === 0 || account.user_id === -1 ? '0' : account.user_id,
        node_id: null,
        user_fullaccess: true,
        partner_id:
            account.partner_id == null || account.partner_id === 0 || account.partner_id === -1
                ? '0'
                : account.partner_id,
        context_data: '',
        visited_sites: [],
    };

    const updatedAccountEntries = {
        account_id: account.account_id,
        user_id: account.user_id == null || account.user_id === 0 || account.user_id === -1 ? '0' : account.user_id,
        partner_id:
            account.partner_id == null || account.partner_id === 0 || account.partner_id === -1
                ? '0'
                : account.partner_id,
    } as SSOAccount;
    setAccountRequest(updatedAccountEntries);
    return { h_key: JSON.stringify(query) };
};
