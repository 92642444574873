import { useEffect, useState } from 'react';
import { FixedLoading } from '@shared/components/Loading';
import { setYup } from '@shared/yupValidators';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { loadRequest as loadAdminAlert } from '@store/modules/adminAlert/adminAlertSlice';
import { loadAuth } from '@store/modules/auth/authSlice';
import { loadNavbar } from '@store/modules/layout/navbarSlice';
import { loadRequest as loadMetadata } from '@store/modules/metadata/metadataSlice';
import RoutesContainer from './routing/RoutesContainer';

setYup();

const App = () => {
    const dispatch = useAppDispatch();
    const authState = useAppSelector((state) => state.auth);
    const navbarState = useAppSelector((state) => state.navbar);
    const metadataState = useAppSelector((state) => state.metadata);

    const [isInitialDone, setIsInitialDone] = useState<boolean>(false);

    useEffect(() => {
        dispatch(loadAuth());
        dispatch(loadNavbar());
        dispatch(loadMetadata());
        dispatch(loadAdminAlert());
    }, []);

    useEffect(() => {
        if (
            authState.loading === false &&
            navbarState.loading === false &&
            metadataState.loading === false &&
            navbarState.data &&
            metadataState.data
        ) {
            setIsInitialDone(true);
        } else {
            setIsInitialDone(false);
        }
    }, [authState, navbarState, metadataState]);

    if (isInitialDone === false) {
        return <FixedLoading />;
    }

    return <RoutesContainer></RoutesContainer>;
};

export default App;
