import { createSelector } from '@reduxjs/toolkit';
import { REALM_KEY_MAP, compare } from '@pages/Realms/helper';
import { createRealm } from './helper';
import { RealmsResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface RealmsState extends GenericState<RealmsResponse | null> {}

const initialState: RealmsState = {
    loading: false,
    data: null,
    error: null,
};

const realmsSlice = createGenericSlice({
    name: 'realms',
    initialState: initialState as RealmsState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
        sortRealms: (state, action: PayloadAction<{ key: keyof typeof REALM_KEY_MAP; order: 'asc' | 'desc' }>) => {
            const { key, order } = action.payload;
            if (state.data) {
                // @ts-ignore
                state.data.realms?.sort((a, b) => compare(a[key], b[key], order));
            }
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad, sortRealms } = realmsSlice.actions;

export const selectAllRealms = createSelector(
    (state: RootState) => {
        const realmQuotaMap = state.realmQuotaMap.data;
        return state.realms.data?.realms?.map((realm) => createRealm(realm, realmQuotaMap));
    },
    (data) => data ?? []
);

export const selectQuota = createSelector(
    (state: RootState) => state.realms.data?.quota,
    (data) => data
);

export default realmsSlice.reducer;
