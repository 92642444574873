import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest, select } from 'redux-saga/effects';
import {
    getApplication,
    getApplications,
    deleteApplication,
    postApplication,
    putApplication,
    putAppUserSourceMapping,
} from '@services/api/application';
import { getBrandings } from '@services/api/branding';
import { getRealmsBrief } from '@services/api/realms';
import { getUserSources } from '@services/api/userSource';
import { USER_PORTAL_TYPE } from '@shared/utils/constants';
import { RootState } from '@store/index';
import {
    deleteRequest as deleteUserPortals,
    deleteSuccess as deleteUserPortalsSuccess,
    deleteFailure as deleteUserPortalsFailure,
    cancelDelete as cancelDeleteUserPortals,
} from './deleteUserPortalsSlice';
import {
    upsertRequest as upsertUserPortalRequest,
    upsertSuccess as upsertUserPortalSuccess,
    upsertFailure as upsertUserPortalFailure,
    cancelUpsert as cancelUpsertUserPortal,
} from './upsertUserPortalSlice';
import {
    loadRequest as loadUserPortalRealms,
    loadSuccess as loadUserPortalRealmsSuccess,
    loadFailure as loadUserPortalRealmsFailure,
    cancelLoad as cancelLoadUserPortalRealms,
} from './userPortalRealmsSlice';
import {
    loadRequest as loadUserPortalResources,
    loadSuccess as loadUserPortalResourcesSuccess,
    loadFailure as loadUserPortalResourcesFailure,
    cancelLoad as cancelLoadUserPortalResources,
} from './userPortalResourcesSlice';
import {
    loadRequest as loadUserPortal,
    loadSuccess as loadUserPortalSuccess,
    loadFailure as loadUserPortalFailure,
    cancelLoad as cancelLoadUserPortal,
} from './userPortalSlice';
import {
    loadRequest as loadUserPortals,
    loadSuccess as loadUserPortalsSuccess,
    loadFailure as loadUserPortalsFailure,
    cancelLoad as cancelLoadUserPortals,
} from './userPortalsSlice';

export function* fetchUserPortals(action: ReturnType<typeof loadUserPortals>) {
    try {
        const { response, cancel }: { response: UserPortal[]; cancel: any } = yield race({
            response: call(getApplications, { type: USER_PORTAL_TYPE }),
            cancel: take(cancelLoadUserPortals),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserPortalsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserPortalsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserPortal(action: ReturnType<typeof loadUserPortal>) {
    const id = action.payload.params.id;
    try {
        const { response, cancel }: { response: UserPortalDetail; cancel: any } = yield race({
            response: call(getApplication, { id }),
            // response: applications.find((app) => app.id === action.payload.params.id),
            cancel: take(cancelLoadUserPortal),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserPortalSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserPortalFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserPortalRealms(action: ReturnType<typeof loadUserPortalRealms>) {
    try {
        const { response, cancel }: { response: RealmBrief[]; cancel: any } = yield race({
            response: call(getRealmsBrief),
            cancel: take(cancelLoadUserPortalRealms),
        });

        if (cancel) {
            return;
        }

        yield put(loadUserPortalRealmsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserPortalRealmsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* upsertUserPortal(action: ReturnType<typeof upsertUserPortalRequest>) {
    const { id, data: dataWithCallback } = action.payload;
    const { user_source_ids, default_usersource_id, ...data } = dataWithCallback;

    // Only edit mode has id
    const upsertCall = id ? call(putApplication, { id, data }) : call(postApplication, { data });

    try {
        const { response, cancel }: { response: UserPortal; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertUserPortal),
        });

        if (cancel) {
            return;
        }

        if (user_source_ids || default_usersource_id !== undefined) {
            try {
                const appId = response.id;
                yield race({
                    response: call(putAppUserSourceMapping, {
                        appId,
                        data: { user_source_ids, default_usersource_id },
                    }),
                });
                toast.success('Mapping of UserPortal to User Source successful');
            } catch (e) {
                const errMsg =
                    typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
                toast.error(errMsg);
            }
        }
        yield put(upsertUserPortalSuccess({ data: response }));
        toast.success(`${id ? 'Update' : 'Create'} UserPortal Succeeded`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(upsertUserPortalFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* deleteUserPortalsRequest(action: ReturnType<typeof deleteUserPortals>) {
    const { ids } = action.payload;

    const deleteUserPortalCalls = ids.map((id) => call(deleteApplication, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteUserPortalCalls),
            cancel: take(cancelDeleteUserPortals),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteUserPortalsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadUserPortals());
        toast.success(`${ids.length} UserPortal Deleted`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(deleteUserPortalsFailure(errMsg));
        yield put(loadUserPortals());
        toast.error(errMsg);
    }
}

export function* fetchUserPortalResources(action: ReturnType<typeof loadUserPortalResources>) {
    const commonCalls = {
        brandings: call(getBrandings),
        userSources: call(getUserSources),
    };

    const appResourceCalls = commonCalls;
    try {
        const { response, cancel }: { response: UserPortalResource; cancel: any } = yield race({
            response: all(appResourceCalls),
            cancel: take(cancelLoadUserPortalResources),
        });

        if (cancel) {
            return;
        }

        let realms: RealmBrief[] = [];
        try {
            realms = yield select((state: RootState) => {
                console.log(state.userPortalResources.data);
                return state.userPortalResources.data.realms;
            });
            if (!realms) {
                const { response }: { response: RealmBrief[] } = yield race({ response: call(getRealmsBrief) });
                realms = response;
            }
        } catch (e) {}

        let userPortals: UserPortal[] = [];
        try {
            userPortals = yield select((state: RootState) => state.userPortalResources.data.userPortals);
            if (!userPortals) {
                const { response }: { response: UserPortal[] } = yield race({
                    response: call(getApplications, { type: USER_PORTAL_TYPE }),
                });
                userPortals = response;
            }
        } catch (e) {}
        yield put(loadUserPortalResourcesSuccess({ data: { ...response, realms, userPortals } }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadUserPortalResourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserPortalSaga() {
    yield takeLatest(loadUserPortal, fetchUserPortal);
}

export function* fetchUserPortalsSaga() {
    yield takeLatest(loadUserPortals, fetchUserPortals);
}

export function* upsertUserPortalSaga() {
    yield takeLatest(upsertUserPortalRequest, upsertUserPortal);
}

export function* deleteUserPortalsSaga() {
    yield takeLatest(deleteUserPortals, deleteUserPortalsRequest);
}

export function* fetchUserPortalRealmsSaga() {
    yield takeLatest(loadUserPortalRealms, fetchUserPortalRealms);
}

export function* fetchUserPortalResourcesSaga() {
    yield takeLatest(loadUserPortalResources, fetchUserPortalResources);
}
