import { createSelector } from '@reduxjs/toolkit';
import { RealmPermissionsParams } from '@services/api/realmPermissions';
import { createRealmWithPermissionID } from './helper';
import { RealmsByGroupResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<RealmsByGroupResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

// there are two groups in this data
// admin in group & admin not in group

const realmsByAdminGroupSlice = createGenericSlice({
    name: 'realmsByAdminGroup',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (
            state,
            action: PayloadAction<{
                inGroupParams: RealmPermissionsParams;
                notInGroupParams: RealmPermissionsParams;
            }>
        ) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmsByGroupResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = realmsByAdminGroupSlice.actions;

export const selectRealmsInGroup = createSelector(
    (state: RootState) =>
        state.realmsByAdminGroup.data?.realmsInGroup.map((realm) => createRealmWithPermissionID(realm)),
    (data) => data ?? []
);

export const selectRealmsNotInGroup = createSelector(
    (state: RootState) =>
        state.realmsByAdminGroup.data?.realmsNotInGroup.map((realm) => createRealmWithPermissionID(realm)),
    (data) => data ?? []
);

export default realmsByAdminGroupSlice.reducer;
