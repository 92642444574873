import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import {
    AlertResponse,
    deleteAlert,
    deleteAlertGroup,
    getAlerts,
    postAlert,
    postAlertGroup,
    putAlert,
} from '@services/api/alerts';
import { getGroup } from '@services/api/groups';
import { getQuota } from '@services/api/quota';
import { getRealmsBrief } from '@services/api/realms';
import { getReceivers } from '@services/api/receivers';
import { pluralize } from '@shared/utils/pluralize';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    createRequest as createEvent,
    createSuccess as createEventSuccess,
    createFailure as createEventFailure,
    cancelCreate as cancelCreateEvent,
} from './createEventSlice';
import {
    deleteRequest as deleteEvents,
    deleteSuccess as deleteEventsSuccess,
    deleteFailure as deleteEventsFailure,
    cancelDelete as cancelDeleteEvents,
} from './deleteEventsSlice';
import {
    loadRequest as loadEventReceivers,
    loadFailure as loadEventReceiversFailure,
    loadSuccess as loadEventReceiversSuccess,
    cancelLoad as cancelLoadEventReceivers,
} from './eventReceiversSlice';
import {
    loadRequest as loadEvents,
    loadFailure as loadEventsFailure,
    loadSuccess as loadEventsSuccess,
    cancelLoad as cancelLoadEvents,
} from './eventsSlice';
import {
    loadRequest as loadGroupByEvent,
    loadFailure as loadGroupByEventFailure,
    loadSuccess as loadGroupByEventSuccess,
    cancelLoad as cancelLoadGroupByEvent,
} from './groupsByEventSlice';
import {
    updateRequest as updateEvent,
    updateSuccess as updateEventSuccess,
    updateFailure as updateEventFailure,
    cancelUpdate as cancelUpdateEvent,
} from './updateEventSlice';

export type EventResponse = {
    alerts: AlertResponse;
    realms: RealmBrief[];
    realmQuota: QuotaBreif;
    groups: Group[];
};

export function* fetchEvents(action: ReturnType<typeof loadEvents>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: EventResponse; cancel: any } = yield race({
            response: all({
                alerts: call(getAlerts),
                realms: call(getRealmsBrief),
                realmQuota: call(getQuota, params),
                groups: call(getGroup, {}),
            }),
            cancel: take(cancelLoadEvents),
        });

        if (cancel) {
            return;
        }

        yield put(loadEventsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadEventsFailure(e?.response?.data?.error_message));
    }
}

export function* fetchEventsSaga() {
    yield takeLatest(loadEvents, fetchEvents);
}

export function* fetchGroupsByEvent(action: ReturnType<typeof loadGroupByEvent>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Group[]; cancel: any } = yield race({
            response: call(getGroup, { params }),
            cancel: take(cancelLoadGroupByEvent),
        });

        if (cancel) {
            return;
        }

        yield put(loadGroupByEventSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadGroupByEventFailure(e?.response?.data?.error_message));
    }
}

export function* fetchGroupsByEventSaga() {
    yield takeLatest(loadGroupByEvent, fetchGroupsByEvent);
}

export function* deleteEventsRequest(action: ReturnType<typeof deleteEvents>) {
    const { ids } = action.payload;

    const deleteCalls = ids.map((id) => call(deleteAlert, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteEvents),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give data to determin to close the modal after success
        yield put(deleteEventsSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadEvents({ params: { brief: true } }));
        const count = ids.length;
        toast.success(`${count} ${pluralize('Alert', count)} Deleted`);
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(deleteEventsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* deleteEventsSaga() {
    yield takeLatest(deleteEvents, deleteEventsRequest);
}

export function* fetchEventReceivers(action: ReturnType<typeof loadEventReceivers>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Receiver[]; cancel: any } = yield race({
            response: call(getReceivers, { params }),
            cancel: take(cancelLoadEventReceivers),
        });

        if (cancel) {
            return;
        }

        yield put(loadEventReceiversSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadEventReceiversFailure(e?.response?.data?.error_message));
    }
}

export function* fetchEventReceiversSaga() {
    yield takeLatest(loadEventReceivers, fetchEventReceivers);
}

export function* createEventRequest(action: ReturnType<typeof createEvent>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(postAlert, { data }),
            cancel: take(cancelCreateEvent),
        });

        if (cancel) {
            return;
        }

        yield put(createEventSuccess({ data: response }));
        // load initial data after successfully
        yield put(loadEvents({ params: { brief: true } }));
        toast.success('Alarm Event Created');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(createEventFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* createEventSaga() {
    yield takeLatest(createEvent, createEventRequest);
}

export function* updateEventRequest(action: ReturnType<typeof updateEvent>) {
    const {
        id,
        data: {
            alarm,
            group: { addIds, removeIds },
        },
    } = action.payload;

    // Three types of call
    // 1. update alarm
    // 2. delete groups
    // 3. add groups

    const eventCalls = [call(putAlert, { id, data: alarm })];
    if (addIds.length > 0) {
        addIds.forEach((alert) => eventCalls.push(call(postAlertGroup, { id, data: alert })));
    }

    if (removeIds.length > 0) {
        removeIds.forEach((groupId) => eventCalls.push(call(deleteAlertGroup, { alertId: id, groupId })));
    }

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: all(eventCalls),
            cancel: take(cancelUpdateEvent),
        });

        if (cancel) {
            return;
        }

        yield put(updateEventSuccess({ data: response }));
        // load initial data after successfully
        yield put(loadEvents({ params: { brief: true } }));
        toast.success('Alarm Event Updated');
    } catch (e) {
        const errMsg = e?.response?.data?.error_message;
        yield put(updateEventFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateEventSaga() {
    yield takeLatest(updateEvent, updateEventRequest);
}
