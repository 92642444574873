import debounce from 'debounce';
import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import RegionModal from '@pages/AccountSelection/RegionSelectionModal';
import { AccountCard } from '@shared/components/AccountCard';
import { Icon } from '@shared/components/Icon';
import { FixedLoading, RelativeLoading } from '@shared/components/Loading';
import { useModal } from '@shared/hooks/useModal';
import { FTC_NO_LICENSE } from '@shared/utils/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { selectAccountList, selectFilteredAccountList, selectOrgList } from '@store/modules/accounts/accountsSlice';
import {
    loadRequest as loadAccountsStatus,
    selectAccountsStatusMap,
} from '@store/modules/accounts/accountsStatusSlice';
import { loadLogin } from '@store/modules/auth/loginSlice';

import '../AccountSelection.scss';
import { SSOAccount, setJsonAccount } from '@store/modules/auth/ssoAccountSlice';
import { selectRegions } from '@store/modules/regions/regionsSlice';
import { phraseAccount } from '../helper';

const AccountView = (props: { filter: string; loadAccounts: () => void }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const originalAccountList = useAppSelector(selectAccountList);
    const accountList = useAppSelector(selectFilteredAccountList);
    const organizationList = useAppSelector(selectOrgList);
    const regionList = useAppSelector(selectRegions);
    const { isOpen: isRegionModalOpen, toggle: toggleRegionModal, close: closeRegionModal } = useModal();
    const authState = useAppSelector((state: RootState) => state.auth);
    const accountsState = useAppSelector((state: RootState) => state.accounts);
    const accountsStatusMap = useAppSelector(selectAccountsStatusMap);
    const [showLoadingMask, setShowLoadingMask] = useState(false);
    const { filter, loadAccounts } = props;
    const debouncedLoadAccounts = debounce(loadAccounts, 200);
    const debouncedFilterTerm = filter;

    // list
    const ITEMS_PER_PAGE = 6;

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const [filteredAccountList, setFilterAccountList] = useState<typeof accountList>([]);
    const [currentDisplayItems, setCurrentDisplayItems] = useState<typeof accountList>([]);

    let interactiveMessage = null;
    if (originalAccountList.length === 0) {
        interactiveMessage = 'Please register account on https://support.fortinet.com.';
    } else if (accountList.length === 0) {
        interactiveMessage = 'Please click refresh button to acquire the latest account list.';
    }

    // search input
    // const [filter, setFilter] = useState<string>('');

    /**
     * The useDebounce hook is useful for delaying the execution of functions or state updates
     * until a specified time period has passed without any further changes to the input value.
     * This is especially useful in scenarios such as handling user input or triggering network
     * requests, where it effectively reduces unnecessary computations and ensures that
     * resource-intensive operations are only performed after a pause in the input activity.
     */
    // const debouncedFilterTerm = useDebounce(filter, 300);

    // initial filterAccountList value
    useEffect(() => {
        if (accountList.length) {
            setFilterAccountList(accountList);
        }
        if (authState.user?.to_select) {
            // query the select user account status
            const selectedAcccount = accountList.find(
                (account) => account.account_id.toString() === authState.user?.to_select.account_id.toString()
            );
            if (selectedAcccount) {
                dispatch(loadAccountsStatus({ accounts: [selectedAcccount] }));
            }
        }
    }, [accountList]);

    // when filter result changes
    useEffect(() => {
        if (filteredAccountList.length) {
            const endOffset = itemOffset + ITEMS_PER_PAGE;
            // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            setCurrentDisplayItems(filteredAccountList.slice(itemOffset, endOffset));
            setCurrentPage(0);
        } else {
            setCurrentDisplayItems([]);
        }
    }, [filteredAccountList]);

    // when current page changes
    useEffect(() => {
        if (filteredAccountList.length) {
            const itemOffset = currentPage * ITEMS_PER_PAGE;
            const endOffset = itemOffset + ITEMS_PER_PAGE;
            // console.log('new currentpage', currentPage);
            // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            setCurrentDisplayItems(filteredAccountList.slice(itemOffset, endOffset));
        }
    }, [filteredAccountList, currentPage]);

    // when user type filter wording
    useEffect(() => {
        const source = [...accountList];

        if (!debouncedFilterTerm || debouncedFilterTerm.trim() === '') {
            setFilterAccountList(source);
        } else {
            const result = source.filter((s) => {
                if (
                    s.account_id.toString() === debouncedFilterTerm ||
                    s.account_id.toString().includes(debouncedFilterTerm)
                ) {
                    return true;
                } else if (s.account_email.toLocaleLowerCase().includes(debouncedFilterTerm.toLocaleLowerCase())) {
                    return true;
                } else if (s.account_company.toLocaleLowerCase().includes(debouncedFilterTerm.toLocaleLowerCase())) {
                    return true;
                }
                return false;
            });

            setFilterAccountList(result);
        }
    }, [debouncedFilterTerm]);

    useEffect(() => {
        const accountsToQuery = currentDisplayItems.filter((account) => accountsStatusMap[account.account_id] == null);
        if (!accountsToQuery || accountsToQuery.length === 0) return;
        dispatch(loadAccountsStatus({ accounts: accountsToQuery }));
    }, [currentDisplayItems]);

    useEffect(() => {
        if (accountList.length === 1 && !organizationList.length && !authState.user?.to_select) {
            // skip the auto selection for first time trial user
            if (
                accountsStatusMap[accountList[0].account_id] != null &&
                accountsStatusMap[accountList[0].account_id]['ftc_status'] !== FTC_NO_LICENSE
            ) {
                selectAccount(accountList[0]);
            }
        }
        if (authState.user?.to_select) {
            // skip the auto login for first time trial user and navigate to introduction
            const selectedAccount = accountList.find(
                (account) => account.account_id.toString() === authState.user?.to_select.account_id.toString()
            );
            if (selectedAccount && accountsStatusMap[selectedAccount.account_id] !== null) {
                if (
                    accountsStatusMap[selectedAccount.account_id]['ftc_status'] !== FTC_NO_LICENSE ||
                    // when login from another FTC region, no need for intro
                    authState.user?.to_select.source_app === 'FTC'
                ) {
                    dispatch(setJsonAccount({ data: authState.user?.to_select }));
                    const loginbody: { h_key: string; trial?: boolean; region_domain?: string | null } = {
                        h_key: JSON.stringify(authState.user?.to_select),
                    };
                    if (
                        accountsStatusMap[selectedAccount.account_id] != null &&
                        accountsStatusMap[selectedAccount.account_id]?.region_domain != null
                    ) {
                        loginbody['region_domain'] = accountsStatusMap[selectedAccount.account_id].region_domain;
                    }
                    if (
                        regionList.length <= 1 ||
                        !!loginbody?.region_domain ||
                        authState.user?.to_select.source_app === 'FTC'
                    ) {
                        setShowLoadingMask(true);
                        dispatch(loadLogin(loginbody));
                    } else {
                        toggleRegionModal();
                    }
                } else {
                    navigate(`./${selectedAccount.account_id}/introduction`);
                }
            }
        }
    }, [accountsStatusMap]);

    const handlePageClick = (event: any) => {
        setCurrentPage(event.selected);
        const newOffset = (event.selected * ITEMS_PER_PAGE) % filteredAccountList.length;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    const selectAccount = (account: AuthUser) => {
        if (accountsStatusMap[account.account_id]?.['ftc_status'] === FTC_NO_LICENSE) {
            navigate(`./${account.account_id}/introduction`);
        } else {
            const loginbody = {
                ...phraseAccount(account, (data: SSOAccount) => dispatch(setJsonAccount({ data: data }))),
            };
            if (
                accountsStatusMap[account.account_id] != null &&
                accountsStatusMap[account.account_id]?.region_domain != null
            ) {
                loginbody['region_domain'] = accountsStatusMap[account.account_id].region_domain;
            }
            if (regionList.length <= 1 || !!loginbody?.region_domain) {
                setShowLoadingMask(true);
                dispatch(loadLogin(loginbody));
            } else {
                toggleRegionModal();
            }
        }
    };

    return (
        <div id="account-selection-page">
            {accountsState.loading ? (
                <RelativeLoading />
            ) : (
                <>
                    {showLoadingMask && <FixedLoading />}
                    {!!interactiveMessage && (
                        <div className="d-flex flex-column">
                            <div className="p-4">
                                <img
                                    src={process.env.PUBLIC_URL + '/media/Illustration_Error=2X.svg'}
                                    alt="Illustration_Error.svg"
                                />
                            </div>

                            <h2>Get account list failed</h2>
                            <div className="mb-2">
                                <p>{interactiveMessage}</p>
                            </div>
                            <div>
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        debouncedLoadAccounts();
                                    }}
                                >
                                    Try Again
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="container-fluid container-fix">
                        <div className="row g-4 position-relative justify-content-center">
                            {filteredAccountList.length > ITEMS_PER_PAGE && (
                                <>
                                    <button
                                        id="account-select-prev"
                                        disabled={currentPage === 0}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentPage(currentPage - 1);
                                        }}
                                    >
                                        {/* prev */}
                                        <Icon classNames={['material-symbols-outlined']} content="navigate_before" />
                                    </button>
                                    <button
                                        id="account-select-next"
                                        disabled={currentPage > 0 && currentDisplayItems.length < ITEMS_PER_PAGE}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setCurrentPage(currentPage + 1);
                                        }}
                                    >
                                        {/* next */}
                                        <Icon classNames={['material-symbols-outlined']} content="navigate_next" />
                                    </button>
                                </>
                            )}

                            {currentDisplayItems.map((d, index) => {
                                return (
                                    <div className="col-4" key={index}>
                                        <AccountCard
                                            // @ts-ignore
                                            user={d}
                                            activeUser={authState.user}
                                            onClick={selectAccount}
                                            seq={index}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-6">
                        <ReactPaginate
                            className={['pagination', filteredAccountList.length > ITEMS_PER_PAGE ? '' : 'd-none'].join(
                                ' '
                            )}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                            activeLinkClassName="active"
                            previousClassName="page-item d-none"
                            previousLinkClassName="page-link"
                            nextClassName="page-item d-none"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            forcePage={currentPage}
                            pageRangeDisplayed={2}
                            pageCount={Math.ceil(filteredAccountList.length / ITEMS_PER_PAGE)}
                            previousLabel="Previous"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </>
            )}
            <RegionModal
                isOpen={isRegionModalOpen}
                toggle={toggleRegionModal}
                onClosed={closeRegionModal}
                onCanceled={closeRegionModal}
                meta={{
                    regions: regionList,
                }}
            />
        </div>
    );
};
export { AccountView };
