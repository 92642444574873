import { Button, UncontrolledTooltip } from 'reactstrap';
import { FModal } from '@shared/components/FtcModal';
import { FixedLoading } from '@shared/components/Loading';
import { useAppDispatch } from '@store/hooks';
import { deleteRequest as deleteBrandingRequest } from '@store/modules/settings/branding/deleteBrandingsSlice';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onClosed: () => void;
    onCanceled: () => void;
    data: BrandingData[];
    meta: {
        selectedIds: string[];
        submitLoading: boolean;
    };
};
function DeleteModal({ isOpen, toggle, onClosed, onCanceled, data, meta }: Props) {
    const dispatch = useAppDispatch();
    const { selectedIds, submitLoading } = meta;
    const selectedBrandings = data.filter(({ id }: { id: string }) => selectedIds.includes(id));
    return (
        <FModal isOpen={isOpen} toggle={toggle} onClosed={onClosed}>
            <FModal.ModalHeader toggle={toggle}>Delete Branding(s)</FModal.ModalHeader>
            <FModal.ModalBody>
                <>
                    {/* loading */}
                    {submitLoading && <FixedLoading />}
                    <div className="plain-text">
                        <>
                            Are you sure you want to delete the following Branding? Please note if the Branding is used
                            by any application, the branding of the application will fail back to Default or FTC
                            Template Branding.
                        </>
                        <ul>
                            {selectedBrandings.map(({ name, apps, id }) => {
                                return (
                                    <>
                                        <li key={name}>
                                            <span id={`us-${id}`} key={`us-${id}`}>
                                                Name: {name}, Apps: {apps ? apps.split(',').length : '0'}
                                            </span>
                                        </li>
                                        <UncontrolledTooltip placement="top" target={`us-${id}`}>
                                            {apps ? 'Used by: ' + apps : 'Unused'}
                                        </UncontrolledTooltip>
                                    </>
                                );
                            })}
                        </ul>
                    </div>
                </>
            </FModal.ModalBody>
            <FModal.ModalFooter>
                <Button color="primary" outline onClick={onCanceled}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        dispatch(deleteBrandingRequest({ ids: selectedIds }));
                    }}
                    disabled={submitLoading}
                >
                    Yes
                </Button>
            </FModal.ModalFooter>
        </FModal>
    );
}

export default DeleteModal;
