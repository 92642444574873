import { PayloadAction, createSelector } from '@reduxjs/toolkit';
import { ResourcesCountParams } from '@services/api/resources';
import { HardTokenParams, TempTokenParams } from '@services/api/tokens';
import { formatUser } from './helper';
import { UsersResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { RootState } from '@store/index';

interface UserState extends GenericState<UsersResponse | null> {}

const initialState: UserState = {
    loading: false,
    data: null,
    error: null,
};

export type loadUserParams = {
    tempToken: TempTokenParams;
    hardToken: HardTokenParams;
    SMSCount: ResourcesCountParams;
};

const usersSlice = createGenericSlice({
    name: 'users',
    initialState: initialState as UserState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: loadUserParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UsersResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usersSlice.actions;

export const selectRealms = createSelector(
    (state: RootState) => state.users.data?.realms || [],
    (data) => data ?? []
);

export const selectAllUsers = createSelector(
    (state: RootState) => {
        const { users, realms, tempTokens } = state.users.data || {};
        // need to check if get user by id will miss any info
        return users?.map((item) => formatUser(item, realms ?? [], tempTokens ?? []));
    },
    (data) => data ?? []
);

export const selectHardTokens = createSelector(
    (state: RootState) => state.users.data?.hardTokens,
    (data) => data ?? []
);

export default usersSlice.reducer;
