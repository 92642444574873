import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import { UsageRealmData } from '../usage/realmSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface QuotaState extends GenericState<QuotaDetail | null> {}

const initialState: QuotaState = {
    loading: false,
    data: null,
    error: null,
};

const quotaSlice = createGenericSlice({
    name: 'quota',
    initialState: initialState as QuotaState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: QuotaDetail }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = quotaSlice.actions;

const findQuotaRealmById = (realms: QuotaDetail['realms'], id: string): Unpacked<QuotaDetail['realms']> | undefined => {
    return realms.find((r) => r[0] === id);
};

export const selectCurrentRealmsWithQuota = createSelector(
    (state: RootState) => state.usageRealms.data,
    (state: RootState) => state.quota.data?.realms,
    (usageRealms, quotaRealms) => {
        if (!usageRealms || !quotaRealms) {
            return [];
        }

        const result: (UsageRealmData & { allocatedUserQuota: number | null; userCount: number | null })[] = [
            ...usageRealms,
        ]
            .filter((d) => d.hasOwnProperty('client_count'))
            .map((d) => {
                const quotaRealm = quotaRealms ? findQuotaRealmById(quotaRealms, d.id) : null;
                return {
                    ...d,
                    allocatedUserQuota: quotaRealm?.[1] || null,
                    userCount: quotaRealm?.[2] || null,
                };
            });

        return result;
    }
);

export default quotaSlice.reducer;
