import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getRealmsBrief } from '@services/api/realms';
import { getUsage, UsageResponse, UsageRealmResponse } from '@services/api/usage';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadUsageRealm,
    loadFailure as loadUsageRealmFailure,
    loadSuccess as loadUsageRealmSuccess,
    cancelLoad as cancelLoadUsageRealm,
} from './realmSlice';
import {
    loadRequest as loadUsageSummary,
    loadFailure as loadUsageSummaryFailure,
    loadSuccess as loadUsageSummarySuccess,
    cancelLoad as cancelLoadUsageSummary,
} from './summarySlice';
import {
    loadRequest as loadUsage,
    loadFailure as loadUsageFailure,
    loadSuccess as loadUsageSuccess,
    cancelLoad as cancelLoadUsage,
} from './usageSlice';

export function* fetchUsageRealms(action: ReturnType<typeof loadUsageRealm>) {
    try {
        const {
            response,
            cancel,
        }: { response: { realms: RealmBriefWithAppCount[]; usageDeletedRealms: UsageRealmResponse }; cancel: any } =
            yield race({
                response: all({
                    realms: call(getRealmsBrief, { application_count: true }),
                    usageDeletedRealms: call(getUsage, action.payload),
                }),
                cancel: take(cancelLoadUsageRealm),
            });

        if (cancel) {
            return;
        }

        const { realms, usageDeletedRealms } = response;
        yield put(
            loadUsageRealmSuccess({
                data: realms
                    .map((realm) => {
                        return {
                            id: realm['id'],
                            name: realm['name'],
                            client_count: realm['client_count'],
                            application_count: realm['application_count'],
                        };
                    })
                    .concat(
                        usageDeletedRealms.map((realm) => {
                            return { client_count: 0, application_count: 0, ...realm };
                        })
                    ),
            })
        );
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadUsageRealmFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchUsage(action: ReturnType<typeof loadUsage>) {
    try {
        const { response, cancel }: { response: UsageResponse; cancel: any } = yield race({
            response: call(getUsage, action.payload),
            cancel: take(cancelLoadUsage),
        });

        if (cancel) {
            return;
        }

        yield put(loadUsageSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadUsageFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchSummaryUsage(action: ReturnType<typeof loadUsageSummary>) {
    try {
        const { response, cancel }: { response: UsageResponse; cancel: any } = yield race({
            response: call(getUsage, action.payload),
            cancel: take(cancelLoadUsageSummary),
        });

        if (cancel) {
            return;
        }

        yield put(loadUsageSummarySuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadUsageSummaryFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchUsageReamlSaga() {
    yield takeLatest(loadUsageRealm, fetchUsageRealms);
}

export function* fetchUsageSaga() {
    yield takeLatest(loadUsage, fetchUsage);
}

export function* fetchSummaryUsageSaga() {
    yield takeLatest(loadUsageSummary, fetchSummaryUsage);
}
