import { toast } from 'react-toastify';
import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getBrandings, postBranding } from '@services/api/branding';
import {
    createRequest as createBrandingRequest,
    createSuccess as createBrandingSuccess,
    createFailure as createBrandingFailure,
    cancelCreate as cancelcreateBranding,
} from './createUserPortalBrandingSlice';
import {
    loadRequest as loadBrandings,
    loadSuccess as loadBrandingsSuccess,
    loadFailure as loadBrandingsFailure,
    cancelLoad as cancelLoadBrandings,
} from './userPortalBrandingsSlice';

export function* fetchBranding(action: ReturnType<typeof loadBrandings>) {
    try {
        const { response, cancel }: { response: Branding[]; cancel: any } = yield race({
            response: call(getBrandings),
            cancel: take(cancelLoadBrandings),
        });

        if (cancel) {
            return;
        }

        yield put(loadBrandingsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadBrandingsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* createBranding(action: ReturnType<typeof createBrandingRequest>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: Branding; cancel: any } = yield race({
            response: call(postBranding, { data }),
            cancel: take(cancelcreateBranding),
        });

        if (cancel) {
            return;
        }

        yield put(createBrandingSuccess({ data: response }));

        // load initial data
        yield put(loadBrandings());
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(createBrandingFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchUserPortalBrandingsSaga() {
    yield takeLatest(loadBrandings, fetchBranding);
}

export function* createUserPortalBrandingSaga() {
    yield takeLatest(createBrandingRequest, createBranding);
}
