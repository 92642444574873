/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

export const localTime = (date: string | null) => {
    if (!date) {
        return '';
    }
    const locale = window.navigator.language || 'en-US';
    if (date.indexOf('Z') === -1) {
        return new Date(date + 'Z').toLocaleString(locale);
    }
    return new Date(date).toLocaleString(locale);
};

export const getDaysExpired = (expiredAt: string | null) => {
    if (!expiredAt) {
        return 0;
    }
    const interval = Date.now() - new Date(expiredAt).getTime();
    return Math.ceil(interval / (1000 * 3600 * 24));
};

// format time string from ISO 8601 to MM/DD/YYYY, HH:MM:SS AM/PM
export const formatTimeString = (isoDate: string) => {
    return dayjs(isoDate).tz(dayjs.tz.guess()).format('M/D/YYYY, h:mm:ss A');
};
