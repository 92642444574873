import { useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FtcModal } from '@shared/components/FtcModal';
import { FixedLoading } from '@shared/components/Loading';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { loadLogin } from '@store/modules/auth/loginSlice';
import { getJsonAccount } from '@store/modules/auth/ssoAccountSlice';
import './RegionSelectionModal.scss';

type Props = {
    isOpen: boolean;
    toggle: () => void;
    onCanceled: () => void;
    onClosed: () => void;
    meta: {
        regions: Region[];
    };
};

function RegionModal({ isOpen, toggle, onClosed, onCanceled, meta }: Props) {
    const { regions } = meta;

    const dispatch = useAppDispatch();
    const jsonAccount = useAppSelector(getJsonAccount);
    const [showLoadingMask, setShowLoadingMask] = useState(false);
    const handleClick = (region_domain: string) => {
        const loginBody = { h_key: jsonAccount, region_domain: region_domain };
        setShowLoadingMask(true);
        dispatch(loadLogin(loginBody));
    };

    return (
        <FtcModal
            header={`Please select a region:`}
            size="lg"
            isOpen={isOpen}
            toggle={toggle}
            hasFooter={false}
            onClosed={onClosed}
            onCanceled={onCanceled}
            backdrop="static"
        >
            <>
                {showLoadingMask && <FixedLoading />}
                <div id="world-background"></div>
                <div id="option-section">
                    {regions.map((region, index) => {
                        return (
                            <div className={regions.length > 4 ? 'half-width' : 'option'}>
                                <Button
                                    id={region.name + index}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClick(region.domain);
                                    }}
                                >
                                    {region.name.toUpperCase()} ({region.location})
                                </Button>
                                <UncontrolledTooltip placement="right" target={region.name + index}>
                                    {region.description || region.location}
                                </UncontrolledTooltip>
                            </div>
                        );
                    })}
                </div>
            </>
        </FtcModal>
    );
}

export default RegionModal;
