import { toast } from 'react-toastify';
import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { getClients, deleteClient, putClient } from '@services/api/clients';
import { getProfiles } from '@services/api/profiles';
import { getRealmsBrief } from '@services/api/realms';
import { getUsersRef } from '@services/api/users';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import { loadRequest as loadResources } from '@store/modules/resources/resourcesSlice';
import {
    deleteRequest as deleteClients,
    deleteSuccess as deleteClientsSuccess,
    deleteFailure as deleteClientsFailure,
    cancelDelete as cancelDeleteClients,
} from './deleteFortiProductClientsSlice';
import {
    loadRequest as loadClients,
    loadFailure as loadClientsFailure,
    loadSuccess as loadClientsSuccess,
    cancelLoad as cancelLoadClients,
} from './fortiProductClientsSlice';
import {
    loadRequest as loadClientUsers,
    loadFailure as loadClientUsersFailure,
    loadSuccess as loadClientUsersSuccess,
    cancelLoad as cancelLoadClientUsers,
} from './fortiProductClientUsersSlice';
import {
    upsertRequest as updateClient,
    upsertSuccess as updateClientSuccess,
    upsertFailure as updateClientFailure,
    cancelUpsert as cancelUpdateClient,
} from './updateFortiProductClientSlice';

export type ClientsResponse = {
    clients: Client[];
    realms: RealmBrief[];
    profiles: Profile[];
};

const clientParams = { params: { type: 'Fortinet' } };
const RETRY_TIME = 15 * 1000;

export function* fetchClients(action: ReturnType<typeof loadClients>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: ClientsResponse; cancel: any } = yield race({
            response: all({
                clients: call(getClients, { params }),
                realms: call(getRealmsBrief),
                profiles: call(getProfiles),
            }),
            cancel: take(cancelLoadClients),
        });

        if (cancel) {
            return;
        }

        yield put(loadClientsSuccess({ data: response }));

        const { clients } = response;
        const shouldRetry = clients.find(({ task_id }) => !!task_id);

        while (shouldRetry) {
            yield delay(RETRY_TIME);
            yield put(loadResources());
            yield put(loadClients(clientParams));
        }
    } catch (e) {
        showErrorAlert(e);
        yield put(loadClientsFailure(e.message));
    }
}

export function* fetchClientUsers(action: ReturnType<typeof loadClientUsers>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: User[]; cancel: any } = yield race({
            response: call(getUsersRef, { params }),
            cancel: take(cancelLoadClientUsers),
        });

        if (cancel) {
            return;
        }

        yield put(loadClientUsersSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadClientUsersFailure(e.message));
    }
}

export function* deleteClientsRequest(action: ReturnType<typeof deleteClients>) {
    const { ids } = action.payload;

    const deleteClientCalls = ids.map((id) => call(deleteClient, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteClientCalls),
            cancel: take(cancelDeleteClients),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteClientsSuccess({ data: 'success' }));

        //  load initial data
        yield put(loadResources());
        yield put(loadClients(clientParams));
        toast.success('Request Success');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(deleteClientsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateClientRequest(action: ReturnType<typeof updateClient>) {
    const { id, data } = action.payload;

    try {
        const { response, cancel }: { response: Client; cancel: any } = yield race({
            response: call(putClient, { id, data }),
            cancel: take(cancelUpdateClient),
        });

        if (cancel) {
            return;
        }

        yield put(updateClientSuccess({ data: response }));

        // load initial data
        yield put(loadResources());
        yield put(loadClients(clientParams));
        toast.success('Request Succeeded');
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(updateClientFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* updateClientSaga() {
    yield takeLatest(updateClient, updateClientRequest);
}

export function* deleteClientsSaga() {
    yield takeLatest(deleteClients, deleteClientsRequest);
}

export function* fetchClientsSaga() {
    yield takeLatest(loadClients, fetchClients);
}

export function* fetchClientUsersSaga() {
    yield takeLatest(loadClientUsers, fetchClientUsers);
}
