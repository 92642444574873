import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import type { NAVIGATION_UNIT } from './index';

export function Collapse({
    data,
    isDefaultOpen = false,
    levelStart = 1,
}: {
    data: NAVIGATION_UNIT;
    isDefaultOpen: boolean;
    levelStart: number;
}) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        setIsOpen(isDefaultOpen);
    }, [isDefaultOpen]);

    return (
        <>
            <button
                type="button"
                title={data.name}
                className={['collapse-btn', isOpen ? 'active' : ''].join(' ')}
                onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    toggle();
                }}
            >
                {data.meta.icon} <span>{data.name}</span>
            </button>
            <ul className={['collapse-ul', isOpen ? 'show' : ''].join(' ')}>
                {data.sub &&
                    data.sub.map((l2, l2Index) => {
                        return (
                            <li key={l2Index} className={`level-${levelStart}-li`}>
                                <div id={l2.meta?.tooltip?.id}>
                                    <NavLink to={`${data.to}${l2.to}`} className={l2.meta?.disabled ? 'disabled' : ''}>
                                        <span>{l2.name}</span>
                                    </NavLink>
                                </div>
                                {l2.meta?.tooltip && (
                                    <UncontrolledTooltip placement="right" target={l2.meta?.tooltip?.id}>
                                        {l2.meta?.tooltip.content}
                                    </UncontrolledTooltip>
                                )}

                                {/* level 3 */}
                                {Array.isArray(l2.sub) ? (
                                    <ul>
                                        {l2.sub.map((l3, l3Index) => {
                                            return (
                                                <li key={l3Index} className={`level-${levelStart + 1}-li`}>
                                                    <NavLink to={`${data.to}${l2.to}${l3.to}`}>
                                                        <span>{l3.name}</span>
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : undefined}
                            </li>
                        );
                    })}
            </ul>
        </>
    );
}
