import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';

interface InitialState extends GenericState<RealmBrief[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userPortalRealmsSlice = createGenericSlice({
    name: 'userPortalRealms',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmBrief[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userPortalRealmsSlice.actions;

export const selectUserPortalRealms = createSelector(
    (state: RootState) => state.userPortalRealms.data,
    (data) => data ?? []
);

export const selectUserPortalRealmOptions = createSelector(
    (state: RootState) => state.userPortalRealms.data?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export default userPortalRealmsSlice.reducer;
