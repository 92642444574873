import { parseHTMLStr } from '@shared/utils/parseHTMLStr';

export const createAuthLog = (element: AuthLogsHits): AuthLogData => {
    return {
        timestamp: new Date(element.fields['@timestamp']?.[0]).toLocaleString(),
        username: typeof element.fields['extra.user'] === 'undefined' ? '-' : element.fields['extra.user'][0],
        action: element.fields['extra.action'][0],
        status: element.fields['extra.status'][0],
        result: element.fields['extra.status']
            ? Math.floor(element.fields['extra.status']?.[0] / 100) === 2
                ? 'Success'
                : 'Failed'
            : '',
        requestId: element.fields['context.request_id'][0],
        realm: element.fields['extra.realm_id']?.[0],
        device: element.fields['context.device'][0],
        userIp: element.fields['extra.user_ip']?.[0] ?? 'N/A',
        location: element.fields['extra.location']?.[0] ?? 'N/A',
        message: parseHTMLStr(element.fields.message[0]),
    };
};

export const createMgmtLog = (element: MgmtLogsHits): MgmtLogData => {
    const geoInfo = [
        element.fields['geoip.city_name'],
        element.fields['geoip.region_name'],
        element.fields['geoip.country_name'],
    ];
    return {
        timestamp: new Date(element.fields['@timestamp']?.[0]).toLocaleString(),
        username:
            typeof element.fields['context.username'] === 'undefined'
                ? typeof element.fields['context.device'] === 'undefined'
                    ? '-'
                    : element.fields['context.device'][0]
                : element.fields['context.username'][0],
        action: element.fields['extra.action']?.[0] ?? '',
        status:
            typeof element.fields.status === 'undefined' ? element.fields.response_code?.[0] : element.fields.status[0],
        requestId: element.fields['context.request_id'][0],
        clientType:
            typeof element.fields['context.client_type'] === 'undefined'
                ? typeof element.fields['context.device'] === 'undefined'
                    ? '-'
                    : element.fields['context.device'][0]
                : element.fields['context.client_type'][0],
        clientIp: element.fields['context.client_ip']?.[0] ?? 'NA',
        resource: element.fields.resource[0],
        resourceId:
            typeof element.fields['extra.resource_id'] !== 'undefined'
                ? typeof element.fields['extra.resource_id'][0] === 'string'
                    ? element.fields['extra.resource_id'][0]
                    : typeof element.fields['extra.resource_id'][0] === 'object'
                    ? element.fields['extra.resource_id'][0]?.join(', ')
                    : ''
                : '',
        realmId: typeof element.fields['context.realm_id'] === 'undefined' ? '' : element.fields['context.realm_id'][0],
        requestInfo: typeof element.fields.message === 'undefined' ? '-' : parseHTMLStr(element.fields.message[0]),
        responseInfo:
            typeof element.fields['extra.response'] === 'undefined'
                ? '-'
                : JSON.stringify(element.fields['extra.response'][0]),
        location: geoInfo.some((item) => item == null) ? 'N/A' : geoInfo.join(', '),
    };
};

export const createSMSLog = (element: SMSLogsHits): SMSLogData => {
    return {
        timestamp: new Date(element.fields['@timestamp']?.[0]).toLocaleString(),
        user: element.fields['extra.user'] ? element.fields['extra.user'][0] : 'N/A',
        device: element.fields['context.device'][0],
        action: element.fields['extra.action'][0],
        mobileNumber: element.fields['extra.mobile_number'][0],
        realm: element.fields['context.realm_id'][0],
        requestId: element.fields['context.request_id'][0],
        countryCode: element.fields['extra.country_code'][0],
        countryName: element.fields['extra.country_name'][0],
        rate: element.fields['extra.rate'][0],
        message: parseHTMLStr(element.fields.message[0]),
    };
};
