import { createSelector } from '@reduxjs/toolkit';
import { loadSuccess as loadRealmQuotaSuccess } from './realmQuotaSlice';
import { loadSuccess as loadRealmsSuccess } from './realmsSlice';
import { RealmsResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface RealmQuotaMapState extends GenericState<Record<string, RealmQuota> | null> {}

const initialState: RealmQuotaMapState = {
    loading: false,
    data: {},
    error: null,
};

const realmQuotaMapSlice = createGenericSlice({
    name: 'realmQuotaMap',
    initialState: initialState as RealmQuotaMapState,
    reducers: {
        loadSuccess: (state, action: PayloadAction<{ data: RealmQuota }>) => {
            state.loading = false;
            state.error = null;
            const realmQuotaMap = { ...state.data };
            realmQuotaMap[action.payload.data.realm_id] = action.payload.data;
            state.data = realmQuotaMap;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadRealmQuotaSuccess, (state, action: PayloadAction<{ data: RealmQuota }>) => {
            const { data: quota } = action.payload;
            if (state.data) {
                const realmQuotaMap = { ...state.data };
                realmQuotaMap[quota.realm_id] = { ...quota };
                state.data = realmQuotaMap;
            }
        });
        builder.addCase(loadRealmsSuccess, (state, action: PayloadAction<{ data: RealmsResponse }>) => {
            const { quota } = action.payload.data;
            if (state.data) {
                const realmQuotaMap = { ...state.data };
                quota.realms.forEach(({ realm_id, realm_quota, users }) => {
                    realmQuotaMap[realm_id] = {
                        realm_id: realm_id,
                        realm_quota: realm_quota,
                        users: users,
                    };
                });
                state.data = realmQuotaMap;
            }
        });
    },
});

export const { loadSuccess, loadFailure, cancelLoad } = realmQuotaMapSlice.actions;

export const selectRealmQuotaMap = createSelector(
    (state: RootState) => state.realmQuotaMap.data,
    (data) => data ?? {}
);

export default realmQuotaMapSlice.reducer;
