import { ADMIN } from '@shared/utils/constants';

export function formatAdminGroup(element: AdminGroup): AdminGroupData {
    const { id, name, description, member_count, is_admin } = element;

    return {
        id: id,
        name: name,
        description: description || '',
        memberCount: member_count,
        level: is_admin === true ? ADMIN.GLOBAL : ADMIN.SUB,
    };
}

export function formatAdmin(element: Admin): AdminData {
    const { id, username, email, subuser_id } = element;

    return {
        id: id,
        username: username,
        email: email,
        subuserId: subuser_id,
    };
}

export function formatAdminNotInGroup(element: AdminNotInGroup): AdminNotInGroupData {
    const { user_id, user_name, user_email } = element;

    return {
        id: user_id,
        username: user_name,
        email: user_email,
    };
}

export function createRealmWithPermissionID(
    element: Realm & { permission_id: string }
): RealmBriefData & { permissionId: string } {
    return {
        name: element.name,
        id: element.id,
        description: element.description,
        isDefault: element.is_default,
        select: false,
        permissionId: element.permission_id ? element.permission_id : '0',
    };
}
