import { createSelector } from '@reduxjs/toolkit';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface RegionState extends GenericState<Region[]> {}

const initialState: RegionState = {
    loading: false,
    data: [],
    error: null,
};

const regionsSlice = createGenericSlice({
    name: 'regions',
    initialState: initialState as RegionState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: Region[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = regionsSlice.actions;

export const selectRegions = createSelector(
    (state: RootState) => state.regions.data,
    (data) => data
);

export default regionsSlice.reducer;
