import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import { loadSuccess as loadApplicationRealmsSuccess } from './applicationRealmsSlice';
import { loadSuccess as loadApplicationsSuccess } from './applicationsSlice';
import { loadSuccess as loadBrandingsSuccess } from './branding/appBrandingsSlice';
import { loadSuccess as loadSigningCertsSuccess } from './signingCert/appSigningCertsSlice';
import { loadSuccess as loadUserSourcesSuccess } from './userSource/appUserSourcesSlice';
import { brandingFormatter } from '../settings/branding/helper';

type ApplicationResource = {
    applications: Application[] | null;
    realms: RealmBrief[] | null;
    brandings: Branding[] | null;
    signingCerts: SigningCert[] | null;
    profiles: Profile[] | null;
    userSources: UserSource[] | null;
    previewSaml: SamlAppPreview | null;
    previewOidcAzure: OidcAzureAppPreview | null;
};

type NonNullableResource = {
    applications: NonNullable<ApplicationResource['applications']>;
    realms: NonNullable<ApplicationResource['realms']>;
    brandings: BrandingData[];
    signingCerts: NonNullable<ApplicationResource['signingCerts']>;
    profiles: NonNullable<ApplicationResource['profiles']>;
    userSources: NonNullable<ApplicationResource['userSources']>;
    preview: {
        saml: NonNullable<ApplicationResource['previewSaml']>;
        oidcAzure: NonNullable<ApplicationResource['previewOidcAzure']>;
    };
};

interface InitialState extends GenericState<ApplicationResource> {}

const initialState: InitialState = {
    loading: false,
    data: {
        applications: null,
        realms: null,
        brandings: null,
        signingCerts: null,
        profiles: null,
        userSources: null,
        previewSaml: null,
        previewOidcAzure: null,
        // preview: null,
    },
    error: null,
};

const applicationResourcesSlice = createGenericSlice({
    name: 'applicationResources',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ isEditMode?: boolean }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: ApplicationResource }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadBrandingsSuccess, (state, action: PayloadAction<{ data: Branding[] }>) => {
            const { data: brandings } = action.payload;
            state.data.brandings = brandings;
        });
        builder.addCase(loadSigningCertsSuccess, (state, action: PayloadAction<{ data: SigningCert[] }>) => {
            const { data: signingCerts } = action.payload;
            state.data.signingCerts = signingCerts;
        });
        builder.addCase(loadApplicationRealmsSuccess, (state, action: PayloadAction<{ data: RealmBrief[] }>) => {
            const { data: realms } = action.payload;
            state.data.realms = realms;
        });
        builder.addCase(loadApplicationsSuccess, (state, action: PayloadAction<{ data: Application[] }>) => {
            const { data: applications } = action.payload;
            state.data.applications = applications;
        });
        builder.addCase(loadUserSourcesSuccess, (state, action: PayloadAction<{ data: UserSource[] }>) => {
            const { data: userSources } = action.payload;
            state.data.userSources = userSources;
        });
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = applicationResourcesSlice.actions;

export const selecAppResource = createSelector(
    (state: RootState) => state.appResources.data,
    (data) => {
        let nonNullData: NonNullableResource = {
            applications: [],
            realms: [],
            brandings: [],
            signingCerts: [],
            profiles: [],
            userSources: [],
            preview: {
                saml: {
                    prefix: '',
                    fqdn: '',
                    entity_id: '',
                    sso_url: '',
                    slo_url: '',
                },
                oidcAzure: {
                    prefix: '',
                    fqdn: '',
                    audience_id: '',
                    redirect_uris: [],
                    discovery_endpoint: '',
                    authorization_endpoint: '',
                },
            },
        };
        Object.entries(data).forEach(([key, value]) => {
            if (value !== null) {
                if (key === 'previewSaml') {
                    nonNullData['preview']['saml'] = value as any;
                } else if (key === 'previewOidcAzure') {
                    nonNullData['preview']['oidcAzure'] = value as any;
                } else {
                    nonNullData[key as keyof Omit<ApplicationResource, 'previewSaml' | 'previewOidcAzure'>] =
                        value as any;
                }
            }
        });
        nonNullData['brandings'] = (data['brandings'] ?? []).map(brandingFormatter);
        return nonNullData;
    }
);

export const selectAppRealmOptions = createSelector(
    (state: RootState) => state.appResources.data.realms?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export const selectAppProfileOptions = createSelector(
    (state: RootState) => state.appResources.data.profiles?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export const selectAppUserSourceOptions = createSelector(
    (state: RootState) => state.appResources.data.userSources?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export default applicationResourcesSlice.reducer;
