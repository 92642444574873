import { type PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';

interface InitialState extends GenericState<RealmBrief[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const applicationRealmsSlice = createGenericSlice({
    name: 'applicationRealms',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: RealmBrief[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = applicationRealmsSlice.actions;

export const selectApplicationRealms = createSelector(
    (state: RootState) => state.applicationRealms.data,
    (data) => data ?? []
);

export const selectApplicationRealmOptions = createSelector(
    (state: RootState) => state.applicationRealms.data?.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export default applicationRealmsSlice.reducer;
