import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest, select } from 'redux-saga/effects';
import {
    getApplication,
    getApplications,
    deleteApplication,
    postApplication,
    putApplication,
    getApplicationPreview,
    putAppUserSourceMapping,
} from '@services/api/application';
import { getBrandings } from '@services/api/branding';
import { getSigningCerts } from '@services/api/cert';
import { getProfiles } from '@services/api/profiles';
import { getRealmsBrief } from '@services/api/realms';
import { getUserSources } from '@services/api/userSource';
import { OIDC_AZURE_CODE } from '@shared/utils/idpProxy';
import { RootState } from '@store/index';
import {
    loadRequest as loadApplicationRealms,
    loadSuccess as loadApplicationRealmsSuccess,
    loadFailure as loadApplicationRealmsFailure,
    cancelLoad as cancelLoadApplicationRealms,
} from './applicationRealmsSlice';
import {
    loadRequest as loadApplicationResources,
    loadSuccess as loadApplicationResourcesSuccess,
    loadFailure as loadApplicationResourcesFailure,
    cancelLoad as cancelLoadApplicationResources,
} from './applicationResourcesSlice';
import {
    loadRequest as loadApplication,
    loadSuccess as loadApplicationSuccess,
    loadFailure as loadApplicationFailure,
    cancelLoad as cancelLoadApplication,
} from './applicationSlice';
import {
    loadRequest as loadApplications,
    loadSuccess as loadApplicationsSuccess,
    loadFailure as loadApplicationsFailure,
    cancelLoad as cancelLoadApplications,
} from './applicationsSlice';
import {
    deleteRequest as deleteApplications,
    deleteSuccess as deleteApplicationsSuccess,
    deleteFailure as deleteApplicationsFailure,
    cancelDelete as cancelDeleteApplications,
} from './deleteApplicationsSlice';

import {
    upsertRequest as upsertApplicationRequest,
    upsertSuccess as upsertApplicationSuccess,
    upsertFailure as upsertApplicationFailure,
    cancelUpsert as cancelUpsertApplication,
} from './upsertApplicationSlice';

export function* fetchApplications(action: ReturnType<typeof loadApplications>) {
    try {
        const { response, cancel }: { response: Application[]; cancel: any } = yield race({
            response: call(getApplications),
            cancel: take(cancelLoadApplications),
        });

        if (cancel) {
            return;
        }

        yield put(loadApplicationsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadApplicationsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchApplication(action: ReturnType<typeof loadApplication>) {
    const id = action.payload.params.id;
    try {
        const { response, cancel }: { response: ApplicationDetail; cancel: any } = yield race({
            response: call(getApplication, { id }),
            // response: applications.find((app) => app.id === action.payload.params.id),
            cancel: take(cancelLoadApplication),
        });

        if (cancel) {
            return;
        }

        yield put(loadApplicationSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadApplicationFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchApplicationRealms(action: ReturnType<typeof loadApplicationRealms>) {
    try {
        const { response, cancel }: { response: RealmBrief[]; cancel: any } = yield race({
            response: call(getRealmsBrief),
            cancel: take(cancelLoadApplicationRealms),
        });

        if (cancel) {
            return;
        }

        yield put(loadApplicationRealmsSuccess({ data: response }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadApplicationRealmsFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* upsertApplication(action: ReturnType<typeof upsertApplicationRequest>) {
    const { id, data: dataWithCallback } = action.payload;
    const { user_source_ids, default_usersource_id, ...data } = dataWithCallback;

    // Only edit mode has id
    const upsertCall = id ? call(putApplication, { id, data }) : call(postApplication, { data });

    try {
        const { response, cancel }: { response: Application; cancel: any } = yield race({
            response: upsertCall,
            cancel: take(cancelUpsertApplication),
        });

        if (cancel) {
            return;
        }

        if (user_source_ids || default_usersource_id !== undefined) {
            try {
                const appId = response.id;
                yield race({
                    response: call(putAppUserSourceMapping, {
                        appId,
                        data: { user_source_ids, default_usersource_id },
                    }),
                });
                toast.success('Mapping of Application to User Source successful');
            } catch (e) {
                const errMsg =
                    typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
                toast.error(errMsg);
            }
        }
        yield put(upsertApplicationSuccess({ data: response }));
        toast.success(`${id ? 'Update' : 'Create'} Application Succeeded`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(upsertApplicationFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* deleteApplicationsRequest(action: ReturnType<typeof deleteApplications>) {
    const { ids } = action.payload;

    const deleteApplicationCalls = ids.map((id) => call(deleteApplication, { id: id }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteApplicationCalls),
            cancel: take(cancelDeleteApplications),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data for determin to close the modal after success
        yield put(deleteApplicationsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadApplications());
        toast.success(`${ids.length} Application Deleted`);
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(deleteApplicationsFailure(errMsg));
        yield put(loadApplications());
        toast.error(errMsg);
    }
}

export function* fetchApplicationResources(action: ReturnType<typeof loadApplicationResources>) {
    const isEditMode = action.payload.isEditMode;
    const commonCalls = {
        brandings: call(getBrandings),
        signingCerts: call(getSigningCerts),
        profiles: call(getProfiles),
        userSources: call(getUserSources),
    };

    const preview = {
        previewSaml: call(getApplicationPreview),
        previewOidcAzure: call(getApplicationPreview, OIDC_AZURE_CODE),
    };

    const appResourceCalls = isEditMode ? commonCalls : { ...commonCalls, ...preview };
    try {
        const { response, cancel }: { response: ApplicationResource; cancel: any } = yield race({
            response: all(appResourceCalls),
            cancel: take(cancelLoadApplicationResources),
        });

        if (cancel) {
            return;
        }

        let realms: RealmBrief[] = [];
        try {
            realms = yield select((state: RootState) => state.appResources.data.realms);
            if (!realms) {
                const { response }: { response: RealmBrief[] } = yield race({
                    response: call(getRealmsBrief),
                });
                realms = response;
            }
        } catch (e) {}

        let applications: Application[] = [];
        try {
            applications = yield select((state: RootState) => state.appResources.data.applications);
            if (!applications) {
                const { response }: { response: Application[] } = yield race({ response: call(getApplications) });
                applications = response;
            }
        } catch (e) {}
        yield put(loadApplicationResourcesSuccess({ data: { ...response, realms, applications } }));
    } catch (e) {
        const errMsg = typeof e?.response?.data === 'object' ? e?.response?.data?.error_message : e?.response?.data;
        yield put(loadApplicationResourcesFailure(errMsg));
        toast.error(errMsg);
    }
}

export function* fetchApplicationSaga() {
    yield takeLatest(loadApplication, fetchApplication);
}

export function* fetchApplicationsSaga() {
    yield takeLatest(loadApplications, fetchApplications);
}

export function* upsertApplicationSaga() {
    yield takeLatest(upsertApplicationRequest, upsertApplication);
}

export function* deleteApplicationsSaga() {
    yield takeLatest(deleteApplications, deleteApplicationsRequest);
}

export function* fetchApplicationRealmsSaga() {
    yield takeLatest(loadApplicationRealms, fetchApplicationRealms);
}

export function* fetchApplicationResourcesSaga() {
    yield takeLatest(loadApplicationResources, fetchApplicationResources);
}
