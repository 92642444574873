import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getRegions } from '@services/api/regions';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadRegions,
    cancelLoad as cancelLoadRegions,
    loadFailure as loadRegionsFailure,
    loadSuccess as loadRegionsSuccess,
} from '@store/modules/regions/regionsSlice';

export function* fetchRegions() {
    try {
        const { response, cancel }: { response: Region[]; cancel: any } = yield race({
            response: call(getRegions),
            cancel: take(cancelLoadRegions),
        });

        if (cancel) {
            return;
        }

        yield put(loadRegionsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRegionsFailure(e.message));
    }
}

export function* fetchRegionsSaga() {
    yield takeLatest(loadRegions, fetchRegions);
}
