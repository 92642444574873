import { call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getAnnouncement } from '@services/api/announcement';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadAdminAlert,
    loadFailure as loadAdminAlertFailure,
    loadSuccess as loadAdminAlertSuccess,
    cancelLoad as cancelLoadAdminAlert,
} from './adminAlertSlice';

export function* fetchAdminAlert(action: ReturnType<typeof loadAdminAlert>) {
    try {
        const { response, cancel }: { response: AdminAlert; cancel: any } = yield race({
            response: call(getAnnouncement, { params: { alert: true } }),
            cancel: take(cancelLoadAdminAlert),
        });

        if (cancel) {
            return;
        }

        yield put(loadAdminAlertSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAdminAlertFailure(e.message));
    }
}

export function* fetchAdminAlertSaga() {
    yield takeLatest(loadAdminAlert, fetchAdminAlert);
}
