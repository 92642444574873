import { createSelector } from '@reduxjs/toolkit';
import { ACS_ALLOW, IDP_PROXY_TTL } from '@shared/utils/constants';
import { SAML_CODE } from '@shared/utils/idpProxy';
import { GenericState, createGenericSlice } from '@store/modules/genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

type ApplicationParams = { id: string };

interface InitialState extends GenericState<ApplicationDetail | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const applicationSlice = createGenericSlice({
    name: 'application',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: ApplicationParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: ApplicationDetail }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = applicationSlice.actions;

export const selectApplication = createSelector(
    (state: RootState) => state.application.data,
    (data) =>
        data ?? {
            id: '',
            prefix: '',
            fqdn: '',
            branding_id: null,
            profile_id: null,
            ttl: IDP_PROXY_TTL,
            attr_mapping: {},
            sp_entity_id: '',
            sp_acs_url: '',
            sp_slo_url: null,
            sp_nameid: null,
            signing_alg: 'rsa-sha256',
            entity_id: '',
            sso_url: '',
            slo_url: '',
            signing_cert_id: null,
            signing_cert_name: '',
            user_sources: [],
            name: '',
            realm_id: '',
            type: SAML_CODE,
            sp_signing_cert: null,
            access: ACS_ALLOW,
            reverse_proxy_url: '',
            default_usersource_id: '',
            login_url: '',
            logo_url: '',
            audience_id: '',
            redirect_uris: [],
            discovery_endpoint: '',
            authorization_endpoint: '',
        }
);

export default applicationSlice.reducer;
