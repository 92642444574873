import { toast } from 'react-toastify';
import { all, call, put, race, take, takeLatest } from 'redux-saga/effects';
import { getTokens, importToken, ImportTokenResponse, deleteTonken } from '@services/api/tokens';
import { pluralize } from '@shared/utils/pluralize';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    loadRequest as loadAuthDevice,
    loadFailure as loadAuthDeviceFailure,
    loadSuccess as loadAuthDeviceSuccess,
    cancelLoad as cancelloadAuthDevice,
} from './authDeviceSlice';
import {
    deleteRequest as deleteTokens,
    deleteSuccess as deleteTokensSuccess,
    deleteFailure as deleteTokensFailure,
    cancelDelete as cancelDeleteTokens,
} from './deleteTokensSlice';
import {
    upsertRequest as loadImportTokens,
    upsertSuccess as loadImportTokensSuccess,
    upsertFailure as loadImportTokensFailure,
    cancelUpsert as cancelLoadTokens,
} from './importTokensSlice';

export function* fetchAuthDevice(action: ReturnType<typeof loadAuthDevice>) {
    try {
        const { response, cancel }: { response: Token[]; cancel: any } = yield race({
            response: call(getTokens, { params: action.payload?.params }),
            cancel: take(cancelloadAuthDevice),
        });

        if (cancel) {
            return;
        }

        yield put(loadAuthDeviceSuccess({ data: response, type: action.payload?.type }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadAuthDeviceFailure(e.message));
    }
}

export function* importTokens(action: ReturnType<typeof loadImportTokens>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: ImportTokenResponse; cancel: any } = yield race({
            response: call(importToken, data),
            cancel: take(cancelLoadTokens),
        });

        if (cancel) {
            return;
        }
        yield put(loadImportTokensSuccess({ data: 'success' }));
        // fetch hardtoken list api after successfully
        const { tokens, error } = response;
        if (!error) {
            toast.success(`Successfully added ${tokens.length} ${pluralize('token', tokens.length)}`);
        } else {
            toast.warn(`Successfully added ${tokens.length} ${pluralize('token', tokens.length)}; ${error}`);
        }
        yield put(loadAuthDevice({ type: 'hardToken' }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadImportTokensFailure('failure'));
    }
}

export function* deleteTokensRequest(action: ReturnType<typeof deleteTokens>) {
    const { sns } = action.payload;
    const deleteCalls = sns.map((sn) => call(deleteTonken, { sn }));

    try {
        const { cancel }: { cancel: any } = yield race({
            response: all(deleteCalls),
            cancel: take(cancelDeleteTokens),
        });

        if (cancel) {
            return;
        }

        // api will not gvie response after deleted successfully
        // give successful data to determin to close the modal after success
        yield put(deleteTokensSuccess({ data: 'success' }));
        toast.success('Token Deleted');

        //  load initial data
        yield put(loadAuthDevice({ type: 'hardToken' }));
    } catch (e) {
        showErrorAlert(e);
        yield put(deleteTokensFailure(e?.response?.data));
    }
}

export function* fetchAuthDeviceSaga() {
    yield takeLatest(loadAuthDevice, fetchAuthDevice);
}

export function* importTokensSaga() {
    yield takeLatest(loadImportTokens, importTokens);
}

export function* deleteTokensSaga() {
    yield takeLatest(deleteTokens, deleteTokensRequest);
}
