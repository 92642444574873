import axiosClient from '../axiosClient';
import { API } from '../index';

export type QuotaParams = {
    realm_id?: string;
    brief?: boolean;
};

export function* getQuota(params: QuotaParams): Generator {
    return yield axiosClient.get<QuotaBreif>(API.QUOTA, { params }).then((res) => res.data);
}

export function* putQuota({ data }: { data?: UpdateRealmData['quota'] }): Generator {
    return yield axiosClient.put<QuotaBreif>(API.QUOTA, data).then((res) => res.data);
}
