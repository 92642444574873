import { useDebounce } from '@uidotdev/usehooks';
import { ChangeEvent, useEffect, useState, PropsWithChildren } from 'react';
import { SearchInput } from '@shared/components/FtcSearchBar';
import { Icon } from '@shared/components/Icon';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import {
    loadRequest as loadAccounts,
    cancelLoad as cancelLoadAccounts,
    selectAccountList,
    selectOrgList,
} from '@store/modules/accounts/accountsSlice';
import './AccountSelection.scss';
import { selectAccountSelected, selectMenuItemStatus } from '@store/modules/auth/authSlice';
import { loadLogout } from '@store/modules/auth/logoutSlice';
import { selectNavbar } from '@store/modules/layout/navbarSlice';
import { loadRequest as loadRegions, cancelLoad as cancelLoadRegions } from '@store/modules/regions/regionsSlice';
import { AccountView } from './AccountView';
import CommonSection from './CommonSection';
import { OrganizationView } from './OrganizationView';

function ContentHeaderSection({
    children,
    user,
    filter,
    setFilter,
    userType = 'account',
}: PropsWithChildren<{
    user: any;
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    userType?: 'account' | 'org';
}>) {
    return (
        <div className="content-header container-fluid container-fix">
            <section className="welcome">
                <h1>Welcome to Your Dashboard</h1>
                {user.account_id && (
                    <div>
                        Username:{' '}
                        <span className="fw-bold">{user.iam_user_name || user.fortinetid || user.user_name}</span>
                    </div>
                )}
            </section>
            <div className="choose-account mb-5">
                <div
                    className={`row ${
                        userType === 'org' ? 'justify-content-center align-items-center' : 'justify-content-between'
                    }`}
                >
                    <div className="col align-self-end">
                        <SearchInput
                            type="search"
                            placeholder="Search"
                            onFilter={(e) => setFilter((e as ChangeEvent<HTMLInputElement>).target.value)}
                            filterValue={filter}
                        />
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
}

function AccountSelectionView({ onClose }: { onClose?: Function }) {
    const dispatch = useAppDispatch();
    const accountList = useAppSelector(selectAccountList);
    const organizationList = useAppSelector(selectOrgList);
    const authState = useAppSelector((state: RootState) => state.auth);
    const navbar = useAppSelector(selectNavbar);
    const fortiCloudItemStatusMap = useAppSelector(selectMenuItemStatus);
    const isAccountSelected = useAppSelector(selectAccountSelected);
    const [selectionView, setSelectionView] = useState<'account' | 'org'>('account');

    // search input
    const [filter, setFilter] = useState<string>('');

    useEffect(() => {
        if (organizationList.length) {
            setSelectionView('org');
        } else if (accountList.length) {
            setSelectionView('account');
        }
    }, [accountList, organizationList]);

    useEffect(() => {
        dispatch(loadAccounts());
        dispatch(loadRegions());
        return () => {
            dispatch(cancelLoadAccounts());
            dispatch(cancelLoadRegions());
        };
    }, []);

    const debouncedFilterTerm = useDebounce(filter, 300);
    if (selectionView === 'account') {
        return (
            <CommonSection
                user={authState.user}
                navbar={navbar}
                fortiCloudItemStatusMap={fortiCloudItemStatusMap}
                logout={() => {
                    dispatch(loadLogout());
                }}
            >
                <ContentHeaderSection user={authState.user} filter={filter} setFilter={setFilter}>
                    <AccountView
                        filter={debouncedFilterTerm}
                        loadAccounts={() => {
                            dispatch(loadAccounts());
                        }}
                    />
                </ContentHeaderSection>
            </CommonSection>
        );
    } else {
        // organization view
        if (isAccountSelected) {
            return (
                <div id="account-selection-dropdown">
                    <header className="d-flex justify-content-between align-items-center">
                        <div>Please Select an Account to Switch</div>
                        <div id="search-input">
                            <SearchInput
                                type="search"
                                placeholder="Search"
                                onFilter={(e) => setFilter((e as ChangeEvent<HTMLInputElement>).target.value)}
                                filterValue={filter}
                            />
                        </div>
                        <button
                            className="close"
                            type="button"
                            onClick={() => {
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            <Icon classNames={['material-symbols-outlined']} content="close" />
                        </button>
                    </header>
                    <OrganizationView filter={debouncedFilterTerm} />
                </div>
            );
        } else {
            return (
                <CommonSection
                    user={authState.user}
                    navbar={navbar}
                    fortiCloudItemStatusMap={fortiCloudItemStatusMap}
                    logout={() => {
                        dispatch(loadLogout());
                    }}
                >
                    <ContentHeaderSection user={authState.user} filter={filter} setFilter={setFilter} userType="org">
                        <OrganizationView filter={debouncedFilterTerm} />
                    </ContentHeaderSection>
                </CommonSection>
            );
        }
    }
}

export { AccountSelectionView };
