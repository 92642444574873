import { createSelector } from '@reduxjs/toolkit';
import { UsageParams } from '@services/api/usage';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

export type UsageRealmData = {
    id: string;
    name: string;
    client_count: number;
    application_count: number;
};

interface UsageRealmsState extends GenericState<UsageRealmData[] | null> {}

const initialState: UsageRealmsState = {
    loading: false,
    data: null,
    error: null,
};

const usageRealmsSlice = createGenericSlice({
    name: 'usageRealms',
    initialState: initialState as UsageRealmsState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ params: UsageParams }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UsageRealmData[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = usageRealmsSlice.actions;

export const selectAllRealms = createSelector(
    (state: RootState) => state.usageRealms.data,
    (data) => {
        if (!data) {
            return [];
        }

        const extendedRealm = [...data];
        extendedRealm.unshift({ id: '', name: 'ALL', client_count: 0, application_count: 0 });
        return extendedRealm;
    }
);

export default usageRealmsSlice.reducer;
