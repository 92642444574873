export const convertArrayToMap = <T extends { [key: string | number]: any }, U extends keyof T>(
    arrays: T[],
    key: U
) => {
    return arrays.reduce(
        (acc, cur) => {
            acc[cur[key]] = cur;
            return acc;
        },
        {} as Record<T[U], T>
    );
};

export const convertArrayToMapWithSecondKey = <
    T extends { [key: string | number]: any },
    U extends keyof T,
    V extends keyof T,
>(
    arrays: T[],
    key: U,
    secondKey: V
) => {
    return arrays.reduce(
        (acc, cur) => {
            acc[cur[key]] = cur[secondKey];
            return acc;
        },
        {} as Record<T[U], T[V]>
    );
};
