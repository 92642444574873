import { GenericState, creatUpdateGenericSlice } from '@store/modules/updateGenericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends GenericState<ApplicationUserSourceMapping[] | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const updateUserPortalUserSourceMappingSlice = creatUpdateGenericSlice({
    name: 'updateUserPortalUserSourceMappings',
    initialState: initialState as InitialState,
    reducers: {
        updateRequest: (
            state,
            action: PayloadAction<{
                appId: string;
                data: UpdateAppUserSourceMappingData;
            }>
        ) => {
            state.loading = true;
        },
        updateSuccess: (state, action: PayloadAction<{ data: ApplicationUserSourceMapping[] }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { updateRequest, updateSuccess, updateFailure, cancelUpdate } =
    updateUserPortalUserSourceMappingSlice.actions;

export default updateUserPortalUserSourceMappingSlice.reducer;
