import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegionModal from '@pages/AccountSelection/RegionSelectionModal';
import { FixedLoading, RelativeLoading } from '@shared/components/Loading';
import { useModal } from '@shared/hooks/useModal';
import { FTC_NO_LICENSE } from '@shared/utils/constants';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { RootState } from '@store/index';
import { selectOrgList, selectAccessFrom } from '@store/modules/accounts/accountsSlice';
import {
    loadRequest as loadAccountsStatus,
    selectAccountsStatusMap,
} from '@store/modules/accounts/accountsStatusSlice';
import { selectAccountSelected } from '@store/modules/auth/authSlice';
import { loadLogin } from '@store/modules/auth/loginSlice';
import { setJsonAccount, SSOAccount } from '@store/modules/auth/ssoAccountSlice';
import { selectRegions } from '@store/modules/regions/regionsSlice';
import { flattenTree, buildTree, phraseOrgAccount } from './helper';
import TreeView from './TreeView';
import type { TreeNode, TreeItem, NodeContents } from './types';
import '../AccountSelection.scss';

const nodeContent: NodeContents = [
    { key: 'name', className: 'node-content' },
    { key: 'description', className: 'node-content' },
];

const matcher = (filterText: string, node: TreeItem<TreeNode>) => {
    return (
        node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        node.description.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );
};

const OrganizationView = (props: { filter: string }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const accountsState = useAppSelector((state: RootState) => state.accounts);
    const isAccountSelected = useAppSelector(selectAccountSelected);
    const authState = useAppSelector((state: RootState) => state.auth);
    const [showLoadingMask, setShowLoadingMask] = useState(false);
    const organizationList = useAppSelector(selectOrgList);
    const [organizationTree, setOrganizationTree] = useState<TreeItem<TreeNode> | null>(null);
    const accountsStatusMap = useAppSelector(selectAccountsStatusMap);
    const regionList = useAppSelector(selectRegions);
    const { isOpen: isRegionModalOpen, toggle: toggleRegionModal, close: closeRegionModal } = useModal();

    // search input
    const { filter } = props;
    const accessFrom = useAppSelector(selectAccessFrom);

    useEffect(() => {
        // @ts-ignore
        dispatch(loadAccountsStatus({ accounts: organizationList[0]['member_accounts'] }));
    }, [organizationList]);

    useEffect(() => {
        if (authState.user?.to_select && organizationList != null && organizationList[0]['member_accounts'] != null) {
            // skip the auto login for first time trial user and navigate to introduction
            const selectedAccount = organizationList[0]['member_accounts'].find(
                (account) => account.account_node_id.toString() === authState.user?.to_select.node_id.toString()
            );
            if (selectedAccount && accountsStatusMap[selectedAccount.account_id] != null) {
                if (
                    accountsStatusMap[selectedAccount.account_id]['ftc_status'] !== FTC_NO_LICENSE ||
                    // when login from another FTC region, no need for intro
                    authState.user?.to_select.source_app === 'FTC'
                ) {
                    dispatch(setJsonAccount({ data: authState.user?.to_select }));
                    const loginbody: { h_key: string; trial?: boolean; region_domain?: string | null } = {
                        h_key: JSON.stringify(authState.user?.to_select),
                    };
                    if (
                        accountsStatusMap[selectedAccount.account_id] != null &&
                        accountsStatusMap[selectedAccount.account_id]?.region_domain != null
                    ) {
                        loginbody['region_domain'] = accountsStatusMap[selectedAccount.account_id].region_domain;
                    }
                    if (
                        regionList.length <= 1 ||
                        !!loginbody?.region_domain ||
                        authState.user?.to_select.source_app === 'FTC'
                    ) {
                        setShowLoadingMask(true);
                        dispatch(loadLogin(loginbody));
                    } else {
                        toggleRegionModal();
                    }
                } else {
                    navigate(`./${selectedAccount.account_id}/introduction`);
                }
            }
        }
    }, [accountsStatusMap]);

    const onclick = (node: TreeItem<TreeNode>) => {
        if (!node.children) {
            if (accessFrom) {
                const loginbody = phraseOrgAccount(node.id, accessFrom, (data: SSOAccount) =>
                    dispatch(setJsonAccount({ data: data }))
                );
                if (node.accountId && accountsStatusMap[node.accountId] != null) {
                    if (accountsStatusMap[node.accountId]['ftc_status'] === FTC_NO_LICENSE) {
                        let path = `/${node.accountId}/introduction`;
                        if (isAccountSelected) {
                            path = './accountselection' + path;
                        } else {
                            path = '.' + path;
                        }
                        navigate(path);
                    } else {
                        if (accountsStatusMap[node.accountId].region_domain !== null) {
                            loginbody['region_domain'] = accountsStatusMap[node.accountId].region_domain;
                        }
                        if (regionList.length < 1 || !!loginbody['region_domain']) {
                            setShowLoadingMask(true);
                            dispatch(
                                loadLogin(
                                    phraseOrgAccount(node.id, accessFrom, (data: SSOAccount) =>
                                        dispatch(setJsonAccount({ data: data }))
                                    )
                                )
                            );
                        } else {
                            toggleRegionModal();
                        }
                    }
                }
            }
        }
    };

    // initial filterAccountList value
    useEffect(() => {
        if (organizationList.length) {
            const flatTree = flattenTree(organizationList);
            const tree = buildTree(flatTree, 'id', 'parentId');
            setOrganizationTree(tree);
        }
    }, [organizationList]);

    return accountsState.loading ? (
        <RelativeLoading />
    ) : (
        organizationTree && (
            <>
                {showLoadingMask && <FixedLoading />}
                <TreeView
                    filter={filter}
                    data={organizationTree}
                    onNodeToggle={onclick}
                    matcher={matcher}
                    nodeContent={nodeContent}
                ></TreeView>
                <RegionModal
                    isOpen={isRegionModalOpen}
                    toggle={toggleRegionModal}
                    onClosed={closeRegionModal}
                    onCanceled={closeRegionModal}
                    meta={{
                        regions: regionList,
                    }}
                />
            </>
        )
    );
};

export { OrganizationView };
