import accountsSlice from './modules/accounts/accountsSlice';
import accountsStatusSlice from './modules/accounts/accountsStatusSlice';
import adminAlertSlice from './modules/adminAlert/adminAlertSlice';
import adminGroupsPermissionsSlice from './modules/admins/adminGroupsPermissionsSlice';
import adminGroupsSlice from './modules/admins/adminGroupsSlice';
import adminsByGroupSlice from './modules/admins/adminsByGroupSlice';
import adminsSlice from './modules/admins/adminsSlice';
import createAdminGroupSlice from './modules/admins/createAdminGroupSlice';
import deleteAdminGroupSlice from './modules/admins/deleteAdminByGroupSlice';
import deleteAdminGroupsSlice from './modules/admins/deleteAdminGroupsSlice';
import realmsByAdminGroupSlice from './modules/admins/realmsByAdminGroupSlice';
import updateAdminGroupSlice from './modules/admins/updateAdminGroupSlice';
import updateAdminsByGroupSlice from './modules/admins/updateAdminsByGroupSlice';
import updateRealmsByAdminGroupSlice from './modules/admins/updateRealmsByAdminGroupSlice';
import createEventSlice from './modules/alarm/events/createEventSlice';
import deleteEventsSlice from './modules/alarm/events/deleteEventsSlice';
import eventReceiversSlice from './modules/alarm/events/eventReceiversSlice';
import eventsSlice from './modules/alarm/events/eventsSlice';
import groupsByEventSlice from './modules/alarm/events/groupsByEventSlice';
import updateEventSlice from './modules/alarm/events/updateEventSlice';
import createGroupSlice from './modules/alarm/notification/groups/createGroupSlice';
import deleteGroupsSlice from './modules/alarm/notification/groups/deleteGroupsSlice';
import groupsSlice from './modules/alarm/notification/groups/groupsSlice';
import receiversGroupSlice from './modules/alarm/notification/groups/receiversGroupSlice';
import updateGroupSlice from './modules/alarm/notification/groups/updateGroupSlice';
import deleteReceiversSlice from './modules/alarm/notification/receivers/deleteReceiversSlice';
import receiverSlice from './modules/alarm/notification/receivers/receiverSlice';
import receiversSlice from './modules/alarm/notification/receivers/receiversSlice';
import upsertReceiverSlice from './modules/alarm/notification/receivers/upsertReceiverSlice';
import alertsSlice from './modules/alerts/alertsSlice';
import announcementSlice from './modules/announcement/announcementSlice';
import applicationRealmsSlice from './modules/applications/applicationRealmsSlice';
import applicationResourcesSlice from './modules/applications/applicationResourcesSlice';
import applicationSlice from './modules/applications/applicationSlice';
import applicationsSlice from './modules/applications/applicationsSlice';
import appBrandingsSlice from './modules/applications/branding/appBrandingsSlice';
import createAppBrandingSlice from './modules/applications/branding/createAppBrandingSlice';
import deleteApplicationsSlice from './modules/applications/deleteApplicationsSlice';
import appSigningCertsSlice from './modules/applications/signingCert/appSigningCertsSlice';
import createAppSigningCertSlice from './modules/applications/signingCert/createAppSigningCertSlice';
import upsertApplicationSlice from './modules/applications/upsertApplicationSlice';
import createUserPortalBrandingSlice from './modules/applications/userPortals/branding/createUserPortalBrandingSlice';
import userPortalBrandingsSlice from './modules/applications/userPortals/branding/userPortalBrandingsSlice';
import deleteUserPortalsSlice from './modules/applications/userPortals/deleteUserPortalsSlice';
import upsertUserPortalSlice from './modules/applications/userPortals/upsertUserPortalSlice';
import userPortalRealmsSlice from './modules/applications/userPortals/userPortalRealmsSlice';
import userPortalResourcesSlice from './modules/applications/userPortals/userPortalResourcesSlice';
import userPortalSlice from './modules/applications/userPortals/userPortalSlice';
import userPortalsSlice from './modules/applications/userPortals/userPortalsSlice';
import createUserPortalUserSourceSlice from './modules/applications/userPortals/userSource/createUserPortalUserSourceSlice';
import userPortalUserSourcesSlice from './modules/applications/userPortals/userSource/userPortalUserSourcesSlice';
import appUserSourcesSlice from './modules/applications/userSource/appUserSourcesSlice';
import createAppUserSourceSlice from './modules/applications/userSource/createAppUserSourceSlice';
import authReducer from './modules/auth/authSlice';
import ssoAccountSlice from './modules/auth/ssoAccountSlice';
import deleteFortiProductClientsSlice from './modules/authClients/fortiProducts/deleteFortiProductClientsSlice';
import fortiProductClientsSlice from './modules/authClients/fortiProducts/fortiProductClientsSlice';
import fortiProductClientUsersSlice from './modules/authClients/fortiProducts/fortiProductClientUsersSlice';
import updateFortiProductClientSlice from './modules/authClients/fortiProducts/updateFortiProductClientSlice';
import deleteMgmtAppClientsSlice from './modules/authClients/mgmtApps/deleteMgmtAppClientsSlice';
import mgmtAppClientSlice from './modules/authClients/mgmtApps/mgmtAppClientSlice';
import mgmtAppClientsSlice from './modules/authClients/mgmtApps/mgmtAppClientsSlice';
import updateSecretMgmtAppClientSlice from './modules/authClients/mgmtApps/updateSecretMgmtAppClientSlice';
import upsertMgmtAppClientSlice from './modules/authClients/mgmtApps/upsertMgmtAppClientSlice';
import searchFortiProductClientsSlice from './modules/authClients/searchFortiProductClientsSlice';
import deleteWebAppClientsSlice from './modules/authClients/webApps/deleteWebAppClientsSlice';
import updateSecretWebAppClientSlice from './modules/authClients/webApps/updateSecretWebAppClientSlice';
import upsertWebAppClientSlice from './modules/authClients/webApps/upsertWebAppClientSlice';
import webAppClientsSlice from './modules/authClients/webApps/webAppClientsSlice';
import webAppUsersSlice from './modules/authClients/webApps/webAppUsersSlice';
import authDeviceSlice from './modules/authDevices/authDeviceSlice';
import deleteTokenSlice from './modules/authDevices/deleteTokensSlice';
import importTokensSlice from './modules/authDevices/importTokensSlice';
import deleteWebauthnCredentialSlice from './modules/authDevices/webauthn/deleteWebauthnCredentialsSlice';
import webauthnCredentialSlice from './modules/authDevices/webauthn/webauthnCredentialsSlice';
import deleteDomainMappingsSlice from './modules/authentication/domainMapping/deleteDomainMappingsSlice';
import domainMappingResourcesSlice from './modules/authentication/domainMapping/domainMappingResourcesSlice';
import domainMappingsSlice from './modules/authentication/domainMapping/domainMappingsSlice';
import upsertDomainMappingSlice from './modules/authentication/domainMapping/upsertDomainMappingSlice';
import createUserSourceDomainMappingSlice from './modules/authentication/userSource/createUserSourceDomainMappingSlice';
import deleteuserSourcesSlice from './modules/authentication/userSource/deleteUserSourcesSlice';
import upsertuserSourceSlice from './modules/authentication/userSource/upsertUserSourceSlice';
import userSourceRealmsSlice from './modules/authentication/userSource/userSourceRealmsSlice';
import userSourceResourcesSlice from './modules/authentication/userSource/userSourceResourcesSlice';
import userSourceSlice from './modules/authentication/userSource/userSourceSlice';
import userSourcesSlice from './modules/authentication/userSource/userSourcesSlice';
import devicesSlice from './modules/devices/clusters/devicesSlice';
import moveDevicesSlice from './modules/devices/clusters/moveDevicesSlice';
import deleteDevicesSlice from './modules/devices/ownership/devicesRemote/deleteDevicesSlice';
import devicesInClusterStatusSlice from './modules/devices/ownership/devicesRemote/devicesInClusterStatusSlice';
import devicesRemoteSlice from './modules/devices/ownership/devicesRemote/devicesRemoteSlice';
import devicesStatusSlice from './modules/devices/ownership/devicesRemote/deviceStatusSlice';
import approveTaskSlice from './modules/devices/ownership/tasks/approveTaskSlice';
import createTaskSlice from './modules/devices/ownership/tasks/createTaskSlice';
import deleteTaskSlice from './modules/devices/ownership/tasks/deleteTaskSlice';
import deviceTasksSlice from './modules/devices/ownership/tasks/tasksSlice';
import navbarReducer from './modules/layout/navbarSlice';
import licensesSlice from './modules/licenses/licensesSlice';
import logDateSlice from './modules/logs/logDateSlice';
import logsSlice from './modules/logs/logsSlice';
import metadataSlice from './modules/metadata/metadataSlice';
import deletePoliciesSlice from './modules/policies/deletePoliciesSlice';
import policiesSlice from './modules/policies/policiesSlice';
import policySlice from './modules/policies/policySlice';
import upsertPolicySlice from './modules/policies/upsertPolicySlice';
import deleteProfilesSlice from './modules/profiles/deleteProfilesSlice';
import profileSlice from './modules/profiles/profileSlice';
import profilesSlice from './modules/profiles/profilesSlice';
import upsertProfileSlice from './modules/profiles/upsertProfileSlice';
import quotaSlice from './modules/quota/quotaSlice';
import createRealmSlice from './modules/realms/createRealmSlice';
import deleteRealmPermissionSlice from './modules/realms/deleteRealmPermissionSlice';
import deleteRealmsSlice from './modules/realms/deleteRealmsSlice';
import realmApplicationsSlice from './modules/realms/realmApplicationsSlice';
import realmClientsSlice from './modules/realms/realmClientsSlice';
import realmPermissionsSlice from './modules/realms/realmPermissionsSlice';
import realmQuotaMapSlice from './modules/realms/realmQuotaMapSlice';
import realmQuotaSlice from './modules/realms/realmQuotaSlice';
import realmsBriefSlice from './modules/realms/realmsBriefSlice';
import realmsSlice from './modules/realms/realmsSlice';
import updateRealmSlice from './modules/realms/updateRealmSlice';
import regionsSlice from './modules/regions/regionsSlice';
import balanceSlice from './modules/resources/balanceSlice';
import refreshBalanceSlice from './modules/resources/refreshBalanceSlice';
import resourcesSlice from './modules/resources/resourcesSlice';
import brandingResourcesSlice from './modules/settings/branding/brandingResourcesSlice';
import brandingSlice from './modules/settings/branding/brandingSlice';
import brandingsSlice from './modules/settings/branding/brandingsSlice';
import deleteBrandingsSlice from './modules/settings/branding/deleteBrandingsSlice';
import upsertBrandingSlice from './modules/settings/branding/upsertBrandingSlice';
import globalSettingsSlice from './modules/settings/global/globalSettingsSlice';
import updateGlobalSettingsSlice from './modules/settings/global/updateGlobalSettingsSlice';
import deleteForwardersSlice from './modules/settings/log/deleteForwardersSlice';
import forwardersSlice from './modules/settings/log/forwardersSlice';
import upsertForwarderSlice from './modules/settings/log/upsertForwarderSlice';
import realmSettingSlice from './modules/settings/realm/realmSettingSlice';
import realmSettingsSlice from './modules/settings/realm/realmSettingsSlice';
import realmTemplateSlice from './modules/settings/realm/realmTemplateSlice';
import updateRealmSettingSlice from './modules/settings/realm/updateRealmSettingSlice';
import updateRealmTemplatePreviewSlice from './modules/settings/realm/updateRealmTemplatePreviewSlice';
import createSigningCertSlice from './modules/settings/signingCert/createSigningCertSlice';
import deleteSigningCertsSlice from './modules/settings/signingCert/deleteSigningCertsSlice';
import signingCertsSlice from './modules/settings/signingCert/signingCertsSlice';
import updateSigningCertSlice from './modules/settings/signingCert/updateSigningCertSlice';
import defaultTemplateSlice from './modules/settings/templates/defaultTemplateSlice';
import deleteTemplatesSlice from './modules/settings/templates/deleteTemplatesSlice';
import previewTemplateSlice from './modules/settings/templates/previewTemplateSlice';
import templateSlice from './modules/settings/templates/templateSlice';
import templatesSlice from './modules/settings/templates/templatesSlice';
import upsertTemplateSlice from './modules/settings/templates/upsertTemplateSlice';

import smsSlice from './modules/sms/smsSlice';
import trialSlice from './modules/trial/trialSlice';
import validateTrialSlice from './modules/trial/validateTrialSlice';
import usageRealmsSlice from './modules/usage/realmSlice';
import usageSummarySlice from './modules/usage/summarySlice';
import usageDatetimeSlice from './modules/usage/usageDateTimeSlice';
import usageSlice from './modules/usage/usageSlice';
import addUsersToGroupSlice from './modules/userGroups/addUsersToGroupSlice';
import createUserGroupSlice from './modules/userGroups/createUserGroupSlice';
import deleteUserGroupsSlice from './modules/userGroups/deleteUserGroupsSlice';
import deleteUsersFromGroupSlice from './modules/userGroups/deleteUsersFromGroupSlice';
import updateUserGroupAppPermissionSlice from './modules/userGroups/updateUserGroupAppPermissionSlice';
import updateUserGroupSlice from './modules/userGroups/updateUserGroupSlice';
import updateUsersInGroupSlice from './modules/userGroups/updateUsersInGroupSlice';
import userGroupApplicationsSlice from './modules/userGroups/userGroupApplicationsSlice';
import userGroupPermissionSlice from './modules/userGroups/userGroupPermissionSlice';
import userGroupSlice from './modules/userGroups/userGroupSlice';
import userGroupsSlice from './modules/userGroups/userGroupsSlice';
import usersByGroupSlice from './modules/userGroups/usersByGroupSlice';
import usersByRealmSlice from './modules/userGroups/usersByRealmSlice';
import batchAddUserSlice from './modules/users/batchAddUserSlice';
import batchDeleteUserSlice from './modules/users/batchDeleteUserSlice';
import deleteUsersSlice from './modules/users/deleteUsersSlice';
import deleteWebauthnCredentialsSlice from './modules/users/deleteUserWebauthnCredentialsSlice';
import removeUsersAliasSlice from './modules/users/removeUsersAliasSlice';
import updateUsersAliasSlice from './modules/users/updateUsersAliasSlice';
import updateUsersTokenSlice from './modules/users/updateUsersTokenSlice';
import userExtensionSlice from './modules/users/userExtensionSlice';
import userFormSubmitSlice from './modules/users/userFormSubmitSlice';
import userSlice from './modules/users/userSlice';
import usersSlice from './modules/users/usersSlice';
import userWebauthnCredentialsSlice from './modules/users/userWebauthnCredentialsSlice';
import registerationOptionSlice from './modules/users/webauthnRegistrationOptionSlice';
import webauthnRegistrationVerificationSlice from './modules/users/webauthnRegistrationVerificationSlice';

const rootReducer = {
    auth: authReducer,
    metadata: metadataSlice,
    adminGroups: adminGroupsSlice,
    admins: adminsSlice,
    adminGroupsPermissions: adminGroupsPermissionsSlice,
    createAdminGroup: createAdminGroupSlice,
    updateAdminGroup: updateAdminGroupSlice,
    deleteAdminGroups: deleteAdminGroupsSlice,
    deleteAdminByGroup: deleteAdminGroupSlice,
    adminsByGroup: adminsByGroupSlice,
    updateAdminsByGroup: updateAdminsByGroupSlice,
    realmsByAdminGroup: realmsByAdminGroupSlice,
    updateRealmsByAdminGroup: updateRealmsByAdminGroupSlice,
    users: usersSlice,
    user: userSlice,
    userExtension: userExtensionSlice,
    batchAddUser: batchAddUserSlice,
    batchDeleteUser: batchDeleteUserSlice,
    updateUsersToken: updateUsersTokenSlice,
    updateUsersAlias: updateUsersAliasSlice,
    removeUsersAlias: removeUsersAliasSlice,
    userFormSubmit: userFormSubmitSlice,
    deleteUsers: deleteUsersSlice,
    navbar: navbarReducer,
    realms: realmsSlice,
    realmsBrief: realmsBriefSlice,
    realmPermissions: realmPermissionsSlice,
    realmQuota: realmQuotaSlice,
    realmQuotaMap: realmQuotaMapSlice,
    realmClients: realmClientsSlice,
    deleteRealmPermission: deleteRealmPermissionSlice,
    deleteRealms: deleteRealmsSlice,
    createRealm: createRealmSlice,
    updateRealm: updateRealmSlice,
    fortiProductClients: fortiProductClientsSlice,
    fortiProductClientUsers: fortiProductClientUsersSlice,
    searchFortiProductClients: searchFortiProductClientsSlice,
    deleteFortiProductClients: deleteFortiProductClientsSlice,
    updateFortiProductClient: updateFortiProductClientSlice,
    mgmtAppClients: mgmtAppClientsSlice,
    mgmtAppClient: mgmtAppClientSlice,
    upsertMgmtAppClient: upsertMgmtAppClientSlice,
    deleteMgmtAppClients: deleteMgmtAppClientsSlice,
    updateSecretMgmtAppClient: updateSecretMgmtAppClientSlice,
    webAppClients: webAppClientsSlice,
    webAppUsers: webAppUsersSlice,
    deleteWebAppClients: deleteWebAppClientsSlice,
    updateSecretWebAppClient: updateSecretWebAppClientSlice,
    upsertWebAppClient: upsertWebAppClientSlice,
    accounts: accountsSlice,
    accountsStatus: accountsStatusSlice,
    licenses: licensesSlice,
    logs: logsSlice,
    logDates: logDateSlice,
    resources: resourcesSlice,
    balance: balanceSlice,
    alerts: alertsSlice,
    usageRealms: usageRealmsSlice,
    quota: quotaSlice,
    usage: usageSlice,
    usageSummary: usageSummarySlice,
    usageDatetime: usageDatetimeSlice,
    sms: smsSlice,
    authDevice: authDeviceSlice,
    authDeviceImportTokens: importTokensSlice,
    authDeviceDeleteTokens: deleteTokenSlice,
    events: eventsSlice,
    groupsByEvent: groupsByEventSlice,
    createEvent: createEventSlice,
    updateEvent: updateEventSlice,
    deleteEvents: deleteEventsSlice,
    eventReceivers: eventReceiversSlice,
    receiver: receiverSlice,
    receivers: receiversSlice,
    upsertReceiver: upsertReceiverSlice,
    deleteReceivers: deleteReceiversSlice,
    groups: groupsSlice,
    receiversGroup: receiversGroupSlice,
    createGroup: createGroupSlice,
    updateGroup: updateGroupSlice,
    deleteGroups: deleteGroupsSlice,
    policies: policiesSlice,
    policy: policySlice,
    profiles: profilesSlice,
    deletePolicies: deletePoliciesSlice,
    upsertPolicy: upsertPolicySlice,
    deleteProfiles: deleteProfilesSlice,
    upsertProfile: upsertProfileSlice,
    profile: profileSlice,
    announcement: announcementSlice,
    templates: templatesSlice,
    defaultTemplate: defaultTemplateSlice,
    template: templateSlice,
    deleteTemplates: deleteTemplatesSlice,
    previewTemplate: previewTemplateSlice,
    upsertTemplate: upsertTemplateSlice,
    globalSettings: globalSettingsSlice,
    updateGlobalSettings: updateGlobalSettingsSlice,
    realmSettings: realmSettingsSlice,
    realmSetting: realmSettingSlice,
    updateRealmSetting: updateRealmSettingSlice,
    realmTemplate: realmTemplateSlice,
    updateRealmTemplatePreview: updateRealmTemplatePreviewSlice,
    deviceTasks: deviceTasksSlice,
    approveDeviceTask: approveTaskSlice,
    deleteDeviceTask: deleteTaskSlice,
    createDeviceTask: createTaskSlice,
    devicesRemote: devicesRemoteSlice,
    devicesStatus: devicesStatusSlice,
    devicesInClusterStatus: devicesInClusterStatusSlice,
    deleteDevices: deleteDevicesSlice,
    devices: devicesSlice,
    moveDevices: moveDevicesSlice,
    forwarders: forwardersSlice,
    upsertForwarder: upsertForwarderSlice,
    deleteForwarders: deleteForwardersSlice,
    ssoAccount: ssoAccountSlice,
    trial: trialSlice,
    validateTrial: validateTrialSlice,
    refreshBalance: refreshBalanceSlice,
    webauthnCredentials: webauthnCredentialSlice,
    userWebauthnCredentials: userWebauthnCredentialsSlice,
    deleteUserWebauthnCredentials: deleteWebauthnCredentialsSlice,
    deleteWebauthnCredential: deleteWebauthnCredentialSlice,
    webauthnRegistrationOption: registerationOptionSlice,
    webauthnRegistrationVerification: webauthnRegistrationVerificationSlice,
    createSigningCert: createSigningCertSlice,
    deleteSigningCerts: deleteSigningCertsSlice,
    signingCerts: signingCertsSlice,
    updateSigningCert: updateSigningCertSlice,
    domainMappings: domainMappingsSlice,
    domainMappingResources: domainMappingResourcesSlice,
    deleteDomainMappings: deleteDomainMappingsSlice,
    upsertDomainMapping: upsertDomainMappingSlice,
    brandingResources: brandingResourcesSlice,
    branding: brandingSlice,
    brandings: brandingsSlice,
    deleteBrandings: deleteBrandingsSlice,
    upsertBranding: upsertBrandingSlice,
    applications: applicationsSlice,
    application: applicationSlice,
    upsertApplication: upsertApplicationSlice,
    applicationRealms: applicationRealmsSlice,
    appResources: applicationResourcesSlice,
    appBrandings: appBrandingsSlice,
    createAppBranding: createAppBrandingSlice,
    appSigningCerts: appSigningCertsSlice,
    createAppSigningCert: createAppSigningCertSlice,
    createAppUserSource: createAppUserSourceSlice,
    appUserSources: appUserSourcesSlice,
    deleteApplications: deleteApplicationsSlice,
    userSource: userSourceSlice,
    userSources: userSourcesSlice,
    userSourceRealms: userSourceRealmsSlice,
    deleteUserSources: deleteuserSourcesSlice,
    upsertUserSource: upsertuserSourceSlice,
    userSourceResources: userSourceResourcesSlice,
    createUserSourceDomainMapping: createUserSourceDomainMappingSlice,
    userGroup: userGroupSlice,
    userGroups: userGroupsSlice,
    usersByGroup: usersByGroupSlice,
    createUserGroup: createUserGroupSlice,
    deleteUserGroups: deleteUserGroupsSlice,
    updateUserGroup: updateUserGroupSlice,
    addUsersToGroup: addUsersToGroupSlice,
    deleteUsersFromGroup: deleteUsersFromGroupSlice,
    updateUsersInGroup: updateUsersInGroupSlice,
    usersByRealm: usersByRealmSlice,
    userGroupApplications: userGroupApplicationsSlice,
    userGroupPermission: userGroupPermissionSlice,
    updateUserGroupAppPermission: updateUserGroupAppPermissionSlice,
    realmApplications: realmApplicationsSlice,
    adminAlert: adminAlertSlice,
    regions: regionsSlice,
    createUserPortalBranding: createUserPortalBrandingSlice,
    userPortalBrandings: userPortalBrandingsSlice,
    deleteUserPortals: deleteUserPortalsSlice,
    upsertUserPortal: upsertUserPortalSlice,
    userPortalRealms: userPortalRealmsSlice,
    userPortalResources: userPortalResourcesSlice,
    userPortal: userPortalSlice,
    userPortals: userPortalsSlice,
    createUserPortalUserSource: createUserPortalUserSourceSlice,
    userPortalUserSources: userPortalUserSourcesSlice,
};

export default rootReducer;
