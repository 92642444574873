import { Icon } from '../Icon';

const ONLINE_HELP_URL = 'https://docs.fortinet.com/document/fortitoken-cloud/latest/admin-guide?cshid=';

const ONLINE_HELP_MAP: Record<string, { doc_id: string; desc: string }> = {
    root: { doc_id: 'Dashboard', desc: 'Overview' },
    admin: { doc_id: 'Administrators', desc: 'Administrator Management' },
    realm: { doc_id: 'Realms', desc: 'Realm Management' },
    users: { doc_id: 'User_Management-Users', desc: 'User Management' },
    groups: { doc_id: 'User_Management-Groups', desc: 'User Group Management' },
    'applications/fortiprod': { doc_id: 'Applications-FortiProducts', desc: 'Forti Products Management' },
    'applications/webapp': { doc_id: 'Applications-Web', desc: 'Rest API for 3rd Party Apps' },
    'applications/mgmtapp': { doc_id: 'Applications-Management', desc: 'Management Apps' },
    'applications/ssoapp': { doc_id: 'Applications-SSO', desc: 'SSO Application Management' },
    'applications/userportal': { doc_id: 'Applications-End-user_Portals', desc: 'End-User Portal Management' },
    'authentication/usersource': {
        doc_id: 'Authentication-User_Source',
        desc: 'Authentication User Source Management',
    },
    'authentication/domain': { doc_id: 'Authentication-Domain_Mapping', desc: 'Domain Mapping for User Sources' },
    'devices/ownership': { doc_id: 'Devices-Ownership', desc: 'Device Ownership Management' },
    'devices/cluster': { doc_id: 'Devices-Clusters', desc: 'Device Cluster Management' },
    'authdevice/softtoken': { doc_id: 'Tokens-Mobile', desc: 'Mobile Token Management' },
    'authdevice/hardtoken': { doc_id: 'Tokens-Hardware', desc: 'Hardware Token Management' },
    'authdevice/passkey': { doc_id: 'Tokens-Passkey', desc: 'Passkey Management' },
    usage: { doc_id: 'Usage', desc: 'Usage Management' },
    licenses: { doc_id: 'Licenses', desc: 'Licenses Management' },
    'settings/global': { doc_id: 'Settings-Global', desc: 'Global Settings' },
    'settings/template': { doc_id: 'Settings-Templates', desc: 'Template Management' },
    'settings/realm': { doc_id: 'Settings-Realm', desc: 'Realm Settings' },
    'settings/cert': { doc_id: 'Settings-Certificates', desc: 'Signing Certificate Management' },
    'settings/branding': { doc_id: 'Settings-Brandings', desc: 'Branding Management' },
    'adaptiveauth/profile': { doc_id: 'Adaptive_Auth', desc: 'Adaptive Authentication' },
    'adaptiveauth/policy': { doc_id: 'Adaptive_Auth', desc: 'Adaptive Authentication' },
    'alarm/event': { doc_id: 'Alarm', desc: 'Alarm' },
    'alarm/notification/group': { doc_id: 'Alarm', desc: 'Alarm' },
    'alarm/notification/receiver': { doc_id: 'Alarm', desc: 'Alarm' },
    'logs/auth': { doc_id: 'Logs-Authentication', desc: 'Authentication Logs' },
    'logs/mgmt': { doc_id: 'Logs-Management', desc: 'Management Logs' },
    'logs/sms': { doc_id: 'Logs-SMS', desc: 'SMS Logs' },
};

type HelpLinkProps = {
    mapKey:
        | 'root'
        | 'admin'
        | 'realm'
        | 'users'
        | 'groups'
        | 'applications/fortiprod'
        | 'applications/webapp'
        | 'applications/mgmtapp'
        | 'applications/ssoapp'
        | 'applications/userportal'
        | 'authentication/usersource'
        | 'authentication/domain'
        | 'devices/ownership'
        | 'devices/cluster'
        | 'authdevice/softtoken'
        | 'authdevice/hardtoken'
        | 'authdevice/passkey'
        | 'usage'
        | 'settings/global'
        | 'settings/template'
        | 'settings/realm'
        | 'settings/log'
        | 'settings/cert'
        | 'settings/branding'
        | 'adaptiveauth/profile'
        | 'adaptiveauth/policy'
        | 'alarm/event'
        | 'alarm/notification/group'
        | 'alarm/notification/receiver'
        | 'logs/auth'
        | 'logs/mgmt'
        | 'logs/sms'
        | 'licenses';
};

const HelpLink = (props: HelpLinkProps) => {
    const endpoint = ONLINE_HELP_MAP[props.mapKey]['doc_id'] ?? ONLINE_HELP_MAP.root.doc_id;
    return (
        <a href={`${ONLINE_HELP_URL}${endpoint}`} className="help-link" rel="noopener" target="blank" title="help">
            <i style={{ verticalAlign: 'middle' }}>
                <Icon classNames={['material-symbols-outlined', 'fill']} content="help" />
            </i>
        </a>
    );
};

export { HelpLink };
