import { createSelector } from '@reduxjs/toolkit';
import { formatUserGroup } from './helper';
import { UserGroupsResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface InitialState extends GenericState<UserGroupsResponse | null> {}

const initialState: InitialState = {
    loading: false,
    data: null,
    error: null,
};

const userGroupsSlice = createGenericSlice({
    name: 'userGroups',
    initialState: initialState as InitialState,
    reducers: {
        loadRequest: (state) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserGroupsResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userGroupsSlice.actions;

export const selectUserGroupsRealmOptions = createSelector(
    (state: RootState) => state.userGroups.data?.realms.map(({ id, name }) => ({ value: id, label: name })),
    (data) => data ?? []
);

export const selectRealmMap = createSelector(
    (state: RootState) => {
        const realmMap = state.userGroups.data?.realms.reduce(
            (acc, cur) => {
                acc[cur.id] = cur;
                return acc;
            },
            {} as Record<string, RealmBrief>
        );
        return realmMap;
    },
    (data) => data ?? {}
);

export const selectUserGroups = createSelector(
    (state: RootState) =>
        state.userGroups.data?.userGroups.map((userGroup) => {
            const realmMap =
                state.userGroups.data?.realms.reduce(
                    (acc, cur) => {
                        acc[cur.id] = cur;
                        return acc;
                    },
                    {} as Record<string, RealmBrief>
                ) ?? {};
            return formatUserGroup(userGroup, realmMap);
        }),
    (data) => data ?? []
);

export default userGroupsSlice.reducer;
