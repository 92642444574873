import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

export interface SSOAccount {
    source_app: string;
    account_id: string | number;
    user_id: string | number;
    node_id: null | string | number;
    user_fullaccess: boolean;
    partner_id: string | number;
    context_data: string;
    visited_sites: {
        app_name: string;
        last_url: string;
    }[];
}

interface SSOAccountState {
    loading: boolean;
    data: SSOAccount;
    error: Error | null;
}

const initialState: SSOAccountState = {
    loading: false,
    data: {
        source_app: 'FTC',
        account_id: '0',
        user_id: '0',
        node_id: null,
        user_fullaccess: true,
        partner_id: '',
        context_data: '',
        visited_sites: [],
    },
    error: null,
};

const ssoAccountSlice = createSlice({
    name: 'visitedUrls',
    initialState,
    reducers: {
        setCurrentUrl: (state, action: PayloadAction<{ url: string }>) => {
            // no need to return to account selection logged in or not
            if (action.payload.url.includes('login/accountselection')) {
                return;
            }
            const stateCopy = { ...state.data };
            const visitedSites = stateCopy.visited_sites;
            const FTCSite = visitedSites.find((site) => site.app_name === 'FTC');
            if (FTCSite) {
                FTCSite.last_url = action.payload.url;
            } else {
                visitedSites.push({ app_name: 'FTC', last_url: action.payload.url });
            }
            state.data = stateCopy;
        },
        setJsonAccount: (
            state,
            action: PayloadAction<{
                data: SSOAccount | { access_from: SSOAccount };
            }>
        ) => {
            const { data } = action.payload;
            //@ts-ignore
            const stateCopy: SSOAccount = { ...state.data };
            const accountData = 'access_from' in data ? data.access_from : data;
            (Object.keys(accountData) as Array<keyof SSOAccount>).forEach((key) => {
                if (key in stateCopy && accountData[key] != null) {
                    //@ts-ignore
                    stateCopy[key] = accountData[key];
                }
            });
            if ('node_id' in data) {
                stateCopy.node_id = data.node_id;
            }
            state.data = stateCopy;
        },
        clearSSOAcount: (state) => {
            state = initialState;
        },
    },
});

export const { setCurrentUrl, setJsonAccount, clearSSOAcount } = ssoAccountSlice.actions;

export const getVisitedSites = (state: RootState) => state.ssoAccount.data.visited_sites;

export const getFTCLastUrl = (state: RootState) =>
    state.ssoAccount.data.visited_sites.find((site) => site.app_name === 'FTC')?.last_url;

export const getJsonAccount = (state: RootState) => JSON.stringify(state.ssoAccount.data);

export default ssoAccountSlice.reducer;
