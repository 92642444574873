import './AccountCard.scss';
import { useNavigate } from 'react-router-dom';
import { MASTER_USER, FTC_IN_TRIAL, FTC_LICENSED, FTC_NO_LICENSE } from '@shared/utils/constants';
import { useAppSelector } from '@store/hooks';
import { selectAccountsStatusMap } from '@store/modules/accounts/accountsStatusSlice';

type AccountCardType = {
    activeUser: AuthUser | null;
    user: AuthUser;
    onClick: (user: AuthUser) => void;
    seq: number;
};

const avatars = [
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
            <g opacity="0.8">
                <path
                    d="M143.996 71.992C143.996 86.7622 139.535 100.504 131.914 111.925C131.745 112.178 131.576 112.427 131.396 112.676C130.878 113.443 130.34 114.19 129.806 114.933C117.141 131.94 97.1524 143.185 74.4976 143.964C73.6704 143.98 72.8392 144 71.996 144C70.0084 144 68.0368 143.916 66.1054 143.755C44.2657 141.996 25.2046 130.487 13.2347 113.604C13.046 113.339 12.8613 113.074 12.6806 112.809C12.3393 112.319 12.006 111.825 11.6768 111.323C11.1748 110.544 10.689 109.765 10.2111 108.97C3.73431 98.1712 0 85.5213 0 71.992C0 32.2311 32.2396 0 72 0C111.76 0 144 32.2311 144 71.992H143.996Z"
                    fill="#C2C3FD"
                />
                <mask
                    id="mask0_765_127198"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="144"
                    height="144"
                >
                    <path
                        d="M143.996 71.992C143.996 86.7622 139.535 100.504 131.914 111.925C131.745 112.178 131.576 112.427 131.396 112.676C130.878 113.443 130.34 114.19 129.806 114.933C117.141 131.94 97.1524 143.185 74.4976 143.964C73.6704 143.98 72.8392 144 71.996 144C70.0084 144 68.0368 143.916 66.1054 143.755C44.2657 141.996 25.2046 130.487 13.2347 113.604C13.046 113.339 12.8613 113.074 12.6806 112.809C12.3393 112.319 12.006 111.825 11.6768 111.323C11.1748 110.544 10.689 109.765 10.2111 108.97C3.73431 98.1712 0 85.5213 0 71.992C0 32.2311 32.2396 0 72 0C111.76 0 144 32.2311 144 71.992H143.996Z"
                        fill="#A5A6F6"
                    />
                </mask>
                <g mask="url(#mask0_765_127198)">
                    <path
                        d="M71.5 149.794C93.2493 149.794 113.234 142.921 129 131.431C127.156 109.431 102.118 92 71.5 92C40.8818 92 15.8436 109.431 14 131.431C29.7663 142.921 49.7507 149.794 71.5 149.794Z"
                        fill="#9D9EFA"
                    />
                    <circle cx="72" cy="60" r="26" fill="#9D9EFA" />
                </g>
            </g>
        </svg>
    </>,
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
            <g opacity="0.8">
                <path
                    d="M143.996 71.992C143.996 86.7622 139.535 100.504 131.914 111.925C131.745 112.178 131.576 112.427 131.396 112.676C130.878 113.443 130.34 114.19 129.806 114.933C117.141 131.94 97.1524 143.185 74.4976 143.964C73.6704 143.98 72.8392 144 71.996 144C70.0084 144 68.0368 143.916 66.1054 143.755C44.2657 141.996 25.2046 130.487 13.2347 113.604C13.046 113.339 12.8613 113.074 12.6806 112.809C12.3393 112.319 12.006 111.825 11.6768 111.323C11.1748 110.544 10.689 109.765 10.2111 108.97C3.73431 98.1712 0 85.5213 0 71.992C0 32.2311 32.2396 0 72 0C111.76 0 144 32.2311 144 71.992H143.996Z"
                    fill="#F2D8D7"
                />
                <mask
                    id="mask0_765_127208"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="144"
                    height="144"
                >
                    <path
                        d="M143.996 71.992C143.996 86.7622 139.535 100.504 131.914 111.925C131.745 112.178 131.576 112.427 131.396 112.676C130.878 113.443 130.34 114.19 129.806 114.933C117.141 131.94 97.1524 143.185 74.4976 143.964C73.6704 143.98 72.8392 144 71.996 144C70.0084 144 68.0368 143.916 66.1054 143.755C44.2657 141.996 25.2046 130.487 13.2347 113.604C13.046 113.339 12.8613 113.074 12.6806 112.809C12.3393 112.319 12.006 111.825 11.6768 111.323C11.1748 110.544 10.689 109.765 10.2111 108.97C3.73431 98.1712 0 85.5213 0 71.992C0 32.2311 32.2396 0 72 0C111.76 0 144 32.2311 144 71.992H143.996Z"
                        fill="#A5A6F6"
                    />
                </mask>
                <g mask="url(#mask0_765_127208)">
                    <path
                        d="M71.5 149.794C93.2493 149.794 113.234 142.921 129 131.431C127.156 109.431 102.118 92 71.5 92C40.8818 92 15.8436 109.431 14 131.431C29.7663 142.921 49.7507 149.794 71.5 149.794Z"
                        fill="#F59A95"
                    />
                    <circle cx="72" cy="60" r="26" fill="#F59A95" />
                </g>
            </g>
        </svg>
    </>,
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 144 144" fill="none">
            <g opacity="0.8">
                <path
                    d="M143.996 71.992C143.996 86.7622 139.535 100.504 131.914 111.925C131.745 112.178 131.576 112.427 131.396 112.676C130.878 113.443 130.34 114.19 129.806 114.933C117.141 131.94 97.1524 143.185 74.4976 143.964C73.6704 143.98 72.8392 144 71.996 144C70.0084 144 68.0368 143.916 66.1054 143.755C44.2657 141.996 25.2046 130.487 13.2347 113.604C13.046 113.339 12.8613 113.074 12.6806 112.809C12.3393 112.319 12.006 111.825 11.6768 111.323C11.1748 110.544 10.689 109.765 10.2111 108.97C3.73431 98.1712 0 85.5213 0 71.992C0 32.2311 32.2396 0 72 0C111.76 0 144 32.2311 144 71.992H143.996Z"
                    fill="#BCDAFF"
                />
                <mask
                    id="mask0_765_127219"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="144"
                    height="144"
                >
                    <path
                        d="M143.996 71.992C143.996 86.7622 139.535 100.504 131.914 111.925C131.745 112.178 131.576 112.427 131.396 112.676C130.878 113.443 130.34 114.19 129.806 114.933C117.141 131.94 97.1524 143.185 74.4976 143.964C73.6704 143.98 72.8392 144 71.996 144C70.0084 144 68.0368 143.916 66.1054 143.755C44.2657 141.996 25.2046 130.487 13.2347 113.604C13.046 113.339 12.8613 113.074 12.6806 112.809C12.3393 112.319 12.006 111.825 11.6768 111.323C11.1748 110.544 10.689 109.765 10.2111 108.97C3.73431 98.1712 0 85.5213 0 71.992C0 32.2311 32.2396 0 72 0C111.76 0 144 32.2311 144 71.992H143.996Z"
                        fill="#A5A6F6"
                    />
                </mask>
                <g mask="url(#mask0_765_127219)">
                    <path
                        d="M71.5 149.794C93.2493 149.794 113.234 142.921 129 131.431C127.156 109.431 102.118 92 71.5 92C40.8818 92 15.8436 109.431 14 131.431C29.7663 142.921 49.7507 149.794 71.5 149.794Z"
                        fill="#69ACFF"
                    />
                    <circle cx="72" cy="60" r="26" fill="#69ACFF" />
                </g>
            </g>
        </svg>
    </>,
];

const AccountCard = ({ activeUser, user, onClick, seq }: AccountCardType) => {
    const isCurrentUser = activeUser && activeUser.account_id === user.account_id;
    const accountsStatusMap = useAppSelector(selectAccountsStatusMap);
    const navigate = useNavigate();

    return (
        <button
            type="button"
            className={['account-card', isCurrentUser ? 'active' : ''].join(' ')}
            disabled={accountsStatusMap[user.account_id] == null}
            onClick={(e) => {
                e.preventDefault();
                if (isCurrentUser) {
                    navigate('/dashboard');
                    return;
                }
                onClick(user);
            }}
        >
            <div className="account-card-top">
                <div className="account-avatar">
                    {/* <img src={avatars[seq % 3]} alt={user.account_id} /> */}
                    {avatars[seq % 3]}
                </div>
            </div>
            <div className="account-card-bottom">
                <dl>
                    <dt>Account ID</dt>
                    <dd className="text-truncate" title={user.account_id.toString()}>
                        {user.account_id}
                    </dd>
                </dl>
                <dl>
                    <dt>Email</dt>
                    <dd className="text-truncate" title={user.account_email}>
                        {user.account_email}
                    </dd>
                </dl>
                <dl>
                    <dt>Company</dt>
                    <dd className="text-truncate" title={user.account_company}>
                        {user.account_company}
                    </dd>
                </dl>
                <dl>
                    <dt>Status</dt>
                    <dd>
                        <div className="d-flex align-items-center justify-content-between text-truncate">
                            <div>
                                {accountsStatusMap[user.account_id] == null && (
                                    <div className="text-white d-flex align-items-center gap-1">
                                        <div className="spinner-border inline-loader" role="status"></div>
                                    </div>
                                )}
                                {(accountsStatusMap[user.account_id]?.ftc_status === FTC_IN_TRIAL ||
                                    accountsStatusMap[user.account_id]?.ftc_status === FTC_NO_LICENSE) && (
                                    <div className="text-warning d-flex align-items-center gap-1">{'Free Trial'}</div>
                                )}
                                {accountsStatusMap[user.account_id]?.ftc_status === FTC_LICENSED && (
                                    <div className="text-success d-flex align-items-center gap-1">{'Licensed'}</div>
                                )}
                            </div>
                            <div>{user.user_type === MASTER_USER && <span className="master-badge">Master</span>}</div>
                        </div>
                    </dd>
                </dl>
            </div>
        </button>
    );
};

export { AccountCard };
