import { SAML_CODE } from '@shared/utils/idpProxy';
import axiosClient from '../axiosClient';
import { API } from '../index';

export type ApplicationParams = { type: number };
export type DomainMappingParams = { application_id?: string };

export function* getApplicationPreview(type: number = SAML_CODE): Generator {
    return yield axiosClient
        .get<ApplicationPreview>(`${API.APPLICATION_PREVIEW}/?type=${type}`)
        .then((res) => res.data);
}

export function* getApplication({ id }: { id: string }): Generator {
    return yield axiosClient.get<ApplicationDetail>(`${API.APPLICATION}/${id}`).then((res) => res.data);
}

export function* getApplications(params?: ApplicationParams): Generator {
    return yield axiosClient.get<Application[]>(API.APPLICATION, { params }).then((res) => res.data);
}

export function* postApplication({ data }: { data: UpsertApplicationData }): Generator {
    return yield axiosClient.post(API.APPLICATION, data).then((res) => res.data);
}

export function* putApplication({ id, data }: { id: string; data: UpsertApplicationData }): Generator {
    return yield axiosClient.put(`${API.APPLICATION}/${id}`, data).then((res) => res.data);
}

export function* deleteApplication({ id }: { id: string }): Generator {
    return yield axiosClient.delete(`${API.APPLICATION}/${id}`).then((res) => res.data);
}

export function* putAppUserSourceMapping({
    appId,
    data,
}: {
    appId: string;
    data: UpdateAppUserSourceMappingData;
}): Generator {
    return yield axiosClient.put(API.APPLICATION_USERSOURCE_MAPPING(appId), data).then((res) => res.data);
}
