import { NF } from '@shared/utils/constants';

export function createAlertData(element: Alert, realmMap?: Record<string, RealmBrief>): AlertData {
    const realm_name = element.realm_id ? realmMap?.[element.realm_id].name : '';
    return {
        id: element.id,
        level: element.realm_id ? 'realm' : 'global',
        resource: element.resource,
        realmId: element.realm_id,
        realmName: realm_name ?? '',
        description: element.description,
        threshold: element.resource === NF.USERS ? Math.floor(element.threshold * 100) : Math.floor(element.threshold),
        notificationGroupsCount: element.notification_groups_count,
        select: false,
        indicator: createAlertIndicator(realm_name ?? '', element.resource),
        sentTime: element.sent_time
            ? element.sent_time.indexOf('Z') === -1
                ? new Date(element.sent_time + 'Z').toLocaleString()
                : new Date(element.sent_time).toLocaleString()
            : '0',
    };
}

export function createAlertIndicator(realm_name: string, resource: string): string {
    return `${realm_name ? `Realm ${realm_name}` : 'Global Level'} ${resource} resource alarm`;
}
