import { Col, Row } from 'reactstrap';
import { Icon } from '@shared/components/Icon';
import { classnames } from '@shared/utils/classnames';
import { DEFAULT_COL } from '@shared/utils/constants';
import { FErrorMessage } from '../FErrorMessage';

/**
 * Compared with FLabel.tsx.
 * This is used to wrapper shared components under Form, such as FInput.tsx, FLabelSelect.tsx, ...
 * Name type is required here for error msg.
 * Name in FLabel is not required. Given undefined type could cause error.
 */
const FLabelWrapper = ({
    label,
    cols = DEFAULT_COL,
    labelStyle = {},
    styles = {},
    cssClasses = [],
    align = 'center',
    alignText = 'end',
    children,
    id,
    name,
    showRequired,
    tooltip,
}: React.PropsWithChildren<TFLabel & TFLabelWrapper>) => {
    return (
        <div className={classnames(['mb-5', ...cssClasses])} style={styles}>
            <Row className={classnames([`align-items-${align}`])}>
                <Col xs={cols[0]} className="d-inline-flex align-items-center">
                    <label
                        className={classnames(['w-100', `text-${alignText}`, 'break-all-word'])}
                        style={labelStyle}
                        htmlFor={id || name}
                    >
                        {label}
                    </label>
                    {showRequired && <span className="text-danger">*</span>}
                    {tooltip && (
                        <span style={{ cursor: 'pointer' }} className="d-inline-flex" title={tooltip}>
                            <Icon
                                classNames={[
                                    'material-symbols-outlined btn-outline-secondary text-primary font-size-xs ms-1',
                                ]}
                                content="info"
                            />
                        </span>
                    )}
                </Col>
                <Col xs={cols[1]}>{children}</Col>
            </Row>
            <Row>
                <Col
                    xs={cols[1]}
                    md={{
                        offset: cols[0],
                    }}
                >
                    <FErrorMessage name={name} />
                </Col>
            </Row>
        </div>
    );
};

export { FLabelWrapper };
