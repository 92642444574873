import { createSelector } from '@reduxjs/toolkit';
import { disabledAuthMethod, disabledNotifyMethod, formatUser } from './helper';
import { UserResponse } from './watcher';
import { GenericState, createGenericSlice } from '../genericSlice';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '@store/index';

interface UserState extends GenericState<UserResponse | null> {}

const initialState: UserState = {
    loading: false,
    data: null,
    error: null,
};

const userSlice = createGenericSlice({
    name: 'user',
    initialState: initialState as UserState,
    reducers: {
        loadRequest: (state, action: PayloadAction<{ id?: string }>) => {
            state.loading = true;
        },
        loadSuccess: (state, action: PayloadAction<{ data: UserResponse }>) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.data;
        },
    },
});

export const { loadRequest, loadSuccess, loadFailure, cancelLoad } = userSlice.actions;

export const selectUser = createSelector(
    (state: RootState) => {
        const { user, realms, tempTokens } = state.user.data || {};
        // need to check if get user by id will miss any info
        return user ? formatUser(user, realms ?? [], tempTokens ?? []) : null;
    },
    (data) => data
);

export const selectOptions = createSelector(
    (state: RootState) => {
        const { time_base, trial_status } = state.auth.user || {};
        const { balance, SMSCount, hardTokens } = state.user.data || {};
        const { auth_method: currentAuthMethod } = state.user.data?.user || { auth_method: '' };

        const authOptions = ['FTM', 'Email', 'SMS', 'FTK'];
        const notificationOptions = ['Email', 'SMS'];

        const timeBase = time_base;
        let zeroBalance = false;
        if (!timeBase) {
            const parsedBalance: any = balance?.balance.toFixed(1);
            zeroBalance = parsedBalance <= 0;
        }

        const conditionObj = {
            timeBase: timeBase,
            trial: trial_status === 1,
            zeroBalance: zeroBalance,
            SMSCount: SMSCount?.sms,
            hardTokens: hardTokens?.map((token) => token.sn) ?? [],
        };

        const mappedAuthOptions = authOptions.map((method) => ({
            name: method,
            disabled: disabledAuthMethod(method, conditionObj, currentAuthMethod),
        }));
        const mappedNotifyOptions = notificationOptions.map((method) => ({
            name: method,
            disabled: disabledNotifyMethod(method, conditionObj),
        }));

        return {
            auths: mappedAuthOptions,
            notifications: mappedNotifyOptions,
            conditionObj: conditionObj,
        };
    },
    (data) => data
);

export default userSlice.reducer;
