import { toast } from 'react-toastify';
import { all, call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { PAGE_SIZE } from '@pages/Realms/helper';
import { getApplications } from '@services/api/application';
import { getClients } from '@services/api/clients';
import { getQuota, putQuota } from '@services/api/quota';
import { deletePermission, getPermissions } from '@services/api/realmPermissions';
import { deleteRealm, getRealmsBrief, postRealm, putRealm, RealmsBriefResponse } from '@services/api/realms';
import { showErrorAlert } from '@shared/utils/showErrorAlert';
import {
    createRequest as createRealm,
    createFailure as createRealmFailure,
    createSuccess as createRealmSuccess,
    cancelCreate as cancelCreateRealm,
} from './createRealmSlice';
import {
    deleteRequest as deleteRealmPermission,
    deleteSuccess as deleteRealmPermissionSuccess,
    deleteFailure as deleteRealmPermissionFailure,
    cancelDelete as cancelDeleteRealmPermission,
} from './deleteRealmPermissionSlice';
import {
    deleteRequest as deleteRealms,
    deleteSuccess as deleteRealmsSuccess,
    deleteFailure as deleteRealmsFailure,
    cancelDelete as cancelDeleteRealms,
} from './deleteRealmsSlice';
import {
    loadRequest as loadRealmApplications,
    loadFailure as loadRealmApplicationsFailure,
    loadSuccess as loadRealmApplicationsSuccess,
    cancelLoad as cancelLoadRealmApplications,
} from './realmApplicationsSlice';
import {
    loadRequest as loadRealmClients,
    loadSuccess as loadRealmClientsSuccess,
    loadFailure as loadRealmClientsFailure,
    cancelLoad as cancelLoadRealmClients,
} from './realmClientsSlice';
import {
    loadRequest as loadRealmPermissions,
    loadSuccess as loadRealmPermissionsSuccess,
    loadFailure as loadRealmPermissionsFailure,
    cancelLoad as cancelLoadRealmPermissions,
} from './realmPermissionsSlice';
import { selectRealmQuotaMap } from './realmQuotaMapSlice';
import {
    loadRequest as loadRealmQuota,
    loadFailure as loadRealmQuotaFailure,
    loadSuccess as loadRealmQuotaSuccess,
    cancelLoad as cancelLoadRealmQuota,
} from './realmQuotaSlice';
import {
    loadRequest as loadRealmsBrief,
    loadFailure as loadRealmsBriefFailure,
    loadSuccess as loadRealmsBriefSuccess,
    cancelLoad as cancelLoadRealmsBrief,
} from './realmsBriefSlice';
import {
    loadRequest as loadRealms,
    loadFailure as loadRealmsFailure,
    loadSuccess as loadRealmsSuccess,
    cancelLoad as cancelLoadRealms,
} from './realmsSlice';
import {
    updateRequest as updateRealm,
    updateSuccess as updateRealmSuccess,
    updateFailure as updateRealmFailure,
    cancelUpdate as cancelUpdateRealm,
} from './updateRealmSlice';

export type RealmsResponse = {
    realms: RealmBriefWithAppCount[];
    quota: QuotaBreif;
};

export type UpdateRealmsResponse = {
    realm: Realm;
    quota: QuotaBreif;
};

export function* fetchRealms(action: ReturnType<typeof loadRealms>): any {
    try {
        const { response, cancel }: { response: RealmsResponse; cancel: any } = yield race({
            response: all({
                realms: call(getRealmsBrief, { application_count: true }),
                quota: call(getQuota, { brief: true }),
            }),
            cancel: take(cancelLoadRealms),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmsSuccess({ data: response }));

        const realmQuotaMap = yield select(selectRealmQuotaMap);
        // initial to fetch realm quota to store into RealmQuotaMap, only load PAGE_SIEZ items first time
        for (let { id } of response.realms.slice(0, PAGE_SIZE)) {
            // skip if realm is assgined quota, user count can be found in quota table
            // not do fetch if exist in RealmQuotaMap
            if (!realmQuotaMap?.hasOwnProperty(id) && !response.quota.realms.find((realm) => realm.realm_id === id)) {
                yield put(loadRealmQuota({ params: { realm_id: id } }));
            }
        }
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmsFailure(e.message));
    }
}

export function* createRealmRequest(action: ReturnType<typeof createRealm>) {
    const { data } = action.payload;

    try {
        const { response, cancel }: { response: Realm; cancel: any } = yield race({
            response: call(postRealm, { data }),
            cancel: take(cancelCreateRealm),
        });

        if (cancel) {
            return;
        }

        yield put(createRealmSuccess({ data: response }));
        //  load initial data
        yield put(loadRealms());
        toast.success('Realm Created');
    } catch (e) {
        yield put(createRealmFailure(e?.response?.data?.error_message));
        showErrorAlert(e);
    }
}

export function* updateRealmRequest(action: ReturnType<typeof updateRealm>) {
    const {
        data: { id, realm, quota },
    } = action.payload;

    try {
        const { response, cancel }: { response: UpdateRealmsResponse; cancel: any } = yield race({
            response: all({
                realms: call(putRealm, { id: id, data: realm }),
                quota: call(putQuota, { data: quota }),
            }),
            cancel: take(cancelUpdateRealm),
        });

        if (cancel) {
            return;
        }

        yield put(updateRealmSuccess({ data: response }));
        //  load initial data
        yield put(loadRealms());
        toast.success('Realm Updated');
    } catch (e) {
        yield put(updateRealmFailure(e?.response?.data?.error_message));
        showErrorAlert(e);
    }
}

export function* deleteRealmsRequest(action: ReturnType<typeof deleteRealms>) {
    const { ids } = action.payload;

    /**
     * @todo: Will use array after error type add string[]
     */
    // Collect delete failure
    let errors = '';
    for (let id of ids) {
        try {
            const { cancel }: { cancel: any } = yield race({
                response: call(deleteRealm, { id: id }),
                cancel: take(cancelDeleteRealms),
            });

            if (cancel) {
                return;
            }
        } catch (e) {
            errors += e?.response?.data + ', ';
        }
    }

    /**
     * @todo: Will add one case such as 1 success and 1 failure after error type add string[]
     */
    if (errors.length === 0) {
        yield put(deleteRealmsSuccess({ data: 'success' }));
        //  load initial data
        yield put(loadRealms());
        toast.success(`${ids.length} Realm Deleted`);
    } else {
        yield put(deleteRealmsFailure(errors));
        toast.error(errors);
    }
}

export function* fetchRealmClients(action: ReturnType<typeof loadRealmClients>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: Client[]; cancel: any } = yield race({
            response: call(getClients, { params }),
            cancel: take(cancelLoadRealmClients),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmClientsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmClientsFailure(e.message));
    }
}

export function* fetchRealmPermissions(action: ReturnType<typeof loadRealmPermissions>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: RealmPermission[]; cancel: any } = yield race({
            response: call(getPermissions, { params }),
            cancel: take(cancelLoadRealmPermissions),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmPermissionsSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmPermissionsFailure(e.message));
    }
}

export function* fetchRealmQuota(action: ReturnType<typeof loadRealmQuota>) {
    const { params } = action.payload;
    try {
        const { response, cancel }: { response: RealmQuota; cancel: any } = yield race({
            response: call(getQuota, params),
            cancel: take(cancelLoadRealmQuota),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmQuotaSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmQuotaFailure(e.message));
    }
}

export function* fetchRealmsBrief(action: ReturnType<typeof loadRealmsBrief>) {
    try {
        const { params } = action.payload;
        const { response, cancel }: { response: RealmsBriefResponse; cancel: any } = yield race({
            response: call(getRealmsBrief, params),
            cancel: take(cancelLoadRealmsBrief),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmsBriefSuccess({ data: response }));
    } catch (e) {
        showErrorAlert(e);
        yield put(loadRealmsBriefFailure(e.message));
    }
}

export function* deleteRealmPermissionRequest(action: ReturnType<typeof deleteRealmPermission>) {
    const { id, realmId } = action.payload;

    try {
        const { response, cancel }: { response: any; cancel: any } = yield race({
            response: call(deletePermission, { id }),
            cancel: take(cancelDeleteRealmPermission),
        });

        if (cancel) {
            return;
        }

        yield put(deleteRealmPermissionSuccess({ data: response }));
        yield put(loadRealmPermissions({ params: { realm_id: realmId } }));
    } catch (e) {
        yield put(deleteRealmPermissionFailure(e?.response?.data?.error_message));
        showErrorAlert(e);
    }
}

function* fetchRealmApplications(action: ReturnType<typeof loadRealmApplications>) {
    try {
        const { response, cancel }: { response: Application[]; cancel: any } = yield race({
            response: call(getApplications),
            cancel: take(cancelLoadRealmApplications),
        });

        if (cancel) {
            return;
        }

        yield put(loadRealmApplicationsSuccess({ data: response }));
    } catch (e) {
        const errMsg = e?.response?.data ?? e?.response?.data?.error_message;
        yield put(loadRealmApplicationsFailure(errMsg));
        showErrorAlert(e);
    }
}

export function* fetchRealmsSaga() {
    yield takeLatest(loadRealms, fetchRealms);
}

export function* createRealmSaga() {
    yield takeLatest(createRealm, createRealmRequest);
}

export function* updateRealmSaga() {
    yield takeLatest(updateRealm, updateRealmRequest);
}

export function* deleteRealmsSaga() {
    yield takeLatest(deleteRealms, deleteRealmsRequest);
}

export function* fetchRealmClientsSaga() {
    yield takeLatest(loadRealmClients, fetchRealmClients);
}

export function* fetchRealmPermissionsSaga() {
    yield takeLatest(loadRealmPermissions, fetchRealmPermissions);
}

export function* fetchRealmQuotaSaga() {
    yield takeEvery(loadRealmQuota, fetchRealmQuota);
}

export function* fetchRealmsBriefSaga() {
    yield takeLatest(loadRealmsBrief, fetchRealmsBrief);
}

export function* deleteRealmPermissionSaga() {
    yield takeLatest(deleteRealmPermission, deleteRealmPermissionRequest);
}

export function* fetchRealmApplicationsSaga() {
    yield takeLatest(loadRealmApplications, fetchRealmApplications);
}
