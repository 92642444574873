export const ADMIN = {
    GLOBAL: 'global_admin',
    SUB: 'sub_admin',
};

export const MASTER_USER = 'Master';

export const FTC_LICENSED = 2;
export const FTC_IN_TRIAL = 1;
export const FTC_NO_LICENSE = 0;

export const AUTO_LOGOUT_TIME = 10 * 60;
export const COUNT_DOWN_TIME = 5;

export const DATETIME_FORMAT = 'MM-DD-YYYY HH:mm:ss';
export const DATETIME_FORMAT_DATEPICKER = 'MM/dd/yyyy h:mm aa';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_DATEPICKER = 'MM/dd/yyyy';
export const MONTH_FORMAT = 'MM-YYYY';
export const MONTH_FORMAT_DATEPICKER = 'MM/yyyy';
export const QUERY_DATE_FORMAT = 'YYYY-MM-DD';

export const LOG_DEFAULT_TIME_RANGE = 7;
export const LOG_MAX_TIME_RANGE = 45;
export const MAX_LOG_RECORD = 10000;
export const LOG_TOTAL_COUNT_GREATER = 'gte';
export const LOG_TOTAL_COUNT_EQUAL = 'eq';

export const CUSTOMER_DEFAULT_TIME_RANGE = 5;

export const ANY_SOURCE = 0;
export const SOURCE_SUBNET = 1;
export const SOURCE_LOCATION = 2;

export const ACTION_MFA = 0;
export const ACTION_BLOCK = 1;
export const ACTION_BYPASS = 2;

export const DEFAULT_AUTH_INTERVAL = 8;

export const NIL_UUID = '00000000-0000-0000-0000-000000000000';

export const POLICY_ACTIONS = {
    0: 'Multi-factor Authentication',
    1: 'Block',
    2: 'Bypass',
};

export const WEEKDAY_MAPPINGS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const DAYS = [
    { value: 0, text: 'Monday' },
    { value: 1, text: 'Tuesday ' },
    { value: 2, text: 'Wednesday' },
    { value: 3, text: 'Thursday' },
    { value: 4, text: 'Friday' },
    { value: 5, text: 'Saturday' },
    { value: 6, text: 'Sunday' },
];

export const TIMEZONES = [
    { label: '(GMT-12:00) International Date Line West', value: 'Etc/GMT+12' },
    { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
    { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
    { label: '(GMT-09:00) Alaska', value: 'US/Alaska' },
    { label: '(GMT-08:00) Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
    { label: '(GMT-08:00) Tijuana, Baja California', value: 'America/Tijuana' },
    { label: '(GMT-07:00) Arizona', value: 'US/Arizona' },
    { label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', value: 'America/Chihuahua' },
    { label: '(GMT-07:00) Mountain Time (US & Canada)', value: 'US/Mountain' },
    { label: '(GMT-06:00) Central America', value: 'America/Managua' },
    { label: '(GMT-06:00) Central Time (US & Canada)', value: 'US/Central' },
    { label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', value: 'America/Mexico_City' },
    { label: '(GMT-06:00) Saskatchewan', value: 'Canada/Saskatchewan' },
    { label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', value: 'America/Bogota' },
    { label: '(GMT-05:00) Eastern Time (US & Canada)', value: 'US/Eastern' },
    { label: '(GMT-05:00) Indiana (East)', value: 'US/East-Indiana' },
    { label: '(GMT-04:00) Atlantic Time (Canada)', value: 'Canada/Atlantic' },
    { label: '(GMT-04:00) Caracas, La Paz', value: 'America/Caracas' },
    { label: '(GMT-04:00) Manaus', value: 'America/Manaus' },
    { label: '(GMT-04:00) Santiago', value: 'America/Santiago' },
    { label: '(GMT-03:30) Newfoundland', value: 'Canada/Newfoundland' },
    { label: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
    { label: '(GMT-03:00) Buenos Aires, Georgetown', value: 'America/Argentina/Buenos_Aires' },
    { label: '(GMT-03:00) Greenland', value: 'America/Godthab' },
    { label: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
    { label: '(GMT-02:00) Mid-Atlantic', value: 'America/Noronha' },
    { label: '(GMT-01:00) Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
    { label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', value: 'Africa/Casablanca' },
    {
        label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
        value: 'Etc/Greenwich',
    },
    {
        label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: 'Europe/Amsterdam',
    },
    {
        label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: 'Europe/Belgrade',
    },
    { label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', value: 'Europe/Brussels' },
    { label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', value: 'Europe/Sarajevo' },
    { label: '(GMT+01:00) West Central Africa', value: 'Africa/Lagos' },
    { label: '(GMT+02:00) Amman', value: 'Asia/Amman' },
    { label: '(GMT+02:00) Athens, Bucharest, Istanbul', value: 'Europe/Athens' },
    { label: '(GMT+02:00) Beirut', value: 'Asia/Beirut' },
    { label: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
    { label: '(GMT+02:00) Harare, Pretoria', value: 'Africa/Harare' },
    { label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', value: 'Europe/Helsinki' },
    { label: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Minsk', value: 'Europe/Minsk' },
    { label: '(GMT+02:00) Windhoek', value: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Kuwait, Riyadh, Baghdad', value: 'Asia/Kuwait' },
    { label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', value: 'Europe/Moscow' },
    { label: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Tbilisi', value: 'Asia/Tbilisi' },
    { label: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
    { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Muscat' },
    { label: '(GMT+04:00) Baku', value: 'Asia/Baku' },
    { label: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
    { label: '(GMT+05:00) Yekaterinburg', value: 'Asia/Yekaterinburg' },
    { label: '(GMT+05:00) Islamabad, Karachi, Tashkent', value: 'Asia/Karachi' },
    { label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi', value: 'Asia/Calcutta' },
    { label: '(GMT+05:30) Sri Jayawardenapura', value: 'Asia/Calcutta' },
    { label: '(GMT+05:45) Kathmandu', value: 'Asia/Katmandu' },
    { label: '(GMT+06:00) Almaty, Novosibirsk', value: 'Asia/Almaty' },
    { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
    { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Rangoon' },
    { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    { label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', value: 'Asia/Hong_Kong' },
    { label: '(GMT+08:00) Kuala Lumpur, Singapore', value: 'Asia/Kuala_Lumpur' },
    { label: '(GMT+08:00) Irkutsk, Ulaan Bataar', value: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Perth', value: 'Australia/Perth' },
    { label: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
    { label: '(GMT+09:00) Osaka, Sapporo, Tokyo', value: 'Asia/Tokyo' },
    { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
    { label: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
    { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
    { label: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
    { label: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
    { label: '(GMT+10:00) Canberra, Melbourne, Sydney', value: 'Australia/Canberra' },
    { label: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
    { label: '(GMT+10:00) Guam, Port Moresby', value: 'Pacific/Guam' },
    { label: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
    { label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', value: 'Asia/Magadan' },
    { label: '(GMT+12:00) Auckland, Wellington', value: 'Pacific/Auckland' },
    { label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', value: 'Pacific/Fiji' },
    { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
];

export const POLICY_NUM = 2;

export const NF = {
    USERS: 'users',
    SMS: 'SMS',
};

export const DEVICE_STATUS = {
    DEV_INCONSISTENT: 'inconsistent',
    DEV_CONSISTENT: 'consistent',
    DEV_DELETE: 'delete',
    DEV_TRANSFER: 'transfer',
    DEV_TFR_NONE: '',
    DEV_TFR_INIT: 'transfer init',
    DEV_TFR_WAIT_APVL: 'wait for approval',
    DEV_TFR_QUEUED: 'queued',
    DEV_TFR_PENDING: 'transferring',
    DEV_TFR_COMPLETE: 'complete',
    DEV_TFR_FAILED: 'failed',
    DEV_DEL_QUEUED: 'queued',
    DEV_DEL_PENDING: 'deleting',
    DEV_DEL_FAILED: 'failed',
    DEV_TFR_ACPT: 'accept',
    DEV_TFR_INPR: 'in progress',
    DEV_TFR_BYPASS: 'bypass approval',
};

export const DEVICE_TRANSFER_TASK_RETRY_TIME = 10 * 1000;

export const LOG_INF_FAZ = 'FortiAnalyzer';

export const SUPER_ADMIN = 1;

export const SUPPORT_LINK =
    'https://docs.fortinet.com/document/fortitoken-cloud/latest/admin-guide?cshid=Technical_Support';

export const FORTICARE_LINK = 'https://support.fortinet.com/Home.aspx';

export const CONTACT_SALES_LINK = 'https://www.fortinet.com/corporate/about-us/contact-us#contact-sales';

export const INTRO_VIDEO_URL = 'https://www.youtube.com/embed//mmX96YbegNU?rel=0&autoplay=1&mute=1&loop=1';

export const DEFAULT_COL = [2, 10];

export const REPLAY_HIGH = 10;
export const REPLAY_MEDIUM = 50;
export const REPLAY_LOW = 100;

export const DEFAULT_PIN_REQUIRE_TYPE = 1;
export const DEFAULT_PIN_LENGTH = 6;
export const DEFAULT_TIMESTEP = 30;
export const DEFAULT_OTP_LENGTH = 6;
export const DEFAULT_FTM_VALID_WINDOW = 2;

export const PERMISSION_MANAGEMENT = 0;
export const PERMISSION_AUTHENTICATION = 1;
export const PERMISSION_MANAGEMENT_SCIM = 2;

export const USER_EXTENSION_INPUT_BOX_MAX_LENGTH = 50;

// user extension attributes map to name scim singular attributes
export const SCIM_U_SG_ATTRS = {
    display_name: 'Display Name',
    nickname: 'Nick Name',
    profile_url: 'Profile Url',
    title: 'Title',
    usertype: 'User Type',
    preferred_language: 'Preferred Language',
    locale: 'Locale',
    timezone: 'Timezone',
};

// user extensions map to name scim complex attributes
export const SCIM_U_CX_ATTRS = {
    meta: 'Meta',
    name: 'Name',
    emails: 'Emails',
    phone_numbers: 'Phone Numbers',
    ims: 'IMS',
    addresses: 'Addresses',
    entitlements: 'Entitlements',
    roles: 'Roles',
    x509certificates: 'x509Certificates',
    photos: 'Photos',
    enterprise: 'Enterprise',
};

// display order of user extension info
export const SCIM_DISPLAY_ATTRITUES = [
    'display_name',
    'nickname',
    'title',
    'usertype',
    'profile_url',
    'preferred_language',
    'locale',
    'timezone',
    'name',
    'emails',
    'phone_numbers',
    'addresses',
    'entitlements',
    'photos',
    'ims',
    'roles',
    'x509certificates',
    'enterprise',
    'meta',
];
export const BASE_USER = 0;
export const USER_REF = 1;

export const MAX_DISPLAYABLE_USERS = 10;
export const USERS_SYNC_POST_THRESHOLD = 10;
// 1 seconds
export const TASK_RETRY_TIME = 1 * 1000;
// job time to live in queue
export const QUEUE_JOB_TTL = 3600 * 1000;
// job execution timeout
export const QUEUE_JOB_TIMEOUT = 1800 * 1000;

export const ACS_ALLOW = 0;
export const ACS_DENY = 1;

export const PERMISSION_OPTIONS = [
    {
        label: 'Allow',
        value: ACS_ALLOW,
    },
    { label: 'Deny', value: ACS_DENY },
    { label: 'App Default', value: null },
];

export const APP_PERMISSION_OPTIONS = [
    {
        label: 'Allow',
        value: ACS_ALLOW,
    },
    { label: 'Deny', value: ACS_DENY },
];

// Application names
export const FORTIPRODUCT = 'FortiProduct';
export const WEB_APP = 'Web Application';
export const MGMT_APP = 'Mgmt Application';
export const SSO_APP = 'SSO Application';
export const USER_PORTAL = 'End-User Portal';
export const APPLICATION = 'Application';

export const SCIM_APP = 'SCIM Client';

export const DEFAULT_FTC_LOGO_URL = 'https://ftc.fortinet.com/app/media/logo.svg';
export const DEFAULT_FTC_LIGHT_LOGO_URL = 'https://ftc.fortinet.com/app/media/logo_light.svg';
export const BRANDING_THEME = 'theme';
export const BRANDING_SSO_TYPE = 'sso';
export const BRANDING_SSP_TYPE = 'ssp';
export const THEME_TEMPLATE = {
    app_type: BRANDING_THEME,
    properties: [
        {
            name: 'Primary Color',
            key: '--ftc-theme-primary-color',
            value: '#145a90',
            description: 'Primary color applying to Backgroud of Button and Sidebar Menu',
        },
        {
            name: 'Accent Color',
            key: '--ftc-theme-accent-color',
            value: '#ffffff',
            description: 'Accent color applying to the font color of Button and Sidebar Menu',
        },
    ],
    images: [
        {
            name: 'Logo',
            key: 'theme-logo',
            value: 'https://ftc.fortinet.com/app/media/logo.svg',
            description: 'Logo for the applications',
        },
    ],
    texts: [],
};
export const SSO_TEMPLATE = {
    app_type: BRANDING_SSO_TYPE,
    properties: [
        {
            name: 'Primary Color',
            key: '--ftc-primary-color',
            value: '#2b7ce1',
            description: 'Primary color for the sso page',
        },
        {
            name: 'Background Color',
            key: '--ftc-bg-color',
            value: '#d0dde7',
            description: 'Background color for the sso page',
        },
        {
            name: 'Button Color',
            key: '--ftc-btn-color',
            value: '#ffffff',
            description: 'Color for the font in the buttons',
        },
        {
            name: 'Font Color',
            key: '--ftc-font-color',
            value: '#364152',
            description: 'Font color for the Form Titles',
        },
    ],
    images: [
        {
            name: 'Logo',
            key: 'logo',
            value: 'images/logo.svg',
            description: 'Logo for the sso application',
        },
    ],
    texts: [],
};

export const SSP_TEMPLATE = {
    app_type: BRANDING_SSP_TYPE,
    properties: [
        {
            name: 'Button Background Color',
            key: '--ftc-btn-bg-color',
            value: '#145a90',
            description: 'Background color for the buttons',
        },
        {
            name: 'Button Color',
            key: '--ftc-btn-font-color',
            value: '#ffffff',
            description: 'Color for the font in the buttons',
        },
        {
            name: 'Sidebar Menu Background Color',
            key: '--ftc-sidebar-bg-color',
            value: '#145a90',
            description: 'Background color for the Sidebar Menu',
        },
        {
            name: 'Sidebar Menu Active Background Color',
            key: '--ftc-sidebar-active-bg-color',
            value: '#134770',
            description: 'Active Background color for the Sidebar Menu',
        },
        {
            name: 'Sidebar Menu Font Color',
            key: '--ftc-sidebar-font-color',
            value: '#ffffff',
            description: 'Font color for the Sidebar Menu',
        },
    ],
    images: [
        {
            name: 'Landing Logo',
            key: 'landing-logo',
            value: 'https://ftc.fortinet.com/app/media/logo.svg',
            description: 'Logo for the SSP application',
        },
        {
            name: 'Logo',
            key: 'app-logo',
            value: 'https://ftc.fortinet.com/app/media/logo.svg',
            description: 'Logo for the SSP application',
        },
    ],
    texts: [
        {
            name: 'Tagline',
            key: 'headline',
            value: 'Simplify Your Access to Essential Services',
            description: 'Tagline for the SSP landing page',
        },
        {
            name: 'Subtagline',
            key: 'subheadline',
            value: 'Effortlessly manage your settings, security, and preferences – all in one secure portal.',
            description: 'Subtagline for the SSP landing page',
        },
    ],
};

export const DEFAULT_BRANDING_TEMPLATES = [THEME_TEMPLATE, SSO_TEMPLATE, SSP_TEMPLATE];

export const DEFAULT_BRANDING_ID = NIL_UUID;

export const DEFAULT_BRANDING = {
    templates: DEFAULT_BRANDING_TEMPLATES,
    name: 'FTC Template Branding',
    id: DEFAULT_BRANDING_ID,
};

export const FTC_TEMPLATE_BRANDING = DEFAULT_BRANDING_ID;

export const SCIM_API_URL = 'https://ftc.fortinet.com:9696/api/v2/scim/';
export const WEB_APP_API_URL = 'https://ftc.fortinet.com:9696/';
export const MSFT_GLOBAL_AZURE_REDIRECT_URI =
    'https://login.microsoftonline.com/common/federation/externalauthprovider';

export const FA_FTM = 'FTM';
export const FA_EMAIL = 'Email';
export const FA_SMS = 'SMS';
export const FA_FTK = 'FTK';
export const FA_WEBAUTHN = 'WebAuthn';
export const FA_WEBAUTHN_LABEL = 'Passkey';
export const SUPPORT_FA = [
    { label: FA_WEBAUTHN_LABEL, value: FA_WEBAUTHN },
    { label: FA_FTK, value: FA_FTK },
    { label: FA_FTM, value: FA_FTM },
    { label: FA_SMS, value: FA_SMS },
    { label: FA_EMAIL, value: FA_EMAIL },
];

export const DEFAULT_LOG_PAYLOAD = {
    from: 0,
    size: 25,
    range: {
        gte: 0,
        lt: 0,
    },
    type: 'api-mgmt',
    admin_log: true,
    sort: [
        {
            timestamp: 'desc',
        },
    ],
};

export const ADMIN_LOG_FILTER_ACTION_LIST = [
    {
        label: 'ALL',
        value: '',
    },
    {
        label: 'Create',
        value: 'create',
    },
    {
        label: 'Modify',
        value: 'modify',
    },
];

export const ADMIN_LOG_FILTER_STATUS_LIST = [
    {
        label: 'ALL',
        value: '',
    },
    {
        label: 'Successful',
        value: 'successful',
    },
    {
        label: 'Bad request',
        value: 'bad request',
    },
    {
        label: 'Gateway timeout',
        value: 'gateway timeout',
    },
    {
        label: 'Internal server error',
        value: 'internal server error',
    },
];

export const ADMIN_LOG_FILTER_SUBJECT_LIST = [
    {
        label: 'ALL',
        value: '',
    },
    {
        label: 'Email notification',
        value: 'email notification',
    },
    {
        label: 'Email notification result',
        value: 'email notification result',
    },
    {
        label: 'Admin',
        value: 'admin',
    },
    {
        label: 'Admin group',
        value: 'admin group',
    },
    {
        label: 'Customer',
        value: 'customer',
    },
];
export const DEFAULT_TEMPLATE_LIST = [
    'Licensed Customer: First Email Notification',
    'Trial Customer: First Email Notification',
    'Trial Customer: Final Email Notification',
    'Licensed Customer: Final Email Notification',
];

export const TEMPLATE_SAMPLE_PLAIN = `<div>
    <span style="font-size:medium;">Dear FortiToken Cloud Customer,&nbsp;</span><br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <span style="font-size:medium;">Sincerely,&nbsp;</span><br>
    <span style="font-size:medium;">FortiToken Cloud Team&nbsp;</span><br>
    &nbsp;
</div>
<div style="margin:0;">
    <a target="_blank" rel="noopener noreferrer" href="https://www.fortinet.com/"><span style="font-family:Calibri,sans-serif;font-size:11pt;"><font style="font-family:Calibri, sans-serif, serif, EmojiFont;"><img src="data:image/png;base64,
    iVBORw0KGgoAAAANSUhEUgAAAMgAAAAXCAYAAABOMABkAAAAGXRFWHRTb2Z0d2Fy
    ZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtxJREFUeNrsXNFx2zAMpXsaQJcFqo98
    p9og8gR1JogzQZIJLE+QdoLYE9SdIPQGTr7zwS6QcyZIwJa+6HSERKkE6Uh4dzyd
    ZRjQIwgSoCQnQogHQY9p9cPL2ekMDvfQUovs8uTpuQSZwuXaQHZidGrZwiKioF2A
    3M7y3RzapUeee2hbaBtjt447aDlR32JcbqFVufv2t+a7QvhS2axzy03f+sRa80qQ
    QUUGM/B/BTSZaQeB3aklSDIC/jPjrB/GgVXkhP2NcalPQr7ta30LhC+VzTq3lMCG
    DnzxRYTHfQSbKcEM04abCDZj4iaSb0kRNEBgFp+bmS4GCrCfRxg02YiCZG4aB0hP
    nEfmOxuJzZi4HhKZBDmvTJFCkSd/Bix7/OYbEgxfa4Xf1jJp2PJnXfj+CcR311A/
    NNUe15YaJzd+Vi2/9zHGVOVo89nCck5afCAQub94s7QHMQ6UCP++dU6fvsSuofDE
    pa7Hp79j2OwCm93ymFOsIWM/Qs4SOZ8PhWASuEh3uQ+wPnl6Xh2T7g6zKeNjNR10
    gBT/kWpU8/jSMrO0pQ7bnvZ86n7z2MeK48XLGJs2rFhk4BSLHo/cBZ8XHCD0dcmK
    u4EDhGHH1UiLdw4QhhMW3AXDLNKlqD2B6wlrh0JZHoHuSQ/7tmevDhsHkodasDEW
    JEBIQLjFSqrbETvkPAfIAFcQEpiHBdv2yBUMdgWyqXC44QSysqtudjvjKAPEpCBF
    i8zh/oke8C6PI0x66GbER+bBF9Lz6jwXHw/U6s2VZegA4dmbUQ0QH5sYvgJkZq4p
    NcHxqifd0LtYvyM7ZcPjkoHgspK5HFaQsNu8kP9vIq4iEnkvncGoQgfG46GejXEf
    5CoS6Vv2PaMBP8W/V4b1Ub9fr9/v2SdIDkc20+pdp5ez0wtTVGeB6h7sX02Uxxx2
    j+hSDtcnEX1debro8elvV86S0Ldd6xPVIKtXje8mOPQqUr4LMAClH+sqCxn5YAAA
    AABJRU5ErkJggg==" alt="Fortinet Logo" width="249" height="28" border="0" id="_x0000_i1027">&nbsp;</font></span></a>
</div>`;

export const TEMPLATE_SAMPLE_COLORED = `<div>
    <div style="background-color:#EEEEEE;">
        <div style="background-color:#EEEEEE;margin-top:15px;width:98%;">
            <table style="width:100%;" align="center" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr style="background-color:#0378AE;">
                        <td>
                            <div style="background-color:#0378AE;margin:35px;">
                                <span style="background-color:#0378AE;color:white;font-family:Arial;font-size:30px;"><font style="font-family:Arial, serif, EmojiFont;"><strong>Header</strong></font></span>
                            </div>
                        </td>
                    </tr>
                    <tr style="background-color:white;margin-top:15px;">
                        <td>
                            <div style="background-color:white;text-align:left;">
                                <p>
                                    <span style="background-color:white;color:#333333;font-family:verdana;font-size:14px;"><font style="font-family:verdana, serif, EmojiFont;">Body</font></span><br>
                                    <br>
                                    &nbsp;
                                </p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style="width:100%;" align="center">
                <tbody>
                    <tr>
                        <td>
                            <div style="text-align:center;" align="center">
                                <div style="margin:20px;">
                                    <span style="background-color:#EEEEEE;color:#666666;font-family:verdana;font-size:11px;"><font style="font-family:verdana, serif, EmojiFont;">Copyright © 2024 Fortinet Inc. All rights reserved.Email: </font></span><a href="mailto:support@fortinet.com" target="_blank" rel="noopener noreferrer"><span style="background-color:#EEEEEE;color:#E71A0F;font-family:verdana;font-size:11px;"><font style="font-family:verdana, serif, EmojiFont;">support@fortinet.com</font></span><span style="background-color:#EEEEEE;color:#666666;font-family:verdana;font-size:11px;"><font style="font-family:verdana, serif, EmojiFont;">&nbsp;</font></span></a><span style="background-color:#EEEEEE;color:#666666;font-family:verdana;font-size:11px;"><font style="font-family:verdana, serif, EmojiFont;"> Phone: +1 (866) 868-3678</font></span>&nbsp;
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>`;

export const TEMPLATE_SAMPLE_MAPPING = {
    'Plain Text': TEMPLATE_SAMPLE_PLAIN,
    'Colored Background': TEMPLATE_SAMPLE_COLORED,
    'No Template': '',
};

export const DEFAULT_EMAIL_TEMPLATE = (header: string, list: string) => `<div>
	<div style="background-color:#EEEEEE;">
	    <font face="verdana 'Muli',Arial,sans-serif"><span style="background-color:#EEEEEE;">
		    <div style="width:98%;background-color:#EEEEEE;margin-top:15px;">
		        <font face="verdana" size="2" style="font-family: verdana, serif, EmojiFont;"><span
		                style="font-size:14px;background-color:#EEEEEE;">
		                <table width="600" align="center" border="0" cellspacing="0" cellpadding="0"
		                    style="width:600px;">
		                    <tbody>
		                        <tr style="background-color:#0378AE;">
		                            <td>
		                                <div style="background-color:#0378AE;margin:35px;">
		                                    <font face="Arial" size="5" color="white"
		                                        style="font-family: Arial, serif, EmojiFont;"><span
		                                            style="font-size:30px;background-color:#0378AE;"><b>${header}</b></span></font>
		                                </div>
		                            </td>
		                        </tr>
		                        <tr style="background-color:white;">
		                            <td>
		                                <div style="background-color:white;margin:38px;text-align: left;">
		                                    <font color="#333333"><span style="background-color:white;">
		                                            <ul style="margin-top:14pt;margin-bottom:14pt;">
		                                                ${list}
		                                            </ul>
		                                            <div style="margin-top:14pt;margin-bottom:14pt;">&nbsp;</div>
		                                            <div style="margin-top:14pt;margin-bottom:14pt;">For more
		                                                information about new features, see <a
		                                                    href="https://docs.fortinet.com/document/fortitoken-cloud/latest/admin-guide"
		                                                    target="_blank" rel="noopener noreferrer">
		                                                    <font color="#E71A0F">FortiToken Cloud User Guide</font>
		                                                </a>.</div>
		                                            <br>

		                                        </span></font>
		                                </div>
		                            </td>
		                        </tr>
		                    </tbody>
		                </table>
		                <table width="600" align="center" style="width:600px;">
		                    <tbody>
		                        <tr>
		                            <td>
		                                <div align="center" style="text-align:center;">
		                                    <font size="1" color="#666666"><span style="font-size:11px;">
		                                            <div style="margin:20px;">Copyright © 2024 Fortinet Inc. All
		                                                rights reserved.<br>

		                                                Email: <a href="mailto:support@fortinet.com" target="_blank"
		                                                    rel="noopener noreferrer">
		                                                    <font color="#E71A0F">support@fortinet.com</font>
		                                                </a>&nbsp;Phone: +1 (866) 868-3678<br>
		                                            </div>
		                                        </span></font>
		                                </div>
		                            </td>
		                        </tr>
		                    </tbody>
		                </table>
		            </span></font>
		    </div>
		</span></font>
	</div>
</div>`;

export const USER_PORTAL_TYPE = 0;
export const USER_PORTAL_TTL = 900;
export const IDP_PROXY_TTL = 900;
export const MULTI_USER_SOURCE_WARRNING = `
If multiple user sources are added to one application, all of these user sources need to
have domains configured. Otherwise, if the user inputs a username without a matching domain
for the user source, the authentication will use the default user source. If the default
user source is not set, the authentication will be rejected.
`;

export const FALLBACK_DISABLED_FEATURES = ['ssp', 'reverse_proxy'];
