import { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, redirect } from 'react-router-dom';
import AccountSelectionContainer from '@pages/AccountSelection/';
import FortiProducts from '@pages/AuthClients/FortiProducts';
import { AuthenticatedLayout } from '@pages/Layouts/AuthenticatedLayout';
import Branding from '@pages/Settings/Branding';
import SigningCert from '@pages/Settings/SigningCert';
import RootBoundary from '@shared/components/RootBoundary';
import { getCookie } from '@shared/utils/cookie';
import { useAppSelector } from '@store/hooks';
import { selectGenericCookieSuffix } from '@store/modules/auth/authSlice';
import { selectEnableSsp } from '@store/modules/metadata/metadataSlice';
import { selectResourcesRealm } from '@store/modules/resources/resourcesSlice';

const UnAuthenticatedView =
    process.env.REACT_APP_WEBSITE === 'admin'
        ? lazy(() => import('@pages/Layouts/UnAuthenticatedLayout/Admin'))
        : lazy(() => import('@pages/Layouts/UnAuthenticatedLayout/Cloud'));
const IntroductionPage = lazy(() => import('@pages/AccountSelection/IntroductionPage'));
const Dashboard = lazy(() => import('@pages/Dashboard'));
const Administrators = lazy(() => import('@pages/Administrators'));
const Realms = lazy(() => import('@pages/Realms'));
const MgmtApps = lazy(() => import('@pages/AuthClients/MgmtApps'));
const WebApps = lazy(() => import('@pages/AuthClients/WebApps'));
const Ownership = lazy(() => import('@pages/Devices/Ownership'));
const Clusters = lazy(() => import('@pages/Devices/Clusters'));
const TokensHardware = lazy(() => import('@pages/Tokens/Hardware'));
const TokensMobile = lazy(() => import('@pages/Tokens/Mobile'));
const Users = lazy(() => import('@pages/Users'));
const UsersEdit = lazy(() => import('@pages/Users/Edit'));
const UsersCreate = lazy(() => import('@pages/Users/Create'));
const UserGroups = lazy(() => import('@pages/UserGroups'));
const UserGroupCreate = lazy(() => import('@pages/UserGroups/Create'));
const UserGroupEdit = lazy(() => import('@pages/UserGroups/Edit'));
const Policies = lazy(() => import('@pages/AdaptiveAuth/Policy'));
const PolicyUpsert = lazy(() => import('@pages/AdaptiveAuth/Policy/Upsert'));
const Profiles = lazy(() => import('@pages/AdaptiveAuth/Profile'));
const Licenses = lazy(() => import('@pages/Licenses'));
const Usage = lazy(() => import('@pages/Usage'));
const AuthLogs = lazy(() => import('@pages/Logs/AuthLogs'));
const SMSLogs = lazy(() => import('@pages/Logs/SMSLogs'));
const MgmtLogs = lazy(() => import('@pages/Logs/MgmtLogs'));
const Notification = lazy(() => import('@pages/Alarm/Notification'));
const Event = lazy(() => import('@pages/Alarm/Event'));
const EventCreate = lazy(() => import('@pages/Alarm/Event/Create'));
const EventEdit = lazy(() => import('@pages/Alarm/Event/Edit'));
const Group = lazy(() => import('@pages/Alarm/Notification/Group'));
const GroupCreate = lazy(() => import('@pages/Alarm/Notification/Group/Create'));
const GroupEdit = lazy(() => import('@pages/Alarm/Notification/Group/Edit'));
const Recevier = lazy(() => import('@pages/Alarm/Notification/Receiver'));
const Help = lazy(() => import('@pages/Help'));
const Sms = lazy(() => import('@pages/Help/Sms'));
const Templates = lazy(() => import('@pages/Settings/Templates'));
const TemplateUpsert = lazy(() => import('@pages/Settings/Templates/Upsert'));
const TemplateView = lazy(() => import('@pages/Settings/Templates/View'));
const Global = lazy(() => import('@pages/Settings/Global'));
const Realm = lazy(() => import('@pages/Settings/Realm'));
const LogSetting = lazy(() => import('@pages/Settings/Log'));
const Applications = lazy(() => import('@pages/Applications/SSO'));
const UserPortals = lazy(() => import('@pages/Applications/UserPortal'));
const Authentication = lazy(() => import('@pages/Authentication'));
const Source = lazy(() => import('@pages/Authentication/UserSource'));
const Domain = lazy(() => import('@pages/Authentication/Domain'));
const ApplicationUpsertPage = lazy(() => import('@pages/Applications/SSO/UpsertPage'));
const UserPortalUpsertPage = lazy(() => import('@pages/Applications/UserPortal/UpsertPage'));
const AuthenticationUpsertPage = lazy(() => import('@pages/Authentication/UserSource/UpsertPage'));
const WebauthnCredential = lazy(() => import('@pages/Tokens/Webauthn'));
const BrandingUpsertPage = lazy(() => import('@pages/Settings/Branding/UpsertPage'));
const AdminEdit = lazy(() => import('@pages/Administrators/Edit'));

const RoutesContainer = () => {
    const auth = useAppSelector((state) => state.auth);
    const isAdmin = auth.user?.is_admin;
    const resourcesRealm = useAppSelector(selectResourcesRealm);
    const zeroRealm = resourcesRealm?.realm === '0';
    const cookieSuffix = useAppSelector(selectGenericCookieSuffix);
    const enableSsp = useAppSelector(selectEnableSsp);

    const adminRoutes = [
        {
            path: '/dashboard',
            loader: async (/* { request } */) => {
                if (auth.user) {
                    const toured = getCookie(`tour:${cookieSuffix}`);
                    if (auth.user.init_login && auth.user.is_admin && toured !== 'true') {
                        return redirect('/settings/global');
                    } else {
                        return null;
                    }
                }
                return null;
            },
            element: <Dashboard />,
        },
        {
            path: '/admin',
            children: [
                { path: '', element: <Administrators /> },
                { path: ':adminGroupId', element: <AdminEdit /> },
            ],
        },
        {
            path: '/users',
            element: <Users />,
        },
        {
            path: '/usermanagement',
            children: [
                { index: true, element: <Navigate to="/usermanagement/users" /> },
                { path: 'users', element: <Users /> },
                { path: 'users/create', element: <UsersCreate /> },
                { path: 'users/:userId', element: <UsersEdit /> },
                { path: 'usergroups', element: <UserGroups /> },
                { path: 'usergroups/create', element: <UserGroupCreate /> },
                { path: 'usergroups/:groupId', element: <UserGroupEdit /> },
            ],
        },
        {
            path: '/adaptiveauth',
            children: [
                { index: true, element: <Navigate to="/adaptiveauth/policy" /> },
                { path: 'policy', element: <Policies /> },
                { path: 'policy/create', element: <PolicyUpsert /> },
                { path: 'policy/:policyId', element: <PolicyUpsert /> },
                { path: 'profile', element: <Profiles /> },
            ],
        },
        {
            path: '/applications',
            children: [
                { index: true, element: <Navigate to="/applications/fortiprod" /> },
                { path: 'fortiprod', element: <FortiProducts /> },
                {
                    path: 'webapp',
                    element: <WebApps />,
                },
                {
                    path: 'mgmtapp',
                    element: <MgmtApps />,
                },
                {
                    path: 'ssoapp',
                    element: <Applications />,
                },
                {
                    path: 'ssoapp/:id',
                    element: <ApplicationUpsertPage />,
                },
                ...(enableSsp
                    ? [
                          {
                              path: 'userportal',
                              element: <UserPortals />,
                          },
                          {
                              path: 'userportal/:id',
                              element: <UserPortalUpsertPage />,
                          },
                      ]
                    : []),
            ],
        },
        {
            path: '/authentication',
            children: [
                { index: true, element: <Navigate to="/authentication/source" /> },
                {
                    path: 'source/:id',
                    element: <AuthenticationUpsertPage />,
                },
                {
                    path: '',
                    element: <Authentication />,
                    children: [
                        {
                            path: 'source',
                            element: <Source />,
                        },
                        {
                            path: 'domain',
                            element: <Domain />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/devices',
            children: [
                { index: true, element: <Navigate to="/devices/ownership" /> },
                { path: 'ownership', element: <Ownership /> },
                { path: 'cluster', element: <Clusters /> },
            ],
        },
        {
            path: '/authdevice',
            children: [
                { index: true, element: <Navigate to="/authdevice/softtoken" /> },
                { path: 'softtoken', element: <TokensMobile /> },
                { path: 'hardtoken', element: <TokensHardware /> },
                { path: 'passkey', element: <WebauthnCredential /> },
            ],
        },
        {
            path: '/realms',
            element: <Realms />,
        },
        {
            path: '/licenses',
            element: <Licenses />,
        },
        {
            path: '/usage',
            element: <Usage />,
        },
        {
            path: 'settings',
            children: [
                { index: true, element: <Navigate to="/settings/global" /> },
                { path: 'global', element: <Global /> },
                { path: 'realm', element: <Realm /> },
                {
                    path: 'templates',
                    children: [
                        {
                            path: '',
                            element: <Templates />,
                        },
                        { path: 'create', element: <TemplateUpsert /> },
                        { path: ':templateId', element: <TemplateUpsert /> },
                        { path: 'default', element: <TemplateView /> },
                    ],
                },
                {
                    path: 'brandings',
                    children: [
                        {
                            path: '',
                            element: <Branding />,
                        },
                        { path: ':id', element: <BrandingUpsertPage /> },
                    ],
                },
                {
                    path: 'certificates',
                    element: <SigningCert />,
                },
                { path: 'log', element: <LogSetting /> },
            ],
        },
        {
            path: 'alarm',
            children: [
                { index: true, element: <Navigate to="/alarm/event" /> },
                {
                    path: 'event',
                    children: [
                        {
                            path: '',
                            element: <Event />,
                        },
                        {
                            path: 'create',
                            element: <EventCreate />,
                        },
                        {
                            path: ':eventId',
                            element: <EventEdit />,
                        },
                    ],
                },
                {
                    path: 'notification',
                    element: <Notification />,
                    children: [
                        { index: true, element: <Navigate to="/alarm/notification/group" /> },
                        {
                            path: 'group',
                            children: [
                                {
                                    path: '',
                                    element: <Group />,
                                },
                                {
                                    path: 'create',
                                    element: <GroupCreate />,
                                },
                                {
                                    path: ':groupId',
                                    element: <GroupEdit />,
                                },
                            ],
                        },
                        {
                            path: 'receiver',
                            element: <Recevier />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/logs',
            children: [
                { index: true, element: <Navigate to="/auth" /> },
                { path: 'auth', element: <AuthLogs /> },
                { path: 'mgmt', element: <MgmtLogs /> },
                { path: 'sms', element: <SMSLogs /> },
            ],
        },
        {
            path: '/help',
            children: [
                { index: true, element: <Help /> },
                { path: 'sms', element: <Sms /> },
            ],
        },
    ];

    const nonAdminRoutes = [
        {
            path: '/dashboard',
            loader: async (/* { request } */) => {
                if (auth.user) {
                    const toured = getCookie(`tour:${cookieSuffix}`);
                    if (auth.user.init_login && auth.user.is_admin && toured !== 'true') {
                        return redirect('/settings/global');
                    } else {
                        return null;
                    }
                }
                return null;
            },
            element: <Dashboard />,
        },
        {
            path: '/users',
            element: <Users />,
        },
        {
            path: '/usermanagement',
            children: [
                { index: true, element: <Navigate to="/usermanagement/users" /> },
                { path: 'users', element: <Users /> },
                { path: 'usergroups', element: <UserGroups /> },
                { path: 'usergroups/create', element: <UserGroupCreate /> },
                { path: 'usergroups/:groupId', element: <UserGroupEdit /> },
            ],
        },
        {
            path: '/adaptiveauth',
            children: [
                { index: true, element: <Navigate to="/adaptiveauth/policy" /> },
                { path: 'policy', element: <Policies /> },
                { path: 'policy/create', element: <PolicyUpsert /> },
                { path: 'policy/:policyId', element: <PolicyUpsert /> },
                { path: 'profile', element: <Profiles /> },
            ],
        },
        {
            path: '/applications',
            children: [
                { index: true, element: <Navigate to="/applications/fortiprod" /> },
                { path: 'fortiprod', element: <FortiProducts /> },
                {
                    path: 'webapp',
                    element: <WebApps />,
                },
                {
                    path: 'mgmtapp',
                    element: <MgmtApps />,
                },
                {
                    path: 'ssoapp',
                    element: <Applications />,
                },
                {
                    path: 'ssoapp/:id',
                    element: <ApplicationUpsertPage />,
                },
                ...(enableSsp
                    ? [
                          {
                              path: 'userportal',
                              element: <UserPortals />,
                          },
                          {
                              path: 'userportal/:id',
                              element: <UserPortalUpsertPage />,
                          },
                      ]
                    : []),
            ],
        },
        {
            path: '/authentication',
            children: [
                { index: true, element: <Navigate to="/authentication/source" /> },
                {
                    path: 'source/:id',
                    element: <AuthenticationUpsertPage />,
                },
                {
                    path: '',
                    element: <Authentication />,
                    children: [
                        {
                            path: 'source',
                            element: <Source />,
                        },
                        {
                            path: 'domain',
                            element: <Domain />,
                        },
                    ],
                },
            ],
        },
        {
            path: '/authdevice',
            children: [
                { index: true, element: <Navigate to="/authdevice/softtoken" /> },
                { path: 'softtoken', element: <TokensMobile /> },
                { path: 'hardtoken', element: <TokensHardware /> },
                { path: 'passkey', element: <WebauthnCredential /> },
            ],
        },
        {
            path: '/realms',
            element: <Realms />,
        },
        {
            path: '/usage',
            element: <Usage />,
        },
        {
            path: 'settings',
            children: [
                { index: true, element: <Navigate to="/settings/global" /> },
                { path: 'realm', element: <Realm /> },
                {
                    path: 'templates',
                    children: [
                        {
                            path: '',
                            element: <Templates />,
                        },
                        { path: 'create', element: <TemplateUpsert /> },
                        { path: ':templateId', element: <TemplateUpsert /> },
                        { path: 'default', element: <TemplateView /> },
                    ],
                },
                {
                    path: 'brandings',
                    children: [
                        {
                            path: '',
                            element: <Branding />,
                        },
                        { path: ':id', element: <BrandingUpsertPage /> },
                    ],
                },
                {
                    path: 'certificates',
                    element: <SigningCert />,
                },
                { path: 'log', element: <LogSetting /> },
            ],
        },
        {
            path: '/logs',
            children: [
                { index: true, element: <Navigate to="/auth" /> },
                { path: 'auth', element: <AuthLogs /> },
                { path: 'mgmt', element: <MgmtLogs /> },
                { path: 'sms', element: <SMSLogs /> },
            ],
        },
        {
            path: '/help',
            children: [
                { index: true, element: <Help /> },
                { path: 'sms', element: <Sms /> },
            ],
        },
    ];

    const dashboardOnlyRoute = [
        {
            path: '/dashboard',
            element: <Dashboard />,
        },
    ];

    const resourcesRoutes = isAdmin ? adminRoutes : zeroRealm ? dashboardOnlyRoute : nonAdminRoutes;

    const router = createBrowserRouter(
        [
            {
                path: '/',
                loader: async (/* { request } */) => {
                    if (!auth.user) {
                        console.log("you don't have permission to perform this page");
                        return redirect('/login');
                    }

                    if (!auth.user?.account_id) {
                        console.log('you need to select a account to login');
                        return redirect('/accountselection');
                    }
                    return null;
                },
                element: <AuthenticatedLayout />,
                errorElement: <RootBoundary />,
                children: [
                    {
                        index: true,
                        element: (
                            <Navigate to={process.env.REACT_APP_WEBSITE === 'admin' ? '/internal' : '/dashboard'} />
                        ),
                    },
                    {
                        path: '/internal',
                        loader: async () => {
                            if (process.env.REACT_APP_WEBSITE !== 'admin') {
                                return redirect('/dashboard');
                            }
                            return null;
                        },
                        // element: <Outlet></Outlet>,
                        children: [
                            { index: true, element: <Navigate to="dashboard" /* a.k.a "/internal/dashboard" */ /> },
                            {
                                path: 'dashboard',
                                async lazy() {
                                    let { Dashboard } = await import('@pages/Internal/Dashboard');
                                    return { Component: Dashboard };
                                },
                            },
                            {
                                path: 'search',
                                async lazy() {
                                    let { Search } = await import('@pages/Internal/Search');
                                    return { Component: Search };
                                },
                            },
                            {
                                path: 'kibana',
                                async lazy() {
                                    let { Kibana } = await import('@pages/Internal/Kibana');
                                    return { Component: Kibana };
                                },
                            },
                            {
                                path: 'notification',
                                children: [
                                    { index: true, element: <Navigate to="dashboardnotification" /> },
                                    {
                                        path: 'dashboardnotification',
                                        async lazy() {
                                            let { Popup } = await import('@pages/Internal/Notification/Popup');
                                            return { Component: Popup };
                                        },
                                    },
                                    {
                                        path: 'whatsnewnotification',
                                        async lazy() {
                                            let { WhatsNew } = await import('@pages/Internal/Notification/WhatsNew');
                                            return { Component: WhatsNew };
                                        },
                                    },
                                    {
                                        path: 'generalnotification',
                                        async lazy() {
                                            let { Email } = await import('@pages/Internal/Notification/Email');
                                            return { Component: Email };
                                        },
                                    },
                                    {
                                        path: 'admintemplates',
                                        async lazy() {
                                            let { Template } = await import('@pages/Internal/Notification/Templates');
                                            return { Component: Template };
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'licenses',
                                async lazy() {
                                    let { Licenses } = await import('@pages/Internal/Licenses');
                                    return { Component: Licenses };
                                },
                            },
                            {
                                path: 'smsrate',
                                async lazy() {
                                    let { SmsRate } = await import('@pages/Internal/SmsRate');
                                    return { Component: SmsRate };
                                },
                            },
                            {
                                path: 'adminrolegroup',
                                children: [
                                    {
                                        path: '',
                                        async lazy() {
                                            let { AdminGroup } = await import('@pages/Internal/AdminGroup');
                                            return { Component: AdminGroup };
                                        },
                                    },
                                    {
                                        path: ':groupId',
                                        async lazy() {
                                            let { AdminGroupEdit } = await import('@pages/Internal/AdminGroup/Edit');
                                            return { Component: AdminGroupEdit };
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'adminlogs',
                                async lazy() {
                                    let { AdminLog } = await import('@pages/Internal/AdminLog');
                                    return { Component: AdminLog };
                                },
                            },
                        ],
                    },
                    ...resourcesRoutes,
                    {
                        path: '*',
                        element: <div>Unauthorized to visit this page</div>,
                    },
                ],
            },
            {
                path: '/accountselection',
                loader: () => {
                    if (!auth.user) {
                        console.log("you don't have permission to perform this page");
                        return redirect('/login');
                    }
                    if (process.env.REACT_APP_WEBSITE === 'admin') {
                        if (auth.user?.account_id) {
                            return redirect('/internal');
                        } else {
                            return null;
                        }
                    }
                    return null;
                },
                children: [
                    { index: true, element: <AccountSelectionContainer /> },
                    {
                        path: '',
                        element: <AccountSelectionContainer />,
                    },
                    {
                        path: ':accountId/introduction',
                        element: (
                            <Suspense>
                                <IntroductionPage />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: '/login',
                loader: async (/* { request } */) => {
                    if (process.env.REACT_APP_WEBSITE !== 'admin' && auth.user) {
                        if (auth.user.account_id) {
                            return redirect('/dashboard');
                        } else {
                            return redirect('/accountselection');
                        }
                    }
                    console.warn('Can not find auth.user information in RoutesContainer');
                    return null;
                },
                element: (
                    <Suspense>
                        <UnAuthenticatedView />
                    </Suspense>
                ),
            },
            {
                path: '*',
                element: <div>404</div>,
            },
        ],
        { basename: '/app' }
    );

    return <RouterProvider router={router} />;
};

export default RoutesContainer;
