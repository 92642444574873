export const API = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    AUTH: 'get_user',
    VERSION: 'version',
    NAVBAR: 'get_common_data',
    METADATA: 'get_metadata',
    HEART_BEAT: '/api/v1/hello',
    ACCOUNT_LIST: '/api/accountlist',
    LOG: '/api/log/search',
    ANNOUNCEMENT: 'api/notification',
    // api from faas server
    ACCOUNT_LIST_V1: '/api/v1/accountlist',
    ALERT: '/api/v1/alarm',
    ACCOUNT: '/api/v1/account',
    ACCOUNT_GROUP: '/api/v1/accountgroup',
    ADAPTIVEAUTH_PROFILE: '/api/v1/adaptiveauth/profile',
    ADAPTIVEAUTH_POLICY: '/api/v1/adaptiveauth/policy',
    BALANCE: '/api/v1/balance',
    CLIENT: '/api/v1/client',
    DEVICE: '/api/v1/device',
    DEVICE_TRANSFER: '/api/v1/device/transfer',
    LICENSE: '/api/v1/license',
    NOTIFICATION: '/api/v1/notification',
    NOTIFICATION_GROUP: '/api/v1/notification/group',
    NOTIFICATION_RECEIVER: '/api/v1/notification/receiver',
    NOTIFICATION_ALARM: '/api/v1/notification/alarm',
    QUOTA: '/api/v1/quota',
    RATE: '/api/v1/rate',
    REALM: '/api/v1/realm',
    REALM_PERMISSION: '/api/v1/permission',
    RESOURCE_COUNT: '/api/v1/count',
    SETTING_GLOBAL: '/api/v1/setting/global',
    SETTING_REALM: '/api/v1/setting/realm',
    SETTING_LOG: '/api/v1/log/forwarder',
    TASK: 'api/v1/task',
    TEMPLATE_GLOBAL: '/api/v1/template/global',
    TEMPLATE_REALM: '/api/v1/template/realm',
    TEMPLATE_PREVIEW: '/api/v1/template/preview',
    TOKEN: '/api/v1/token',
    TOKEN_TEMP: '/api/v1/token/temp',
    TOKEN_IMPORT: '/api/v1/token/import',
    TRIAL: '/api/v1/trial',
    USAGE: '/api/v1/usage',
    USER_BASE: '/api/v1/user/base',
    USER_REF: '/api/v1/user',
    USER_BASE_BATCH: '/api/v1/user_sync/base',
    // Admin portal API
    CUSTOMER: '/api/v1/customer',
    PRODUCTION: '/api/production',
    CUSTOMER_PORTAL: '/api/customer',
    LICENSE_ADMIN: '/api/v1/license/admin',
    ACCOUNT_GROUP_ADMIN: '/api/v1/accountgroup/admin',
    ACCOUNT_ADMIN: '/api/v1/account/admin',
    WEBAUTHN_CREDENTIAL: '/api/v1/webauthn/credential',
    WEBAUTHN_REGISTERATION_OPTIONS: '/api/v1/webauthn/registration/options',
    WEBAUTHN_REGISTERATION_VERIFICATION: '/api/v1/webauthn/registration/verification',
    SIGNING_CERT: '/api/v1/signingcert',
    DOMAIN_MAPPING: '/api/v1/usersource/domain',
    BRANDING: '/api/v1/branding',
    APPLICATION_PREVIEW: '/api/v1/application/preview',
    APPLICATION: '/api/v1/application',
    APPLICATION_USERSOURCE_MAPPING: (application_id: string) => `/api/v1/application/${application_id}/user_source`,
    USERRESOURCE: '/api/v1/usersource',
    USERRESOURCE_PREVIEW: '/api/v1/usersource/preview',
    USERSOURCE_DOMAINMAPPING: (user_source_id: string) => `/api/v1/usersource/${user_source_id}/domain`,
    USER_GROUP: '/api/v1/usergroup',
    REGION: '/api/v1/region',
};
